import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import _get from 'lodash.get'

import { CenterColumn, sidePaddingMobile, sidePadding } from 'components/base/CenterColumn'
import { Form } from 'components/Form'
import Layout from 'components/Layout'
import { CONTACT_ID } from 'constant'
import { media, MAX_PAGE_WIDTH } from 'media'
import { GeneralContext } from '../../context/general_context'
import { height as headerHeight } from 'components/Header'
import { IndexHeader } from 'components/home/Header'
import { JobsOverview } from 'components/jobs/overview'
import { HeroTitle } from 'components/base/Typography'

const HeroSection = styled(CenterColumn)`
  height: 85vh;
`

const HeroContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`

const SectionContainer = styled.section`
  position: relative;
`

const FixedHolder = styled.div`
  position: fixed;
  top: 90px;
  z-index: -100;

  ${media.desktop`
    top: 130px;
  `}
`

const Blue = styled.span`
  color: ${({ theme }) => theme.colors.blue};
`

const FixedBackgroundImage = styled.div`
  background-repeat: no-repeat;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  width: calc(100vw - ${2 * sidePaddingMobile}px);
  max-width: ${MAX_PAGE_WIDTH}px;
  z-index: -101;
  height: calc(100vh - ${4 * sidePaddingMobile}px);
  position: relative;

  ${media.desktop`
    min-height: 700px;
    width: calc(100vw - ${2 * sidePadding}px);
    height: calc(100vh - ${2 * headerHeight}px);
  `}
`

const removeTrailingDot = (text) => {
  const lastCharacter = text.length - 1
  if (text[lastCharacter] === '.') return text.slice(0, lastCharacter)
  else return text
}

export const JobsOverviewPage = ({ data, jobPosts = [] }) => {
  const { mobileMenuOpen } = useContext(GeneralContext)
  const [title, setTitle] = useState(null)
  const [filteredJobPosts] = useState(
    jobPosts.filter(
      jobPost => jobPost.node._locale === _get(data, '_locale', '')
    )
  )

  const backImageData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "gradient_white.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  useEffect(() => {
    let originalTitle = ''

    if (filteredJobPosts.length > 1) originalTitle = _get(data, 'title_plural', '')
    else originalTitle = _get(data, 'title_singular', '')

    setTitle(removeTrailingDot(originalTitle))
  }, [
    filteredJobPosts,
    data,
  ])

  if (!data) return null

  return (
    <Layout
      locale={data._locale}
      slug_prefix={data._slug_prefix}
      idPrefix={'jobs-overview'}
      metadata={data._meta}
      disableHeader
    >
      <SectionContainer>
        <HeroSection>
          <IndexHeader
            page={'JobsOverviewPage'}
            locale={data._locale}
            slug_prefix={data._slug_prefix}
            idPrefix={'jobs-overview'}
            position={'top'}
            contactPrefix={'jobs-overview'} />
          <FixedHolder>
            <FixedBackgroundImage imageUrl={backImageData.file.childImageSharp.fluid.src} />
          </FixedHolder>
          <HeroContainer>
            <HeroTitle>
              <>{filteredJobPosts.length} {title}<Blue>.</Blue></>
            </HeroTitle>
          </HeroContainer>
        </HeroSection>
      </SectionContainer>
      <SectionContainer mobileMenuOpen={mobileMenuOpen}>
        <IndexHeader
          page={'JobsOverviewPage'}
          locale={data._locale}
          slug_prefix={data._slug_prefix}
          idPrefix={'jobs-overview'}
          position={'middle'}
          isHidden={true}
          isLightTheme={true}
          contactPrefix={'jobs-overview'} />
        <JobsOverview jobPosts={filteredJobPosts} />
      </SectionContainer>
      <SectionContainer>
        <CenterColumn>
          <IndexHeader
            page={'JobsOverviewPage'}
            locale={data._locale}
            slug_prefix={data._slug_prefix}
            idPrefix={'jobs-overview'}
            position={'bottom'}
            isHidden={true}
            contactPrefix={'jobs-overview'} />
          <div id={`${CONTACT_ID}`} />
          <Form
            _for={'jobs-overview'}
            slug_prefix={data._slug_prefix}
            locale={data._locale}
            idPrefix={'jobs-overview'} />
        </CenterColumn>
      </SectionContainer>
    </Layout>
  )
}

JobsOverviewPage.propTypes = {
  data: PropTypes.shape().isRequired,
  jobPosts: PropTypes.array.isRequired,
}

export const query = graphql`
  fragment JobsOverviewPage on JobsOverviewJson {
    _locale
    _name
    _slug_prefix
    _meta {
      lang
      pageTitle
      siteTitle
      pageDescription
      other_lang {
        code
        url
        _enabled
      }
    }
    title_singular
    title_plural
  }
`
