import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import _get from 'lodash.get'

import { CenterColumn, sidePaddingMobile, sidePadding } from 'components/base/CenterColumn'
import { Form } from 'components/Form'
import Layout from 'components/Layout'
import { CONTACT_ID } from 'constant'
import { media, generateFontSizeCss, MAX_PAGE_WIDTH } from 'media'
import { GeneralContext } from '../../context/general_context'
import { height as headerHeight } from 'components/Header'
import { IndexHeader } from 'components/home/Header'
import { JobDescription } from 'components/jobs/description'
import { Time } from 'components/icons/Time'
import { Person } from 'components/icons/Person'
import { Location } from 'components/icons/Location'
import { einaLight } from 'common-styles'
import { regularStyle, largeStyle } from 'components/Paragraph'
import { WhiteBackground } from 'components/home/common'

const HeroSection = styled(CenterColumn)``

const DISPLACE_HEIGHT_DESKTOP = '250px'
const DISPLACE_HEIGHT_MOBILE = '125px'

const HeroContainer = styled.div`
  height: 100%;
  position: relative;

  ${media.desktop`
    top: ${DISPLACE_HEIGHT_DESKTOP};
  `}

  ${media.mobile`
    top: ${DISPLACE_HEIGHT_MOBILE};
  `}
`

const HeroTitle = styled.h1`
  text-align: left;
  ${generateFontSizeCss(26, 46)}
  line-height: 40px;
  margin-bottom: 30px;

  ${media.desktop`
    line-height: 128px;
    line-height: min(122%, 128px);
    margin-bottom: 150px;
    margin-bottom: min(11.55vh, 150px);
    width: 60%;
    padding-left: 85px;
  `}
`

const HeroSubTitles = styled.h3`
  line-height: normal;
  display: flex;
  margin-top: 20px;
  ${generateFontSizeCss(25, 30)}

  ${media.mobile`
    flex-direction: column;
  `}
`

const sharedIconSettings = css`
  ${media.desktop`
    height: 40px;
    width: auto;
  `}

  ${media.mobile`
    height: 30px;
    width: 30px;
  `}

  margin-right: 10px;
`

const TimeIcon = styled(Time)`
  ${sharedIconSettings}
`

const LocationIcon = styled(Location)`
  ${sharedIconSettings}
`

const PersonIcon = styled(Person)`
  ${sharedIconSettings}
`

const SectionContainer = styled.section`
  position: relative;

  ${({ firstSection }) => firstSection && css`
    overflow: visible;
    z-index: 1;
  `}
`

const FixedHolder = styled.div`
  position: absolute;
  top: 90px;
  z-index: -100;

  ${media.desktop`
    top: 130px;
  `}
`

const Blue = styled.span`
  color: ${({ theme }) => theme.colors.blue};
`

const FixedBackgroundImage = styled.div`
  background-repeat: no-repeat;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  width: calc(100vw - ${2 * sidePaddingMobile}px);
  max-width: ${MAX_PAGE_WIDTH}px;
  z-index: -101;
  height: calc(100vh - ${4 * sidePaddingMobile}px);
  position: relative;

  ${media.desktop`
    min-height: 700px;
    width: calc(100vw - ${2 * sidePadding}px);
    height: calc(100vh - ${2 * headerHeight}px);
  `}
`

const IconUnit = styled.div`
  display: flex;
  align-items: center;

  ${media.mobile`
    margin-bottom: 15px;
  `}
`

const IconText = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;

  span {
    font-size: 15px;
    font-size: 15px;
    line-height: 20px;
  }
  
  span:first-child {
    line-height: 20px;
  }

  span:nth-child(2) {
    font-family: ${einaLight}
  }
`

const HeroImage = styled(Img)``

const BackgroundWhiteSpace = styled.div`
  background-color: white;

  ${media.desktop`
    height: ${DISPLACE_HEIGHT_DESKTOP};
  `}

  ${media.mobile`
    height: ${DISPLACE_HEIGHT_MOBILE};
  `}
`

const imageQueries = graphql`
  query {
    backgroundImage: file(relativePath: { eq: "gradient_white.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    productArchitect: file(relativePath: { eq: "product-architect.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    engineer: file(relativePath: { eq: "engineer.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    projectManager: file(relativePath: { eq: "project-manager.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    salesPerson: file(relativePath: { eq: "sales-person.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    designer: file(relativePath: { eq: "ui-ux-designer.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`


const getProperJobImage = (jobName, team) => {
  if (jobName === 'Senior Product Architect (m/w/x)') return 'productArchitect'
  else if (jobName === 'Project Manager/Product Owner (m/f/x)') return 'projectManager'
  else if (jobName === 'Mid UX/UI Designer (m/f/x)') return 'designer'
  else if (team === 'Engineering') return 'engineer'
  else if (jobName === 'TODO') return 'salesPerson'
  else return 'productArchitect'
}

const removeTrailingDot = (text) => {
  const lastCharacter = text.length - 1
  if (text[lastCharacter] === '.') return text.slice(0, lastCharacter)
  else return text
}

export const JobPostPage = ({ data, html }) => {
  // const data = dataTemp.JobPostJson

  const { mobileMenuOpen } = React.useContext(GeneralContext)
  const queriedImages = useStaticQuery(imageQueries)

  if (!data) return null

  let title = _get(data, 'title', '')
  title = removeTrailingDot(title)
  return (
    <Layout
      locale={data._locale}
      slug_prefix={data._slug_prefix}
      idPrefix={'jobs'}
      metadata={data._meta}
      disableHeader
    >
      <SectionContainer firstSection>

        <HeroSection>
          <IndexHeader
            page={'JobPostPage'}
            locale={data._locale}
            slug_prefix={data._slug_prefix}
            idPrefix={'jobs'}
            position={'top'}
            contactPrefix={'jobs'} />
          <FixedHolder>
            <FixedBackgroundImage imageUrl={queriedImages.backgroundImage.childImageSharp.fluid.src} />
          </FixedHolder>

          <HeroContainer>
            <HeroTitle>
              <>{title}<Blue>.</Blue></>
              <HeroSubTitles>

                <IconUnit>
                  <TimeIcon />
                  <IconText>
                    <span>Start</span>
                    <span>{data.timeIcon}</span>
                  </IconText>
                </IconUnit>

                <IconUnit>
                  <LocationIcon />
                  <IconText>
                    <span>{data._locale === 'en' ? 'Location' : 'Standort'}</span>
                    <span>{data.location}</span>
                  </IconText>
                </IconUnit>

                <IconUnit style={{ marginBottom: 0 }}>
                  <PersonIcon />
                  <IconText>
                    <span>Team</span>
                    <span>{data.team}</span>
                  </IconText>
                </IconUnit>

              </HeroSubTitles>
            </HeroTitle>

            <HeroImage fluid={queriedImages[[getProperJobImage(title, data.team)]].childImageSharp.fluid} alt="" />

          </HeroContainer>
        </HeroSection>

      </SectionContainer>
      <SectionContainer mobileMenuOpen={mobileMenuOpen}>
        <BackgroundWhiteSpace />
        <IndexHeader
          page={'JobPostPage'}
          locale={data._locale}
          slug_prefix={data._slug_prefix}
          idPrefix={'jobs'}
          position={'middle'}
          isHidden={true}
          isLightTheme={true}
          contactPrefix={'jobs'} />
        <JobDescription data={data} html={html} />
      </SectionContainer>
      <SectionContainer>
        <CenterColumn>
          <IndexHeader
            page={'JobPostPage'}
            locale={data._locale}
            slug_prefix={data._slug_prefix}
            idPrefix={'jobs'}
            position={'bottom'}
            isHidden={true}
            contactPrefix={'jobs'} />
          <div id={`${CONTACT_ID}`} />
          <Form
            _for={'jobs'}
            slug_prefix={data._slug_prefix}
            locale={data._locale}
            idPrefix={'jobs'} />
        </CenterColumn>
      </SectionContainer>
    </Layout>
  )
}

JobPostPage.propTypes = {
  data: PropTypes.shape().isRequired,
}



export const query = graphql`
fragment JobPost on MarkdownRemarkFrontmatter {
  introduction_
  title
  timeIcon
  team
  location
  _slug
  _locale
  _slug_prefix
  _meta {
    lang
    pageTitle
    siteTitle
    pageDescription
    other_lang {
      code
      url
      _enabled
    }
  }
}
fragment JobPostPageMd on MarkdownRemark {
  html
  rawMarkdownBody
  data: frontmatter {
    ...JobPost
  }
}
  fragment JobPostPage on JobPostJson {
    _locale
    _name
    _slug_prefix
    _meta {
      lang
      pageTitle
      siteTitle
      pageDescription
      other_lang {
        code
        url
        _enabled
      }
    }
    title
    timeIcon
    location
    team
    introduction
    content {
      type
      title
      body
    }
  }
`
