import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { CenterColumn as BaseCenterColumn } from 'components/base/CenterColumn'
import { ExternalButtonLink } from 'components/ButtonLink'
import { LinkedIn } from 'components/icons/LinkedIn'
import { Instagram } from 'components/icons/Instagram'
import { Facebook } from 'components/icons/Facebook'
import { Clutch } from 'components/icons/Clutch'
import { Paragraph } from 'components/Paragraph'
import logoSrc from 'resources/images/9y-logo.png'
import { media } from 'media'
import _get from 'lodash.get'
import { GeneralContext } from '../../context/general_context'
import { LanguageSwitch } from './LanguageSwitch'

export const height = 126
export const heightMobile = 240

const Container = styled.footer`
  z-index: 1;
  padding: 45px 0;
  position: relative;
  ${media.desktop`
    padding: 0;
    height: ${height}px;
  `}
  
  ${media.mobile`
    ${({ mobileMenuOpen }) => mobileMenuOpen ? 'display: none;' : ''}
    background: ${({ theme }) => theme.colors.black};
  `}
`

const CenterColumn = styled(BaseCenterColumn)`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${media.desktop`
    border-top: 1px solid ${({ theme }) => theme.colors.graySecondary};
    justify-content: space-between;
    flex-direction: row;
  `}
`

const margin = 25
const Vision = styled(Paragraph)`
  ${media.mobileOnly};
  margin-bottom: ${margin}px;
  text-align: center;
  line-height: 22px;
`

const HomeLink = styled(Link)`
  ${media.desktopOnly};
`

const Logo = styled.img`
  height: 25px;
  width: auto;

  ${media.desktop`
    height: 30px;
  `}
`

const Items = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;

  ${media.desktop`
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
  `}
`

const fontStyle = css`
  font-size: 14px;

  ${media.desktop`
    font-size: 16px;
  `}
`

const Copyright = styled.div`
  margin-top: ${margin}px;
  margin-right: 0;
  ${fontStyle};

  ${media.desktop`
    margin-top: 0;
    margin-left: 35px
  `}
`

const Links = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;

  ${media.desktop`
    justify-content: flex-end;
    &.disclaimers {
      margin-top: 0px !important;
    }
  `}

  &.disclaimers {
    margin-top: 35px;

    ${media.mobile`
      margin-bottom: 20px;

      // Change layout due to long German words
      ${({ locale }) => locale === 'de' && css`
        @media only screen and (max-width: 420px) {
          display: flex;
          flex-direction: column;
          margin-bottom: 0;

          li {
            margin: 0 0 20px 0;
          }
        }
      `}
    `}

    a:hover {
      color: ${({ theme }) => theme.colors.blue };
      text-decoration: none;
    }

    ${media.desktop`
      li:last-child {
        margin-right: 35px;
      }
    `}
  }
`

const ListLink = styled.li`
  :not(:last-child) {
    margin-right: 35px;
  }

  &.socialMediaLink:not(:last-child) {
    margin-right: 20px;
  }
  ${fontStyle};
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
`

const StyledExternalLink = styled(ExternalButtonLink)`
  svg {
    margin: 0 !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
`

/** Dirty fix: Hide dots behind the footer */
const BackgroundFix = styled.div`
  ${media.desktopOnly}
  position: absolute;
  background: ${({ theme }) => theme.colors.black};
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const MobileOnly = styled.span`
  ${media.mobileOnly}
`

const DesktopOnly = styled.span`
  ${media.desktopOnly}
  margin: 0 auto 0 50px;
`

export const Footer = ({ slug_prefix = '', locale = 'en' }) => {
  const { mobileMenuOpen } = React.useContext(GeneralContext)
  const data = useStaticQuery(graphql`
    query {
      allFooterJson(filter: {_for: {eq: "index"}}) {
        edges {
          node {
            entries {
              locale
              title
              sub_title
              imprint
              privacy_policy
            }
          }
        }
      }
    }
  `)

  const footerEntries = _get(data, 'allFooterJson.edges.0.node.entries', [])
  const footerData = footerEntries.find(h => h.locale === locale)

  return (
    <Container mobileMenuOpen={mobileMenuOpen}>
      <CenterColumn>
        <BackgroundFix />
        <HomeLink to={`/${slug_prefix}`}>
          <Logo title={'9y'} src={logoSrc} alt={'9y Company Logo'} />
        </HomeLink>
        <Vision>{_get(footerData, 'sub_title', '')}</Vision>
        <Items>
          <DesktopOnly>
            <LanguageSwitch locale={locale} /> 
          </DesktopOnly>
          <Copyright>{_get(footerData, 'title', '')}</Copyright>
          <Links locale={locale} className="disclaimers">
            <ListLink>
              <MobileOnly>
                <LanguageSwitch locale={locale} /> 
              </MobileOnly>
            </ListLink>
            <ListLink>
              <StyledLink to={`/${slug_prefix}${slug_prefix ? '/' : ''}imprint`}>{_get(footerData, 'imprint', '')}</StyledLink>
            </ListLink>
            <ListLink>
              <StyledLink to={`/${slug_prefix}${slug_prefix ? '/' : ''}privacy-policy`}>
                <span dangerouslySetInnerHTML={{ __html: _get(footerData, 'privacy_policy', '') }} />
              </StyledLink>
            </ListLink>
          </Links>
          <Links>
            <ListLink className="socialMediaLink">
              <StyledExternalLink
                href="https://www.instagram.com/9y.agency/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              ><Instagram role="img" /></StyledExternalLink>
            </ListLink>
            <ListLink className="socialMediaLink">
              <StyledExternalLink
                href="https://www.linkedin.com/company/9ydotco"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              ><LinkedIn role="img" /></StyledExternalLink>
            </ListLink>
            <ListLink className="socialMediaLink">
              <StyledExternalLink
                href="https://www.facebook.com/9y.agency/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              ><Facebook role="img" /></StyledExternalLink>
            </ListLink>
            <ListLink className="socialMediaLink">
              <StyledExternalLink
                href="https://clutch.co/profile/9y"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Clutch"
              ><Clutch role="img" /></StyledExternalLink>
            </ListLink>
          </Links>
        </Items>
      </CenterColumn>
    </Container>
  )
}

Footer.propTypes = {
  slug_prefix: PropTypes.string,
  locale: PropTypes.string,
}

export default Footer
