import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import _get from 'lodash.get'

import { CenterColumn, sidePaddingMobile, sidePadding } from 'components/base/CenterColumn'
import { Form } from 'components/Form'
import Layout from 'components/Layout'
import { CONTACT_ID } from 'constant'
import { media, MAX_PAGE_WIDTH } from 'media'
import { GeneralContext } from '../../context/general_context'
import { height as headerHeight } from 'components/Header'
import { IndexHeader } from 'components/home/Header'
import { Reasons } from 'components/why-us/reasons'
import { CallToAction } from 'components/why-us/action'
import { HeroTitle } from 'components/base/Typography'

const HeroSection = styled(CenterColumn)`
  height: 85vh;
`

const HeroContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`

const SectionContainer = styled.section`
  position: relative;
`

const FixedHolder = styled.div`
  position: fixed;
  top: 90px;
  z-index: -100;

  ${media.desktop`
    top: 130px;
  `}
`

const Blue = styled.span`
  color: ${({ theme }) => theme.colors.blue};
`

const FixedBackgroundImage = styled.div`
  background-repeat: no-repeat;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  width: calc(100vw - ${2 * sidePaddingMobile}px);
  max-width: ${MAX_PAGE_WIDTH}px;
  z-index: -101;
  height: calc(100vh - ${4 * sidePaddingMobile}px);
  position: relative;
  ${media.desktop`
    min-height: 700px;
    width: calc(100vw - ${2 * sidePadding}px);
    height: calc(100vh - ${2 * headerHeight}px);
  `}
`

export const WhyUsPage = ({ data }) => {
  const { mobileMenuOpen } = React.useContext(GeneralContext)
  const backImageData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "gradient_white.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const title = _get(data, 'title', '')
  const titleText = title.substring(0, title.length - 1)
  const titleLastChar = title.substring(title.length - 1, title.length)

  return (
    <Layout
      locale={data._locale}
      slug_prefix={data._slug_prefix}
      idPrefix={'why-us'}
      metadata={data._meta}
      disableHeader
    >
      <SectionContainer>
        <HeroSection>
          <IndexHeader
            page={'WhyUsPage'}
            locale={data._locale}
            slug_prefix={data._slug_prefix}
            idPrefix={'team'}
            position={'top'}
            contactPrefix={'why-us'} />
          <FixedHolder>
            <FixedBackgroundImage imageUrl={backImageData.file.childImageSharp.fluid.src} />
          </FixedHolder>
          <HeroContainer>
            <HeroTitle>
              <>
                <span dangerouslySetInnerHTML={{ __html: titleText }} />
                <Blue>{titleLastChar}</Blue>
              </>
            </HeroTitle>
          </HeroContainer>
        </HeroSection>
      </SectionContainer>
      <SectionContainer mobileMenuOpen={mobileMenuOpen}>
        <IndexHeader
          page={'WhyUsPage'}
          locale={data._locale}
          slug_prefix={data._slug_prefix}
          idPrefix={'team'}
          position={'middle'}
          isHidden={true}
          isLightTheme={true}
          contactPrefix={'why-us'} />
        <Reasons data={data} />
        <CallToAction data={data} />
      </SectionContainer>
      <SectionContainer>
        <CenterColumn>
          <IndexHeader
            page={'WhyUsPage'}
            locale={data._locale}
            slug_prefix={data._slug_prefix}
            idPrefix={'team'}
            position={'bottom'}
            isHidden={true}
            contactPrefix={'why-us'} />
          <div id={`${CONTACT_ID}`} />
          <Form
            _for={'index'}
            slug_prefix={data._slug_prefix}
            locale={data._locale}
            idPrefix={'team'} />
        </CenterColumn>
      </SectionContainer>
    </Layout>
  )
}

WhyUsPage.propTypes = {
  data: PropTypes.shape().isRequired,
}

export const query = graphql`
  fragment WhyUsPage on WhyUsJson {
    _locale
    _name
    _slug_prefix
    _meta {
      lang
      pageTitle
      siteTitle
      pageDescription
      other_lang {
        code
        url
        _enabled
      }
    }
    title
    reasons {
      entries {
        text
        image {
          publicURL
        }
        alt_text
      }
    },
    action {
      title
      description
      button_text
      background_image {
        publicURL
      }
    }
  }
`
