import React from 'react'
import _get from 'lodash.get'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { CenterColumn } from 'components/base/CenterColumn'
import { media, generateFontSizeCss } from 'media'
import { ExternalButtonLinkLarge } from 'components/ButtonLink'
import { ArrowRight } from 'components/icons/ArrowRight'
import { appendBlueCharacter } from 'utils'


const Container = styled(CenterColumn)``
const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${media.desktop`
    flex-direction: row;
    justify-content: space-between;
  `}
`

const Review = styled.article`
  display: flex;
  flex-direction: column;

  ${media.mobile`
    ${({ lastReview }) => !lastReview ? `
      margin-bottom: 60px;
  ` : ''}
  `}

  ${media.desktop`
    width: 48%;
    &:first-child,
    &:nth-child(2) {
      margin-bottom: 35px;
    }
  `}

  ${media.desktopFullHD`
    width: 23%;
    max-width: 390px;
    &:first-child,
    &:nth-child(2) {
      margin-bottom: 0;
    }
  `}
`

const Heading = styled.h2`
  ${generateFontSizeCss(26, 32)}
  margin-bottom: 10px;
  line-height: 32px;

  ${media.desktop`
    margin-bottom: 15px;
    line-height: 42px;
  `}

  ${media.desktopFullHD`
    min-height: 126px;
  `}
`

const Description = styled.p`
  line-height: 22px;
  margin-bottom: 30px;

  ${media.desktop`
    margin-bottom: 35px;
    line-height: 28px;
  `}
`

const Logo = styled.img``
const LogoContainer = styled.div`
  margin-bottom: 25px;

  img[title='Fastlane Logo'] {
    max-width: 100%;
    max-height: 65px;
  }

  ${media.desktop`
    height: 85px;
    display: flex;
    align-items: flex-end;
  `}
`

const ButtonContainer = styled.div`
  ${media.desktop`
    margin-top: auto;
  `}
`

const ButtonLink = styled(ExternalButtonLinkLarge)`
  color: ${({ theme }) => theme.colors.black};
  display: inline-block;
`

export const Reviews = ({ data }) => {
  const reviews = _get(data, 'reviews.entries', [])
  const buttonText = _get(data, 'reviews.cta_button', '')

  return (
    <Container>
      <ContentContainer>
        {reviews.map((entry, i) => {
          const title = _get(entry, 'title', '')
          const lastReview = (i === reviews.length - 1)
          const altText = _get(entry, 'alt_text', '')

          return (
            <Review lastReview={lastReview} key={`review_${i}`}>
              <LogoContainer>
                <Logo src={_get(entry, 'logo.publicURL', '')} alt={altText} title={altText} />
              </LogoContainer>
              <Heading>
                {appendBlueCharacter(title)}
              </Heading>
              <Description>
                {entry.description}
              </Description>
              <ButtonContainer>
                <ButtonLink href={entry.link} target="_blank">{buttonText}<ArrowRight /></ButtonLink>
              </ButtonContainer>
            </Review>
          )
        })}
      </ContentContainer>
    </Container>
  )
}

Reviews.propTypes = {
  data: PropTypes.shape().isRequired,
}
