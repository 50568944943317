import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { IndexPage } from 'content/index-page/IndexPage'

const Index = ({ data }) => {
  return <IndexPage data={data.indexJson} />
}

Index.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default Index

export const query = graphql`
  query($slug: String!) {
    indexJson(fields: { slug: { eq: $slug } }) {
      ...IndexPage
    }
  }
`
