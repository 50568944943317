import React from 'react'
import styled from 'styled-components'
import _get from 'lodash.get'
import PropTypes from 'prop-types'

import { media, generateFontSizeCss } from 'media'
import { einaRegular } from 'common-styles'
import { WhiteBackground } from 'components/home/common'
import { ArrowRight } from 'components/icons/ArrowRight'
import { ExternalButtonLinkLarge } from 'components/ButtonLink'
import { CenterColumn } from 'components/base/CenterColumn'

const Background = styled(WhiteBackground)`
  position: relative;
  padding: 60px 0;

  ${media.desktop`
    padding: 150px 0;
  `}
`

const Container = styled(CenterColumn)`
  position: relative;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
    max-width: 1300px;
  `}
`

const ActionTitle = styled.h2`
  text-align: left;
  ${generateFontSizeCss(22, 32)}
  
  ${media.mobile`
    margin-bottom: 20px;
    line-height: 32px;
  `}

  ${media.desktop`
    line-height: 46px;
    max-width: 500px;
    margin-right: 30px;
  `}
`

const Blue = styled.span`
  color: ${({ theme }) => theme.colors.blue};
`

const DescriptionContainer = styled.div``
const DescriptionText = styled.p`
  font-family: ${einaRegular};
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.graySecondary};

  ${media.mobile`
    margin-bottom: 40px;
  `}
  
  ${media.desktop`
    font-size: 16px;
    line-height: 28px;
    max-width: 775px;
    margin-bottom: 60px;
  `}
`

const ButtonLink = styled(ExternalButtonLinkLarge)`
  color: ${({ theme }) => theme.colors.black};
  display: inline-block;
`

const BackgroundImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
`

export const CallToAction = ({ data }) => {
  const title = _get(data, 'action.title', '')
  const titleText = title.substring(0, title.length - 1)
  const titleLastChar = title.substring(title.length - 1, title.length)

  return (
    <Background>
      <BackgroundImage 
        src={_get(data, 'action.background_image.publicURL', '')}
        alt={'Cut off Background Ellipse'}
        title={'Cut off Background Ellipse'} />
      <Container>
        <ActionTitle>
          <>{titleText}<Blue>{titleLastChar}</Blue></>
        </ActionTitle>
        <DescriptionContainer>
          <DescriptionText>{_get(data, 'action.description', '')}</DescriptionText>
          <ButtonLink href='#contact'>{_get(data, 'action.button_text', '')}<ArrowRight /></ButtonLink>
        </DescriptionContainer>
      </Container>
    </Background>
  )
}

CallToAction.propTypes = {
  data: PropTypes.shape().isRequired,
}
