import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { JobPostPage } from 'content/job-post-page/JobPostPage'

const JobPost = (data) => {
  console.log("JOBPOST PAGE" , data)

  return <JobPostPage data={data.data.jobPostJson || data.data.markdownRemark.data } html={data.data?.markdownRemark?.html}/>
}

JobPost.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default JobPost

export const query = graphql`
  query($_slug: String!) {
    markdownRemark(frontmatter: { _slug: { eq: $_slug } }) {
      ...JobPostPageMd
    }
  }
`


    // jobPostJson(_slug: { eq: $_slug }) {
    //   ...JobPostPage
    // }