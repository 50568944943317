import { css } from 'styled-components'

export const MIN_FULLHD_WIDTH = 1920
export const FULLHD_BREAKPOINT = `(min-width: ${MIN_FULLHD_WIDTH}px)`

export const MAX_PAGE_WIDTH = 1600
export const PAGE_BREAKPOINT = `(min-width: ${MAX_PAGE_WIDTH}px)`

export const MOBILE_BREAKPOINT = 768
export const MAX_WIDTH_BREAKPOINT = `(max-width: ${MOBILE_BREAKPOINT}px)`
export const MIN_WIDTH_BREAKPOINT = `(min-width: ${MOBILE_BREAKPOINT + 1}px)`

export const SMALL_MOBILE_WIDTH = 350
export const SMALL_MOBILE_BREAKPOINT = `(max-width: ${SMALL_MOBILE_WIDTH}px)`

export const isMediaMatch = breakpoint => {
  let isMatch = false
  try {
    isMatch = !window.matchMedia(`screen and (min-width: ${breakpoint}px)`).matches
  } catch (e) {
    isMatch = false
  }

  return isMatch
}
export const isMobile = () => isMediaMatch(MOBILE_BREAKPOINT)
export const isDesktop = () => !isMobile()

// if more of these hide* are added, they should also accordingly be added to the media.*Only props below
const hideMobile = css`
  @media ${MAX_WIDTH_BREAKPOINT} {
    display: none !important;
  }
`
const hideDesktop = css`
  @media ${MIN_WIDTH_BREAKPOINT} {
    display: none !important;
  }
`
export const media = {
  smallMobile: (...args) => css`
    @media ${SMALL_MOBILE_BREAKPOINT} {
      ${
        css(...args)
      }
    }
  `,
  mobile: (...args) => css`
    @media ${MAX_WIDTH_BREAKPOINT} {
      ${
        css(...args)
      }
    }
  `,
  desktop: (...args) => css`
    @media ${MIN_WIDTH_BREAKPOINT} {
      ${
        css(...args)
      }
    }
  `,
  page: (...args) => css`
    @media ${PAGE_BREAKPOINT} {
      ${
        css(...args)
      }
    }
  `,
  desktopFullHD: (...args) => css`
    @media ${FULLHD_BREAKPOINT} {
      ${
        css(...args)
      }
    }
  `,
  mobileOnly: css`
    ${hideDesktop};
  `,
  desktopOnly: css`
    ${hideMobile};
  `,
  min1300: (...args) => css`
    @media (min-width: 1300px) {
      ${
        css(...args)
      }
    }
  `,
  msEdge: (...args) => css`
    @supports (-ms-ime-align:auto) {
      ${
        css(...args)
      }
    }
  `,
  firefox: (...args) => css`
    @-moz-document url-prefix() { 
      ${
        css(...args)
      }
    }
  `,
}

const DIFF = MAX_PAGE_WIDTH - MOBILE_BREAKPOINT
export const generateFontSizeCss = (min, max) => css`
  font-size: ${min}px;

  ${media.desktop`
    font-size: calc(${min}px + ${max - min} * ((100vw - ${MOBILE_BREAKPOINT}px) / ${DIFF}));
  `}

  ${media.page`
    font-size: ${max}px;
  `}
`
