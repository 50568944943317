import React from 'react'
import styled from 'styled-components'
import _get from 'lodash.get'
import PropTypes from 'prop-types'

import { media } from 'media'
import { CenterColumn } from 'components/base/CenterColumn'

const Container = styled(CenterColumn)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.black};
  padding: 50px 0 40px 0;

  ${media.desktop`
    padding: 100px 0;
  `}
`

const Blue = styled.span`
  color: ${({ theme }) => theme.colors.blue};
`

const Title = styled.h2`
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
  font-size: 26px;

  ${media.desktop`
    margin-bottom: 0px;
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;

  ${media.desktop`
    padding-top: 60px;
  `}
`

const LogoWrapper = styled.a`
  width: 50%;
  text-align: center;

  ${media.desktop`
    width: 20%;
  `}

  img {
    width: 100%;
    max-width: 150px;
    height: 100%;

    ${media.desktop`
      max-width: 180px;
    `}
  }
`

export const Customers = ({ data }) => {
  const entries = _get(data, 'customers.entries', [])
  const formTitle = _get(data, 'customers.title', '')
  const formTitleText = formTitle.substring(0, formTitle.length - 1)
  const formTitleLastChar = formTitle.substring(formTitle.length - 1, formTitle.length)

  return (
    <Container>
      <Title><>{formTitleText}<Blue>{formTitleLastChar}</Blue></></Title>
      <Content>
        {entries.map((entry, i) => {
          const imageSource = _get(entry, 'image.publicURL', null)
          const altText = _get(entry, 'alt_text', '')
          const urlLink = _get(entry, 'link', '')

          return (
            <LogoWrapper key={`customer_image_${i}`} href={urlLink} target='_blank' rel='noopener noreferrer'>
                <img src={imageSource} title={altText} alt={altText} />
            </LogoWrapper>
          )
        })}
      </Content>
    </Container>
  )
}

Customers.propTypes = {
  data: PropTypes.shape().isRequired,
}