import React from 'react'
import styled from 'styled-components'
import _get from 'lodash.get'
import PropTypes from 'prop-types'

import { media, generateFontSizeCss } from 'media'
import { height as headerHeight } from 'components/Header'
import { appendBlueCharacter } from 'utils'

const Container = styled.div`
  position: relative;
  top: 10px;
  ${media.desktop`
    min-height: 400px;
    height: calc(100vh - ${2*headerHeight}px);
  `}
`

const WrapperContainer = styled.div`
  position: relative;
  display: flex;
  height: calc(100vh - 120px);
  width: 100%;
  ${media.desktop`
    min-height: 375px;
    height: calc(100vh - 134px - 60px);
    @media (min-height: 1000px) {
      height: calc(100% - 60px);
    }
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: calc(100% - 60px);
  margin: 0 auto;
  ${media.desktop`
    width: calc(100% - 140px);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  `}
`

const Section = styled.section`
  width: 100%;
`

const HeroHeading = styled.h1`
  width: 100%;
  text-align: left;
  line-height: 150px;
  line-height: min(115%, 150px);

  ${media.mobile`
    position: relative;
    top: -50px;
  `}

  ${({ locale }) => locale === 'de'  
    ? generateFontSizeCss(40, 120) 
    : generateFontSizeCss(40, 135)
  }
`

const Blue = styled.span`
  color: ${({ theme }) => theme.colors.blue};
`

export const HeroSection = ({ data = {} }) => {
  const title = _get(data, 'hero.title', '')

  return (
    <Container>
      <WrapperContainer>
        <Wrapper>
          <Section>
            <HeroHeading locale={data._locale}>
              <span dangerouslySetInnerHTML={{ __html: title }}></span>
              <Blue>.</Blue>
            </HeroHeading>
          </Section>
        </Wrapper>
      </WrapperContainer>
    </Container>
  )
}

HeroSection.propTypes = {
  data: PropTypes.shape().isRequired,
}
