import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import { konnectSemiBold } from 'common-styles'
import { media } from 'media'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BlueDot = styled.span`
  display: flex;
  position: relative;
  align-items: center;
  color: ${({ theme }) => theme.colors.blue};
  font-size: 45px;
  height: 0px;
  top: -11px;
  pointer-events: none;

  ${media.desktop`
    top: -10px;
    font-size: 60px;
  `}

  ${media.mobile`
    margin-left: 10px;
  `}
`

const StyledLink = styled(Link)`
  font-family: ${konnectSemiBold};
  font-size: ${({ mobileMenuOpen }) => mobileMenuOpen ? '26px' : '14px'};
  color: ${({ theme, isLightTheme }) => isLightTheme ? theme.colors.black : theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.desktop`
    font-size: 15px;
    flex-direction: column;

    &#jobs-desktop ~ span {
      left: -14px;
    }
  `}

  ${media.mobile`
    flex-direction: row;
  `}

  :hover {
    text-decoration: none;

    ${BlueDot} {
      text-decoration: none;
    }
  }
`

export const NavLink = ({
  active,
  children,
  className,
  mobileMenuOpen,
  ...rest
}) => {

  return (
    <Container className={className}>
      {!mobileMenuOpen &&
        <>
          <StyledLink mobileMenuOpen={mobileMenuOpen} {...rest}>{children}</StyledLink>
          {active && <BlueDot>.</BlueDot>}
        </>
      }
      {mobileMenuOpen &&
        <StyledLink mobileMenuOpen={mobileMenuOpen} {...rest}>{children}{active && <BlueDot>.</BlueDot>}</StyledLink>
      }
    </Container>)
}

NavLink.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  mobileMenuOpen: PropTypes.bool,
}

NavLink.defaultProps = {
  active: false,
}
