import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { BlogPostPage } from 'content/blog-post-page/BlogPostPage'

const BlogPost = ({
  data: { markdownRemark: { html, data, rawMarkdownBody } },
}) => {
  let desc = ''

  try {
    desc = rawMarkdownBody.split('\n')[0]
    if (desc.length > 161) {
      desc = desc.substring(0, 161)
      let i = desc.length
      while (i--) {
        if (desc.charAt(i) === ' ') {
          desc = `${desc.substring(0, i)}${i === 0 ? '' : '...'}`
          break
        }
      }
    }
  } catch (e) { /**/ }

  return <BlogPostPage siteDescription={desc.trim()} html={html} data={data}/>
}

BlogPost.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default BlogPost

export const query = graphql`
  query($_slug: String!) {
    markdownRemark(frontmatter: { _slug: { eq: $_slug } }) {
      ...BlogPostPage
    }
  }
`
