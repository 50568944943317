import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { media, generateFontSizeCss } from 'media'
import { einaRegular, einaSemiBold } from 'common-styles'

const Container = styled.div`
  background-color: ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
  color: ${({ theme, fontColor }) => theme.colors[fontColor]};
  padding: 30px;
  font-family: ${einaRegular};

  img {
    margin-bottom: 30px;
    color: ${({ theme }) => theme.colors.white};
    filter: ${({ fontColor }) => fontColor == 'black' ? 'invert(1)' : 'invert(0)'}
  }

  img::selection {
    filter: invert(1);
  }
  
  ${media.desktop`
    padding: 70px;
  `}
`

const Numbers = styled.div`
  font-family: ${einaSemiBold};
  margin-bottom: 15px;

  ${media.desktop`
    margin-bottom: 30px;
  `}
`

const LargeNumber = styled.span`
  ${generateFontSizeCss(55, 120)}
`

const SmallNumber = styled.span`
  ${generateFontSizeCss(22, 54)}
`

const Title = styled.h3`
  margin-bottom: 5px;
  font-weight: bold;
`

const Description = styled.p`
  line-height: 22px;
  margin-bottom: 0;
  opacity: 0.7;
`

export const Box = ({
  backgroundColor,
  largeNumber,
  smallNumber,
  title,
  description,
  fontColor,
  iconSrc,
  altText,
}) => {
  return (
    <Container backgroundColor={backgroundColor} fontColor={fontColor}>
      <img src={iconSrc} alt={altText} title={title} />
      <Numbers>
        <LargeNumber>{largeNumber}</LargeNumber>
        <SmallNumber>{smallNumber}</SmallNumber>
      </Numbers>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  )
}

Box.propTypes = {
  backgroundColor: PropTypes.string,
  largeNumber: PropTypes.string,
  smallNumber: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  fontColor: PropTypes.string,
  iconSrc: PropTypes.string,
  altText: PropTypes.string,
}