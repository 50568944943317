import React from 'react'
import styled, { css } from 'styled-components'
import _get from 'lodash.get'
import PropTypes from 'prop-types'

import { media } from 'media'
import { WorkGalleryEntry } from './WorkGalleryEntry'

const Container = styled.div`
  ${media.mobile`
    margin-top: -80px;
  `}
`

const INNER_DISTANCE = 10
const MARGIN_TOP_ODD = 100
const MARGIN_TOP_EVEN = 60
const FLEX_BASIS = `calc((100% / 2) - ${INNER_DISTANCE}px)`
const EntriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${media.desktop`
    >:nth-child(odd) {
      padding-right: ${INNER_DISTANCE}px;
      margin-top: ${MARGIN_TOP_ODD}px;
    }

    >:nth-child(even) {
      margin-top: ${MARGIN_TOP_ODD + MARGIN_TOP_EVEN}px;
      margin-bottom: auto;
    }

    >:nth-child(1) {
      margin-top: 0px;
    }

    >:nth-child(2) {
      margin-top: ${MARGIN_TOP_EVEN}px;
    }
  `}
`

const EntryContainer = styled.div`
  width: 100%;
  margin-bottom: ${({ isLast }) => isLast ? 0 : 60}px;
  z-index: 1;

  ${media.desktop`
    margin-bottom: auto;
    flex: 1 ${FLEX_BASIS};
    max-width: ${FLEX_BASIS};
    ${({ isLast }) => isLast && css`margin-top: 0 !important;`}
    >* {
      ${({ isReduced }) => isReduced ? `
        width: 80%;
        margin-right: auto;
        margin-left: auto;
      ` : ''}
    }
  `}
`

export const WorkGallery = ({ data }) => {
  const entries = _get(data, 'work', [])
  const reducedEntries = [true, false]
  return (
    <Container>
      <EntriesContainer>
        {entries
          .map((entry, i) => {
            /*
                Gallery entries are counted from left to right, bottom to top.
                [0]  [1]
                [2]  [3]
                [4]  [5]

                Some of those are reduces in size:
                0   1   2   3   4   5   6   7   8   9   10
                T   F   F   T   T   F   F   T   T   F   F

                ... along with even-numbered entries having an top offset (handled in styles)
             */

            let isReducedEntry = false
            if (i < 2) {
              isReducedEntry = reducedEntries[i]
            } else {
              const previousEntryIndex = reducedEntries.length - 1
              const previousEntry = reducedEntries[previousEntryIndex]
              const penultimateEntry = reducedEntries[previousEntryIndex - 1]
              if ((!previousEntry && !penultimateEntry) || (previousEntry && !penultimateEntry)) {
                isReducedEntry = true
              }
            }

            reducedEntries.push(isReducedEntry)
            return (
              <EntryContainer
                isLast={i === entries.length - 1}
                key={`work_gallery_entry_${i}`} isReduced={isReducedEntry}>
                <WorkGalleryEntry
                  isLast={i === entries.length - 1}
                  index={i}
                  entry={entry} />
              </EntryContainer>
            )
        })}
      </EntriesContainer>
    </Container>
  )
}

WorkGallery.propTypes = {
  data: PropTypes.shape().isRequired,
}
