import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import _get from 'lodash.get'
import PropTypes from 'prop-types'

import { theme } from 'theme'
import { sidePadding } from 'components/base/CenterColumn'
import { media } from 'media'
import { afterDot } from 'custom-css'
import { CoreValue, BASE_HEIGHT, IMAGE_MARGIN } from './CoreValue'
import { konnectSemiBold } from 'common-styles'
import { CommonContainer, WhiteBackground } from 'components/home/common'
import { Waypoint } from 'react-waypoint'

const CENTER_CONTAINER_DIM = '50vw'
const Container = styled(CommonContainer)``

const Background = styled(WhiteBackground)`
  ${media.min1300`
    padding: 140px 0 230px 0 !important;
  `}
`

const NotMobileHolder = styled.div`
  display: none;
  ${media.min1300`
    display: unset;
  `}
`

const MobileHolder =styled.div`
  ${media.min1300`
    display: none;
  `}
`

const opacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const MAX_CIRCLE_DIM_PAGE = 700
const MAX_CIRCLE_DIM_DESKTOP = 500
const CenterContainer = styled.div`
  position: relative;
  width: ${CENTER_CONTAINER_DIM};
  height: ${CENTER_CONTAINER_DIM};
  background: ${theme.colors.grayTertiary};
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.min1300`
    opacity: 1;
    margin-left: 0;
    max-width: ${MAX_CIRCLE_DIM_DESKTOP}px;
    max-height: ${MAX_CIRCLE_DIM_DESKTOP}px;
  `}
  ${media.page`
    max-width: ${MAX_CIRCLE_DIM_PAGE}px;
    max-height: ${MAX_CIRCLE_DIM_PAGE}px;
  `}
`

const CENTER_CONTENT_MAX_WIDTH = 140
const CENTER_CONTENT_MAX_HEIGHT = 100
const CenterContainerContent = styled.div`
  max-width: ${CENTER_CONTENT_MAX_WIDTH}px;
  max-height: ${CENTER_CONTENT_MAX_HEIGHT}px;
`

const CenterText = styled.div`
  font-family: ${konnectSemiBold};
  color: ${theme.colors.black};
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  ${afterDot(4)}
  
  ${media.desktop`
    ${afterDot(6)}
    font-size: 32px;
    line-height: 40px;
  `}
`

const calcTransform = index => 270 + (index * 45)

const INDEX_COEFFICIENT_MAP = {
  0: [ 1.5 ],
  1: [ 1.4 ],
  2: [ 1.3 ],
  3: [ 1.4 ],
  4: [ 1.5 ],
}
const NonMobileEntryWrapper = styled.div`
  ${media.min1300`
    width: ${({ index }) => `calc(100vw - ${6*sidePadding}px - (((${MAX_CIRCLE_DIM_DESKTOP}px/${INDEX_COEFFICIENT_MAP[index][0]}))))`};
    opacity: 0;
    animation: 0.5s cubic-bezier(0.8, 0, 0, 0.8) ${({ index }) => index * 0.3}s normal forwards 1 ${opacity};
    position: absolute;
    top: calc((${MAX_CIRCLE_DIM_DESKTOP}px/2) - ${BASE_HEIGHT/2}px);
    left: calc((${MAX_CIRCLE_DIM_DESKTOP}px/2) - ${2*sidePadding}px + ${BASE_HEIGHT}px);
    transform: ${({ index }) => `rotate(${calcTransform(index)}deg) translate(calc(${MAX_CIRCLE_DIM_DESKTOP}px/2 - ${index % 2 === 0 ? '0' : '75'}px)) rotate(-${calcTransform(index)}deg)`};
  `}
  ${media.page`
    width: calc(510px + ${BASE_HEIGHT}px + ${IMAGE_MARGIN}px);
    position: absolute;
    top: calc((${MAX_CIRCLE_DIM_PAGE}px/2) - ${BASE_HEIGHT/2}px);
    left: calc((${MAX_CIRCLE_DIM_PAGE}px/2) - ${2*sidePadding}px + ${BASE_HEIGHT}px);
    transform: ${({ index }) => `rotate(${calcTransform(index)}deg) translate(calc((${MAX_CIRCLE_DIM_PAGE}px/2) - ${index % 2 === 0 ? '0' : '100'}px)) rotate(-${calcTransform(index)}deg)`};
  `}
`

const MobileEntriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 30px;
  }
`

const DesktopWaypoint = styled(Waypoint)`
  ${media.desktopOnly};
`

export const CoreValues = ({ data }) => {
  const entries = _get(data, 'core_values.entries', [])
  const renderEntriesForNotMobile = () => {
    return (
      <>
        {entries.map((entry, i) => {
          return (
            <NonMobileEntryWrapper index={i} key={`core_value_${i}`}>
              <CoreValue entry={entry} />
            </NonMobileEntryWrapper>
          )
        })}
      </>
    )
  }

  const renderEntriesForMobile = () => {
    return (
      <MobileEntriesWrapper>
        {entries.map((entry, i) => {
          return <CoreValue key={`core_value_${i}`} entry={entry} />
        })}
      </MobileEntriesWrapper>
    )
  }

  let [renderCoreValues, setRenderCoreValues] = useState(false)

  return (
    <Background>
      <Container>
        <CenterContainer>
          <CenterContainerContent>
            <DesktopWaypoint bottomOffset={'120px'} onEnter={() => setRenderCoreValues(true)} />
            <CenterText>
              {_get(data, 'core_values.title', '')}
            </CenterText>
          </CenterContainerContent>
          {renderCoreValues && <><NotMobileHolder>{renderEntriesForNotMobile()}</NotMobileHolder></>}
        </CenterContainer>
        <MobileHolder>{renderEntriesForMobile()}</MobileHolder>
      </Container>
    </Background>
  )
}

CoreValues.propTypes = {
  data: PropTypes.shape().isRequired,
}