import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Icon from './Icon'

const Svg = styled(Icon)`
  width: 18px;
  height: 17.212px;
  color: ${({ theme }) => theme.colors.white};
  transform: scale(1.68);
`

export const Instagram = ({ className }) => (
  <Svg viewBox="0 0 30 30" fill="none" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.321 7.678c2.488 0 2.782.01 3.765.054a5.138 5.138 0 011.73.321 3.081 3.081 0 011.763 1.767 5.134 5.134 0 01.321 1.73c.045.982.054 1.277.054 3.765s-.01 2.782-.054 3.765a5.134 5.134 0 01-.321 1.73 3.085 3.085 0 01-1.768 1.768 5.163 5.163 0 01-1.73.321c-.982.045-1.277.054-3.765.054s-2.782-.01-3.765-.054a5.167 5.167 0 01-1.73-.321 3.089 3.089 0 01-1.767-1.768 5.183 5.183 0 01-.321-1.73c-.045-.982-.054-1.277-.054-3.765s.01-2.782.054-3.765a5.182 5.182 0 01.321-1.73 3.087 3.087 0 011.767-1.767 5.141 5.141 0 011.73-.321c.982-.045 1.277-.054 3.765-.054m0-1.679c-2.53 0-2.848.011-3.841.056a6.832 6.832 0 00-2.261.433 4.767 4.767 0 00-2.725 2.725 6.857 6.857 0 00-.433 2.262C6.01 12.469 6 12.786 6 15.316s.011 2.848.056 3.841a6.868 6.868 0 00.433 2.263 4.767 4.767 0 002.725 2.725 6.857 6.857 0 002.261.433c.994.045 1.311.056 3.841.056s2.848-.011 3.841-.056a6.846 6.846 0 002.261-.433 4.763 4.763 0 002.726-2.725 6.807 6.807 0 00.433-2.261c.045-.994.056-1.311.056-3.841s-.011-2.848-.056-3.841a6.8 6.8 0 00-.433-2.261 4.761 4.761 0 00-2.723-2.728 6.828 6.828 0 00-2.262-.433c-.994-.046-1.311-.056-3.841-.056zm0 4.531a4.784 4.784 0 104.784 4.784 4.784 4.784 0 00-4.784-4.784zm0 7.89a3.105 3.105 0 113.106-3.1 3.106 3.106 0 01-3.106 3.1zm4.974-9.2a1.118 1.118 0 11-1.118 1.118 1.117 1.117 0 011.118-1.115z"
    />
  </Svg>
)

Instagram.propTypes = {
  className: PropTypes.string,
}

export default Instagram
