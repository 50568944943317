import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { ServicesPage } from 'content/services-page/ServicesPage'

const Services = ({ data }) => {
  return <ServicesPage data={data.servicesJson}/>
}

Services.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default Services

export const query = graphql`  
  query($slug: String!) {
    servicesJson(fields: { slug: { eq: $slug } }) {
      ...ServicesPage
    }
  }
`
