import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-background-image'
import PropTypes from 'prop-types'
import React from 'react'

export const GradientBackground = ({ children, className }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "gradient_white.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return <Img className={className} fluid={data.file.childImageSharp.fluid} alt="Gradient background">{children}</Img>
}

GradientBackground.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}
