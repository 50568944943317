import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { media } from 'media'
import Icon from './Icon'

const Svg = styled(Icon)`
  width: 22px;
  height: auto;

  ${media.desktop`
    width: 22px;
    height: 21px;
  `}
  color: ${({ theme }) => theme.colors.white};
`

export const Calendar = ({ className }) => (
  <Svg viewBox="0 0 22 21" fill="none" className={className}>
    <g transform="translate(-507 -338)">
      <g fill="none" transform="translate(507 341)" stroke="currentColor" strokeWidth="2">
        <rect stroke="none" width="22" height="18"/>
        <rect fill="none" x="1" y="1" width="20" height="16"/>
      </g>
      <rect fill="currentColor" width="20" height="2" transform="translate(508 347)"/>
      <g fill="currentColor" transform="translate(512 339)">
        <rect stroke="none" width="2" height="6"/>
        <rect fill="none" x="-0.5" y="-0.5" width="3" height="7"/>
      </g>
      <g fill="currentColor" transform="translate(522 339)">
        <rect stroke="none" width="2" height="6"/>
        <rect fill="none" x="-0.5" y="-0.5" width="3" height="7"/>
      </g>
    </g>
  </Svg>
)

Calendar.propTypes = {
  className: PropTypes.string,
}

export default Calendar
