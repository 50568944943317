import PropTypes from 'prop-types'
import React from 'react'
import { Location } from '@reach/router'
import styled from 'styled-components'
import { einaLight, einaRegular, konnectSemiBold } from 'common-styles'

import { ButtonLinkWithIcon } from 'components/ButtonLink'
import { ArrowRight } from 'components/icons/ArrowRight'
import { NavLink } from 'components/NavLink'
import { CONTACT_ID } from 'constant'
import { media } from 'media'
import { graphql, useStaticQuery } from 'gatsby'
import _get from 'lodash.get'
import { GeneralContext } from '../context/general_context'
import HamburgerIcon from './icons/HamburgerIcon'
import { CenterColumn } from 'components/base/CenterColumn'

export const Container = styled.nav``

export const NavItems = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;

  .desktopNavItem {
    display: none;
  }

  ${media.desktop`
    .desktopNavItem {
      display: inherit;
    }

    .mobileNavItem {
      display: none;
    }
  `}

  ${media.smallMobile`
    &.mobileMenuItems {
      flex-direction: row !important;
      flex-wrap: wrap;
      justify-content: flex-start;

      li:nth-child(3) a,
      li:nth-child(5) a {
        margin: 0 0 0 35px;
      }

      li:nth-child(3),
      li:nth-child(5) {
        display: inline-flex;
      }

      li {
        width: 50%;
        
        &:first-child {
          width: 100%;
        }

        a {
          margin-right: auto;
          font-size: 21px;
        }

        a:nth-child(3) {
          margin: 0 0 0 50px !important;
        }

        a > span {
          margin-left: 5px;
          font-size: 30px;
          top: -6px;
        }
      }
    }
  `}
`

export const NavItem = styled.li`
  z-index: 1;
  
  :not(:first-child) {
    margin-left: 20px;
  }

  ${({ mobileMenuOpen, theme }) => mobileMenuOpen ? `
    #home-lets_talk,
    #undefined-lets_talk,
    #imprint-lets_talk,
    #privacy-lets_talk,
    #team-lets_talk,
    #services-lets_talk,
    #blog-lets_talk,
    #blog-post-lets_talk,
    #jobs-overview-lets_talk,
    #jobs-lets_talk {
      color: ${theme.colors.black};
    }
  ` : ''}

  ${media.desktop`
    :not(:first-child) {
      margin-left: 40px;
    }
  `}
`

export const MobileMenuOverlay = styled.div`
  position: absolute;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
`

export const MobileMenuColumn = styled(CenterColumn)`
  margin-top: 110px;

  .mobileMenuItems,
  .socialMedia {
    flex-direction: column;
    align-items: flex-start;

    li {
      margin: 0 0 20px 0 !important;
    }
  
    li a {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .socialMedia {
    margin-top: 5vh;

    li a {
      font-family: ${einaLight};
      font-size: 14px;
    }
  }

  .callToActions {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

export const CallToAction = styled.div`
  background-color: ${({ theme }) => theme.colors.grayTertiary};
  color: ${({ theme }) => theme.colors.black};
  padding: 3rem 0;
  text-align: center;
  width: 50%;

  &:first-child {
    border-right: 1px solid ${({ theme }) => theme.colors.grey};
  }

  p {
    font-family: ${konnectSemiBold};
    font-size: 15px;
    margin-bottom: 5px;
  }

  a {
    font-family: ${einaRegular};
    font-size: 14px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.blue};
  }
`

const NotificationBubble = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  margin-left: 6px;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.white};
`

const JobsNavLink = styled(NavLink)`
  a {
    display: flex;
    flex-direction: row;

    > div {
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }
`

export const Nav = ({ contactPrefix, isLightTheme = false, idPrefix, slug_prefix = '', locale = 'en', filledColorButton = false }) => {
  const data = useStaticQuery(graphql`
    query {
      allHeaderJson(filter: {_for: {eq: "index"}}) {
        edges {
          node {
            entries {
              lets_talk
              blog
              locale
              services
              why_us
              team
              jobs
              email_prefer
              phone_prefer
            }
          }
        }
      }
    }
  `)

  const {
    mobileMenuOpen,
    setMobileMenuOpen,
    numberOfJobOpenings,
  } = React.useContext(GeneralContext)
  const headerEntries = _get(data, 'allHeaderJson.edges.0.node.entries', [])
  const headerData = headerEntries.find(h => h.locale === locale)

  return (
    <Container>
      <NavItems>
        <NavItem className="desktopNavItem">
          <Location>
            {({ location }) => (
              <NavLink
                isLightTheme={isLightTheme}
                to={`${slug_prefix ? `/${slug_prefix}` : ''}/services`}
                active={location.pathname.indexOf('/services') > -1}
              >{_get(headerData, 'services', '')}</NavLink>
            )}
          </Location>
        </NavItem>
        <NavItem className="desktopNavItem">
          <Location>
            {({ location }) => (
              <NavLink
                isLightTheme={isLightTheme}
                to={`${slug_prefix ? `/${slug_prefix}` : ''}/why-us`}
                active={location.pathname.indexOf('/why-us') > -1}
              >{_get(headerData, 'why_us', '')}</NavLink>
            )}
          </Location>
        </NavItem>
        <NavItem className="desktopNavItem">
          <Location>
            {({ location }) => (
              <NavLink
                isLightTheme={isLightTheme}
                to={`${slug_prefix ? `/${slug_prefix}` : ''}/team`}
                active={location.pathname.indexOf('/team') > -1}
              >{_get(headerData, 'team', '')}</NavLink>
            )}
          </Location>
        </NavItem>
        {/* <NavItem className="desktopNavItem">
          <Location>
            {({ location }) => (
              <NavLink
                isLightTheme={isLightTheme}
                to={`${slug_prefix ? `/${slug_prefix}` : ''}/blog`}
                active={location.pathname.indexOf('/blog') > -1}
              >{_get(headerData, 'blog', '')}</NavLink>
            )}
          </Location>
        </NavItem> */}

        <NavItem className="desktopNavItem">
          <Location>
            {({ location }) => (
              <JobsNavLink
                isLightTheme={isLightTheme}
                to={`${slug_prefix ? `/${slug_prefix}` : ''}/jobs`}
                id='jobs-desktop'
                active={location.pathname.indexOf('/jobs') > -1}
              >
                {_get(headerData, 'jobs', '')}
                {(numberOfJobOpenings > 0) &&
                  <NotificationBubble id='notification-bubble'>
                    {numberOfJobOpenings}
                  </NotificationBubble>
                }
              </JobsNavLink>
            )}
          </Location>
        </NavItem>

        <NavItem mobileMenuOpen={mobileMenuOpen} onClick={() => setMobileMenuOpen(false)}>
          <ButtonLinkWithIcon
            filledColorButton={filledColorButton}
            id={`${idPrefix}-lets_talk`}
            isLightTheme={isLightTheme}
            to={`${contactPrefix ? `#${CONTACT_ID}` : `${slug_prefix ? `/${slug_prefix}` : ''}/#${CONTACT_ID}`}`}
            icon={<ArrowRight />}
          >{_get(headerData, 'lets_talk', '')}</ButtonLinkWithIcon>
        </NavItem>
        <NavItem className="mobileNavItem" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
          <HamburgerIcon cssClasses={`${mobileMenuOpen ? '-menu-open' : ''}`} isLightTheme={isLightTheme} />
        </NavItem>
      </NavItems>
      {mobileMenuOpen &&
        <MobileMenuOverlay>
          <MobileMenuColumn>
            <NavItems className="mobileMenuItems">
              <NavItem>
                <Location>
                  {({ location }) => (
                    <NavLink
                      onClick={() => setMobileMenuOpen(false)}
                      mobileMenuOpen={mobileMenuOpen}
                      isLightTheme={isLightTheme}
                      to={`${slug_prefix ? `/${slug_prefix}` : ''}/services`}
                      active={location.pathname.indexOf('/services') > -1}
                    >{_get(headerData, 'services', '')}</NavLink>
                  )}
                </Location>
              </NavItem>
              <NavItem>
                <Location>
                  {({ location }) => (
                    <NavLink
                      onClick={() => setMobileMenuOpen(false)}
                      mobileMenuOpen={mobileMenuOpen}
                      isLightTheme={isLightTheme}
                      to={`${slug_prefix ? `/${slug_prefix}` : ''}/why-us`}
                      active={location.pathname.indexOf('/why-us') > -1}
                    >{_get(headerData, 'why_us', '')}</NavLink>
                  )}
                </Location>
              </NavItem>
              <NavItem>
                <Location>
                  {({ location }) => (
                    <NavLink
                      onClick={() => setMobileMenuOpen(false)}
                      mobileMenuOpen={mobileMenuOpen}
                      isLightTheme={isLightTheme}
                      to={`${slug_prefix ? `/${slug_prefix}` : ''}/team`}
                      active={location.pathname.indexOf('/team') > -1}
                    >{_get(headerData, 'team', '')}</NavLink>
                  )}
                </Location>
              </NavItem>
              {/* <NavItem>
                <Location>
                  {({ location }) => (
                    <NavLink
                      onClick={() => setMobileMenuOpen(false)}
                      mobileMenuOpen={mobileMenuOpen}
                      isLightTheme={isLightTheme}
                      to={`${slug_prefix ? `/${slug_prefix}` : ''}/blog`}
                      active={location.pathname.indexOf('/blog') > -1}
                    >{_get(headerData, 'blog', '')}</NavLink>
                  )}
                </Location>
              </NavItem> */}
              <NavItem>
                <Location>
                  {() => (
                    <NavLink
                      onClick={() => setMobileMenuOpen(false)}
                      mobileMenuOpen={mobileMenuOpen}
                      isLightTheme={isLightTheme}
                      active={location.pathname.indexOf('/jobs') > -1}
                      to={`${slug_prefix ? `/${slug_prefix}` : ''}/jobs`}
                    >
                      {_get(headerData, 'jobs', '')}
                      <NotificationBubble id='notification-bubble'>
                        {numberOfJobOpenings}
                      </NotificationBubble>
                    </NavLink>
                  )}
                </Location>
              </NavItem>
            </NavItems>
            <NavItems className="socialMedia">
              <NavItem>
                <a href="https://www.instagram.com/9y.agency/?hl=en" target="_blank" rel="noopener noreferrer" aria-label="Instagram">Instagram</a>
              </NavItem>
              <NavItem>
                <a href="https://www.linkedin.com/company/9yagency" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">LinkedIn</a>
              </NavItem>
              <NavItem>
                <a href="https://www.facebook.com/9y.agency/" target="_blank" rel="noopener noreferrer" aria-label="Facebook">Facebook</a>
              </NavItem>
              <NavItem>
                <a href="https://clutch.co/profile/9y" target="_blank" rel="noopener noreferrer" aria-label="Clutch">Clutch</a>
              </NavItem>
            </NavItems>
            <NavItems className="callToActions">
              <CallToAction>
                <p>{_get(headerData, 'email_prefer', '')}</p>
                <a href="mailto:hello@9y.co">hello@9y.co</a>
              </CallToAction>
              <CallToAction>
                <p>{_get(headerData, 'phone_prefer', '')}</p>
                <a href="tel:+4314120096">+43 1 4120096</a>
              </CallToAction>
            </NavItems>
          </MobileMenuColumn>
        </MobileMenuOverlay>
      }
    </Container >
  )
}

Nav.propTypes = {
  contactPrefix: PropTypes.string,
  isLightTheme: PropTypes.bool,
  idPrefix: PropTypes.string,
  slug_prefix: PropTypes.string,
  locale: PropTypes.string,
}

Nav.defaultProps = {
  contactPrefix: '',
}

export default Nav
