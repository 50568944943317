import React from 'react'
import styled from 'styled-components'
import _get from 'lodash.get'

import { media } from 'media'
import { WhiteBackground } from 'components/home/common'
import { CenterColumn } from 'components/base/CenterColumn'
import { Box } from 'components/home/facts/Box'

const Background = styled(WhiteBackground)`
  padding: 0;
  
  ${media.desktop`
    padding: 0;
  `}
`

const Container = styled(CenterColumn)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.white};

  ${media.desktop`
    flex-direction: row;
    padding: 0;
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.mobile`
    > div:not(:last-child) {
      margin: 0 0 20px 0;
    }
  `}
  
  ${media.desktop`
    flex-direction: row;
    justify-content: space-evenly;

    > div {
      width: 21%;
    }
  `}
`

export const Facts = ({ data }) => {
  const facts = _get(data, 'facts', [])

  return (
    <Background>
      <Container>
        <Content>
          {facts.map((fact, i) => {
            return (
              <Box
                key={`fact_box_${i}`}
                largeNumber={_get(fact, 'large_number', '')}
                smallNumber={_get(fact, 'small_number', '')}
                title={_get(fact, 'title', '')}
                description={_get(fact, 'description', '')}
                backgroundColor={_get(fact, 'background_color', '')}
                fontColor={_get(fact, 'font_color', '')}
                iconSrc={_get(fact, 'icon.publicURL', '')}
                altText={_get(fact, 'alt_text', '')} />
            )
          })}
        </Content>
      </Container>
    </Background>
  )
}
