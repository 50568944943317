import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { CenterColumn } from 'components/base/CenterColumn'
import { media } from 'media'
import { WhiteBackground } from 'components/home/common'

const Background = styled(WhiteBackground)`
  padding: 60px 0;

  ${media.desktop`
    padding: 90px 0;
  `}
`

const Container = styled(CenterColumn)`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black};
`

const ContentContainer = styled.div`
  max-width: 1300px;
  width: 100%;

  ${media.desktop`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  `}
`

const ReasonContainer = styled.article`
  ${media.mobile`
    display: flex;
    align-items: center;

    ${({ index }) => index !== 8 ? 
      'margin-bottom: 35px;' : 
      'margin-bottom: 0;'
    }
  `}

  ${media.desktop`
    width: 30%;
    max-width: 370px;

    ${({ index }) => index < 6 ? 
      'margin-bottom: 90px;' : 
      'margin-bottom: 0;'
    }
  `}
`

const ReasonIcon = styled.img`
  ${media.mobile`
    margin-right: 20px;
    max-width: 50px;
  `}
  
  ${media.desktop`
    margin-bottom: 20px;
  `}
`

const Description = styled.p`
  line-height: 22px;
  margin-bottom: 0;

  ${media.desktop`
    line-height: 28px;
  `}
`

export const Reasons = ({ data }) => {
  const entries = data.reasons.entries

  return (
    <Background>
      <Container>
        <ContentContainer>
          {entries.map((entry, i) => {
            return (
              <ReasonContainer index={i} key={`reason_${i}`}>
                <ReasonIcon src={entry.image.publicURL} alt={entry.alt_text} title={entry.alt_text} />
                <Description dangerouslySetInnerHTML={{ __html: entry.text }} />
              </ReasonContainer>
            )
          })}
        </ContentContainer>
      </Container>
    </Background>
  )
}

Reasons.propTypes = {
  data: PropTypes.shape().isRequired,
}
