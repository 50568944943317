import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export const GeneralContext = createContext({
  mobileMenuOpen: false,
  setMobileMenuOpen: (mobileMenuOpen) => { /**/ },
})

export const GeneralContextProvider = ({ children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [numberOfJobOpenings, setNumberOfJobOpenings] = useState(0)

  const { jobOpenings } = useStaticQuery(graphql`
    query {
      jobOpenings: allMarkdownRemark (filter: {frontmatter: {_enabled: {eq: true},  _type: {eq: "job"}}}) { 
        edges {
          node {
            frontmatter{

              _locale
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setNumberOfJobOpenings(jobOpenings.edges.length / 2)
  }, [
    jobOpenings,
    setNumberOfJobOpenings,
  ])

  const value = useMemo(() => ({
    mobileMenuOpen,
    setMobileMenuOpen,
    numberOfJobOpenings,
    setNumberOfJobOpenings,
  }), [
    mobileMenuOpen,
    setMobileMenuOpen,
    numberOfJobOpenings,
    setNumberOfJobOpenings,
  ])

  useEffect(() => {
    document.body.style.overflow = mobileMenuOpen ? 'hidden' : null
  }, [mobileMenuOpen])

  return (
    <GeneralContext.Provider value={value}>
      {children}
    </GeneralContext.Provider>
  )
}
