import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Icon from './Icon'

const Svg = styled(Icon)`
  width: 18px;
  height: 17.212px;
  color: ${({ theme }) => theme.colors.white};
  transform: scale(1.6);
`

export const Clutch = ({ className }) => (
  <Svg viewBox="0 0 30 30" fill="none" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.598 18.943a5.549 5.549 0 01-3.745 1.389 5.35 5.35 0 01-5.508-5.188v-.343a5.229 5.229 0 015.012-5.436c.164-.006.329-.005.494 0a5.68 5.68 0 013.77 1.364l.694.6 3.075-3.075-.769-.719a9.953 9.953 0 00-6.77-2.532 9.492 9.492 0 00-9.844 9.127q-.012.321 0 .642a9.651 9.651 0 009.4 9.9h.448a9.958 9.958 0 006.821-2.579l.744-.694-3.125-3.075zm-4.034-6.544a2.425 2.425 0 11-2.425 2.425 2.425 2.425 0 012.424-2.425z"
    />
  </Svg>
)

Clutch.propTypes = {
  className: PropTypes.string,
}

export default Clutch
