import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import { konnectSemiBold } from 'common-styles'
import Icon from 'components/icons/Icon'
import { media } from 'media'

export const buttonStyle = css`
  display: flex;
  align-items: center;
  font-family: ${konnectSemiBold};
  font-size: 14px;
  margin: 0;
  color: ${({ filledColorButton, theme, isLightTheme }) => isLightTheme || filledColorButton ? theme.colors.black : theme.colors.white};
  padding: 10px;
  border: 1px solid ${({ filledColorButton, theme }) => filledColorButton ? theme.colors.white : theme.colors.blue};
  border-radius: 50%;
  transition: color 0.2s ease, background 0.2s ease;
  background-color: ${({ filledColorButton, theme }) => filledColorButton ? theme.colors.white : 'none' };

  ${Icon} {
    color: ${({ theme }) => theme.colors.blue};
    ${({ isLeftIcon }) => isLeftIcon ? 'margin-right: 10px' : 'margin-left: 10px;'}
    transition: color 0.2s ease;
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.blue};
    text-decoration: none;
    border: 1px solid ${({ filledColorButton, theme }) => filledColorButton ? theme.colors.blue : 'inherit' };

    ${Icon} {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  ${media.desktop`
    font-size: 15px;
  `}
`

/* eslint-disable-next-line */
export const ButtonLink = styled(({ isLeftIcon, ...rest }) => <Link {...rest}/>)`
  ${buttonStyle};
`
export const ExternalButtonLink = styled.a`
  ${buttonStyle};
`

export const buttonStyleLarge = css`
  ${buttonStyle};
  padding: 10px 15px;
  border-radius: 200px;

  ${media.desktop`
    padding: 15px 20px;
  `}
`
export const ButtonLinkLarge = styled(ButtonLink)`
  ${buttonStyleLarge};
`
export const ExternalButtonLinkLarge = styled.a`
  ${buttonStyleLarge};
`

export const ButtonLinkWithIcon = ({ children, icon, isLeftIcon, ...rest }) => (
  <ButtonLinkLarge isLeftIcon={isLeftIcon} {...rest}>
    {isLeftIcon && icon}
    {children}
    {!isLeftIcon && icon}
  </ButtonLinkLarge>
)

ButtonLinkWithIcon.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.any,
  isLeftIcon: PropTypes.bool,
}

ButtonLinkWithIcon.defaultProps = {
  isLeftIcon: false,
}

export const ExternalButtonLinkWithIcon = ({ children, icon, isLeftIcon, ...rest }) => (
  <ExternalButtonLinkLarge isLeftIcon={isLeftIcon} {...rest}>
    {isLeftIcon && icon}
    {children}
    {!isLeftIcon && icon}
  </ExternalButtonLinkLarge>
)

ExternalButtonLinkWithIcon.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.any,
  isLeftIcon: PropTypes.bool,
}

ExternalButtonLinkWithIcon.defaultProps = {
  isLeftIcon: false,
}
