import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import axios from 'axios'
import _debounce from 'lodash.debounce'
import _get from 'lodash.get'
import ReactLoading from 'react-loading'
import Img from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby'

import { einaRegular } from 'common-styles'
import { CenterColumn } from 'components/base/CenterColumn'
import { ExternalButtonLinkWithIcon } from 'components/ButtonLink'
import { ArrowRight } from 'components/icons/ArrowRight'
import { Cross } from 'components/icons/Cross'
import { Check } from 'components/icons/Check'
import { media } from 'media'
import { theme } from 'theme'
import { HOVER_LINE_HEIGHT } from 'custom-css'
import Icon from 'components/icons/Icon'
import { appendBlueCharacter } from 'utils'

const Container = styled(CenterColumn)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.black};
  padding: 50px 0 40px 0;

  ${media.desktop`
    flex-direction: row;
    padding: 100px 0;
  `}
`

const TitleMobile = styled.h3`
  ${media.mobileOnly};
  margin-bottom: 40px;
  max-width: 90%;
  line-height: 32px;
  font-size: 26px;
`

const TitleDesktop = styled.div`
  ${media.desktopOnly};
  display: flex;
  width: 50%;
  justify-content: center;
  margin-right: 20px;
  line-height: 56px;
  >:first-child {
    :before {
      transform: scaleY(-1);
      width: 60%;
    }
  }
`

const Title = styled.h3`
  height: 370px;
  padding: 60px 40px;
  box-sizing: border-box;
  font-size: 46px;
  line-height: 56px;

  ${media.desktop`
    padding-right: 20%;
  `}
`

const StyledBackgroundImage = styled(Img)`
  :before {
    width: 60% !important;
    transform: scaleY(-1) !important;
  }

  min-width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.desktop`
    padding-top: 60px;
    max-width: 500px;
    width: 50%;
  `}
`

const Subtitle = styled.h3`
  line-height: 21px;
  
  ${media.desktop`
    margin-bottom: 15px;
  `}
`

const FormElement = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const padding = 20
const formElementStyle = css`
  margin: 0;
  padding: ${padding}px;
  border: 2px solid ${({ theme }) => theme.colors.graySecondary};
  background: transparent;
  text-shadow: none;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 15px;
  width: 100%;
  box-sizing: border-box;
  font-family: ${einaRegular};
  font-size: 14px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.white};
  }

  ${media.desktop`
    font-size: 16px;
    padding: 17px ${padding}px;
  `}
`

const EMOJI_SIZE = 30
const ConfusedFaceEmoji = css`
  ${({ isInvalidEntry }) => isInvalidEntry ? `
    :after {
      content: "\\01F615";
      position: relative;
      font-size: ${EMOJI_SIZE}px;
      top: ${padding + 10}px;
      left: -${EMOJI_SIZE * 1.6}px;
    }
  ` : ''}
`

const EmailEmojiContainer = styled.div`
  display: flex;
  width: calc(100% + ${({ isInvalidEntry }) => isInvalidEntry ? 30 : 0}px);
  position: relative;
  margin: auto;
  ${media.desktop`
    width: calc(80% + ${({ isInvalidEntry }) => isInvalidEntry ? 30 : 0}px);
    margin-right: auto;
    margin-left: 0;
  `}

  ${ConfusedFaceEmoji}
`

const MessageEmojiContainer = styled.div`
  display: flex;
  width: calc(100% + ${({ isInvalidEntry }) => isInvalidEntry ? 30 : 0}px);
  position: relative;
  margin: auto;

  ${ConfusedFaceEmoji}
`

const EmailInput = styled.input`
  ${formElementStyle};
  ${({ isInvalidEntry }) => isInvalidEntry ? `
    background-color: #2F1112;
    border: 1px solid #781D1D;
  ` : ''}
  
  ${({ disabled }) => disabled ? `
    color: #777777;
  ` : ''}
`

const MESSAGE_INPUT_HEIGHT = 180
const MessageInput = styled.textarea`
  ${formElementStyle};
  resize: none;
  height: ${MESSAGE_INPUT_HEIGHT}px;
  min-height: ${MESSAGE_INPUT_HEIGHT}px;
  overflow-y: hidden;
  word-wrap:break-word
  ${({ isInvalidEntry, theme }) => isInvalidEntry ? `
    background-color: ${theme.colors.errorBackground};
    border: 1px solid ${theme.colors.errorForeground};
  ` : ''}
  ${({ disabled }) => disabled ? `
    color: #777777;
  ` : ''}
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  margin-top: 20px;
`

const Submit = styled(ExternalButtonLinkWithIcon)`
  margin-left: 20px;
  ${({ disabled, theme }) => disabled ? `
    opacity: 0.5;
    text-decoration: none;
    &:hover {
      cursor: default;
      color: ${theme.colors.white};
      background: none;
      ${Icon} {
        color: ${theme.colors.blue};
      }
    }
  ` : ''}

  ${({ isError, theme }) => isError ? `
    border: 1px solid ${theme.colors.errorForeground};
  ` : ''}

  ${media.desktop`
    margin-left: 0;
    font-size: 15px;
    svg {
      width: 18px;
      height: auto;
    }
  `}
`

const CallToActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: 50%;
  flex-direction: column;

  ${media.desktop`
    width: width: 100%;
    justify-content: flex-start;
    flex-direction: row;
  `}

  @media only screen and (max-width: 900px) {
    h4, a {
      margin: 0 auto;
    }
  }
`

const SendSubTitle = styled.h4`
  padding: 0;
  
  ${media.mobile`
    margin: 0 0 5px 0 !important;
  `}

  ${media.desktop`
    margin: 0;
  `}
`

const SendSubContent = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  line-height: ${HOVER_LINE_HEIGHT}px;
  padding: 0;
  font-family: ${einaRegular};
  
  :hover {
    text-decoration: none;
  }

  ${media.desktop`
    margin-left: 10px;
  `}
`

const CallToActions = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;

  a {
    border-bottom: 2px solid ${({ theme }) => theme.colors.blue};
  }
`

const STEP = {
  SEND: 1,
  SENDING: 2,
  SENT: 3,
  SENT_ERROR: 4,
}

export const Form = ({
  idPrefix = '',
  _for = 'blog',
  locale = 'en',
}) => {
  const messageInputRef = useRef(null)
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "gradient_white_small.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allFormJson {
        edges {
          node {
            _for
            entries {
              locale
              title
              sub_title
              email_place_holder
              email_prefer
              message_place_holder
              phone_prefer
              submit_text_send
              submit_text_sent
              submit_text_error
            }
          }
        }
      }
    }
  `)

  const formNode = data.allFormJson.edges.find(e => e['node']['_for'] === _for)
  const formData = formNode['node']['entries'].find(e => e.locale === locale)
  const formTitle = _get(formData, 'title', '')
  const formSubTitle = _get(formData, 'sub_title', '')

  /**
   * For can be in various steps/states
   */
  const [step, setStep] = useState(STEP.SEND)

  const [formState, setFormState] = useState({
    isFocus: false,
    isEmailInvalid: false,
    isMessageInvalid: false,
    email: '',
    message: '',
  })

  const checkEmail = (val) => /\S+@\S+\.\S+/.test(val)
  const checkMessage = (val) => (val).length >= 3

  const onEmailChange = (e => {
    setFormState({
      ...formState,
      email: e.target.value,
      isEmailInvalid: !checkEmail(e.target.value),
    })
  })

  const onMessageChange = (e => {
    setFormState({
      ...formState,
      message: e.target.value,
      isMessageInvalid: !checkMessage(e.target.value),
    })
  })

  const submit = async () => {
    setFormState({
      ...formState,
      isFocus: true,
      isMessageInvalid: !checkMessage(formState.message),
      isEmailInvalid: !checkEmail(formState.email),
    })

    if (step !== STEP.SEND || !checkEmail(formState.email) || !checkMessage(formState.message)) {
      return
    }

    try {
      setStep(STEP.SENDING)
      const returnData = await axios({
        method: 'post',
        url: `https://forms.9y.engineering/send?form=contact${process.env.GATSBY_ENV === 'development' ? '&test=true' : ''}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: {
          email: formState.email.trim(),
          message: formState.message.trim(),
        },
      })

      if (returnData.data && returnData.data.status === 'OK') {
        setStep(STEP.SENT)
      } else {
        setStep(STEP.SENT_ERROR)
      }
    } catch (e) {
      setStep(STEP.SENT_ERROR)
    }
  }

  const checkDisabled = step !== STEP.SEND
  let submitIcon = <ArrowRight />
  let submitText = _get(formData, 'submit_text_send', 'Send')
  if (step === STEP.SENT) {
    submitText = _get(formData, 'submit_text_sent', 'Sent')
    submitIcon = <Check />
    document.getElementById(`${idPrefix}-contact_form_send`).id = `${idPrefix}-contact_form_sent`
  } else if (step === STEP.SENDING) {
    submitText = ' '
    submitIcon = <ReactLoading type={'spin'} color={theme.colors.white} height={'20px'} width={'20px'} />
  } else if (step === STEP.SENT_ERROR) {
    submitText = _get(formData, 'submit_text_error', 'Error')
    submitIcon = <Cross />
  }

  return (
    <Container>
      <TitleMobile>{appendBlueCharacter(formTitle)}</TitleMobile>
      <TitleDesktop>
        <StyledBackgroundImage fluid={data.file.childImageSharp.fluid} alt="Gradient background">
          <Title>{appendBlueCharacter(formTitle)}</Title>
        </StyledBackgroundImage>
      </TitleDesktop>
      <Content>
        <Subtitle>{appendBlueCharacter(formSubTitle)}</Subtitle>
        <FormElement id={'contact_form'}>
          <EmailEmojiContainer isInvalidEntry={formState.isFocus && (formState.isEmailInvalid || step === STEP.SENT_ERROR)}>
            <EmailInput
              disabled={checkDisabled ? 'disabled' : null}
              isInvalidEntry={formState.isFocus && (formState.isEmailInvalid || step === STEP.SENT_ERROR)}
              value={formState.email}
              onChange={onEmailChange}
              type={'email'}
              placeholder={_get(formData, 'email_place_holder', '')} />
          </EmailEmojiContainer>
          <MessageEmojiContainer isInvalidEntry={formState.isFocus && (formState.isMessageInvalid || step === STEP.SENT_ERROR)}>
            <MessageInput
              onKeyUp={_debounce(() => {
                if (messageInputRef && messageInputRef.current) {
                  const el = messageInputRef.current
                  // In case the content of the message text-area exceeds its height,
                  // increase the height to accommodate the content.
                  if (el.scrollHeight > el.clientHeight) {
                    el.style.overflowY = 'hidden'
                    el.style.height = '1px'
                    el.style.height = `${el.scrollHeight + 5}px`
                  }
                }
              }, 200)}
              ref={messageInputRef}
              disabled={checkDisabled ? 'disabled' : null}
              isInvalidEntry={formState.isFocus && (formState.isMessageInvalid || step === STEP.SENT_ERROR)}
              value={formState.message}
              onChange={onMessageChange}
              placeholder={_get(formData, 'message_place_holder', '')} />
          </MessageEmojiContainer>
          <ButtonRow>
            <Submit
              id={`${idPrefix}-contact_form_send`}
              disabled={formState.isFocus && (formState.isMessageInvalid || formState.isEmailInvalid || step !== STEP.SEND)}
              icon={submitIcon}
              isError={step === STEP.SENT_ERROR}
              onClick={submit}
            >
              {submitText}
            </Submit>
          </ButtonRow>
          <CallToActions>
            <CallToActionContainer>
              <SendSubTitle>{_get(formData, 'email_prefer', '')}</SendSubTitle>
              <SendSubContent href="mailto:hello@9y.co?Subject=Hello">hello@9y.co</SendSubContent>
            </CallToActionContainer>
            <CallToActionContainer>
              <SendSubTitle>{_get(formData, 'phone_prefer', '')}</SendSubTitle>
              <SendSubContent className="phoneNumber" href="tel:+43 1 4120096">{'+43 1 4120096'}</SendSubContent>
            </CallToActionContainer>
          </CallToActions>
        </FormElement>
      </Content>
    </Container>
  )
}

Form.propTypes = {
  idPrefix: PropTypes.string,
  _for: PropTypes.string,
  locale: PropTypes.string,
}
