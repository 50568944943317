import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Footer from 'components/footer'
import { Head } from 'components/Head'
import { Header }  from 'components/Header'
import Nav from 'components/Nav'
import GlobalStyle from 'global.css'
import { media } from 'media'
import { BOTTOM_OFFSET_LARGE, BOTTOM_OFFSET_MOBILE } from 'components/home/common'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.black};
  color: white;
  overflow-x: hidden;
  ${({ isHome }) => isHome
  ? `
    overflow-y: hidden;
    padding-bottom: ${BOTTOM_OFFSET_MOBILE}px;
  `
  : ''}
  ${media.desktop`
    overflow-y: hidden;
    ${({ isHome }) => isHome ? 'padding-bottom: 0;' : ''}
  `}
`

const Content = styled.div`
  position: relative;
  z-index: 0;
  ${media.desktop`
  ${({ isHome }) => isHome
    ? `bottom: ${BOTTOM_OFFSET_LARGE}px;`
    : ''}
  `}
`

const Layout = ({
  imageUrl = '',
  isHome,
  disableHeader,
  children,
  locale = 'en',
  header,
  metadata,
  contactPrefix,
  idPrefix,
  slug_prefix = '',
}) => (
  <Container isHome={isHome}>
    <GlobalStyle />
    <Head
      imageUrl={imageUrl}
      metadata={metadata} />
    {!disableHeader && (
      header !== undefined
        ? header
        : <Header slug_prefix={slug_prefix}>
            <Nav locale={locale} slug_prefix={slug_prefix} idPrefix={idPrefix} contactPrefix={contactPrefix}/>
          </Header>
    )}
    <Content isHome={isHome}>{children}</Content>
    <Footer locale={locale} slug_prefix={slug_prefix}/>
  </Container>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  metadata: PropTypes.shape().isRequired,
  nav: PropTypes.any,
  header: PropTypes.any,
  contactPrefix: PropTypes.string,
  disableHeader: PropTypes.bool,
  isHome: PropTypes.bool,
  idPrefix: PropTypes.string,
  slug_prefix: PropTypes.string,
  locale: PropTypes.string,
  imageUrl: PropTypes.string,
}

Layout.defaultProps = {
  metadata: {},
  disableHeader: false,
}

export default Layout
