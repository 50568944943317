import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import _get from 'lodash.get'

import { CenterColumn } from 'components/base/CenterColumn'
import { GrayBackground } from 'components/home/common'
import { Offers } from 'components/services/offers'
import { Values } from 'components/services/values'
import { Quotes } from 'components/Quotes'
import { Customers } from 'components/services/customers'
import { Reviews } from 'components/services/reviews'
import { Form } from 'components/Form'
import Layout from 'components/Layout'
import { CONTACT_ID } from 'constant'
import { media } from 'media'
import { GeneralContext } from '../../context/general_context'
import { IndexHeader } from 'components/home/Header'
import { Project } from 'components/services/project'
import { HeroTitle } from 'components/base/Typography'

const HeroSection = styled(CenterColumn)`
  height: 85vh;
`

const HeroContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`

const SectionContainer = styled.section`
  position: relative;
`

const HeroBackground = styled.div`
  background-repeat: no-repeat;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: 50% 65%;
`

const StyledQuotes = styled(Quotes)`
  padding: 0;
`

const StyledProject = styled(Project)`
  padding: 60px 0;

  ${media.desktop`
    padding: 150px 0;
  `}
`

export const ServicesPage = ({ data }) => {
  const { mobileMenuOpen } = React.useContext(GeneralContext)

  return (
    <Layout
      locale={data._locale}
      slug_prefix={data._slug_prefix}
      idPrefix={'services'}
      disableHeader
      metadata={data._meta}
    >
      <SectionContainer>
        <HeroBackground imageUrl={data.hero_background.childImageSharp.fluid.src}>
        <HeroSection>
          <IndexHeader
            page={'ServicesPage'}
            locale={data._locale}
            slug_prefix={data._slug_prefix}
            idPrefix={'services'}
            position={'top'}
            contactPrefix={'services'} />
          <HeroContainer>
            <HeroTitle>
              {_get(data, 'title', '')}
            </HeroTitle>
          </HeroContainer>
        </HeroSection>
        </HeroBackground>
        <Offers data={data} />
      </SectionContainer>
      <SectionContainer mobileMenuOpen={mobileMenuOpen}>
        <IndexHeader
          page={'ServicesPage'}
          locale={data._locale}
          slug_prefix={data._slug_prefix}
          idPrefix={'services'}
          position={'middle'}
          isHidden={true}
          isLightTheme={true}
          contactPrefix={'services'} />
        <Values data={data} />
        <StyledQuotes locale={data._locale} _for={'services'}/>
        <StyledProject data={data} />
        <Customers data={data} locale={data._locale} />
        <GrayBackground>
          <Reviews data={data} />
        </GrayBackground>
      </SectionContainer>
      <SectionContainer>
        <CenterColumn>
          <IndexHeader
            page={'ServicesPage'}
            locale={data._locale}
            slug_prefix={data._slug_prefix}
            idPrefix={'services'}
            position={'bottom'}
            isHidden={true}
            contactPrefix={'services'} />
          <div id={`${CONTACT_ID}`} />
          <Form
            _for={'index'}
            slug_prefix={data._slug_prefix}
            locale={data._locale}
            idPrefix={'services'} />
        </CenterColumn>
      </SectionContainer>
    </Layout>
  )
}

ServicesPage.propTypes = {
  data: PropTypes.shape().isRequired,
}

export const query = graphql`
  fragment ServicesPage on ServicesJson {
    _locale
    _name
    _slug_prefix
    _meta {
      lang
      siteTitle
      pageTitle
      pageDescription
      other_lang {
        code
        url
        _enabled
      }
    }
    title
    hero_background {
      childImageSharp {
        fluid(quality: 80) {
          src
        }
      }
    }
    service_offers {
      cta_button
      entries {
        title
        text
        tags
      }
    }
    values {
      title
      entries {
        title
        text
      }
    }
    project {
      title
      description
    }
    customers {
      texts
      colored_text
    }
    reviews {
      cta_button
      entries {
        title
        description
        link
        alt_text
        logo {
          publicURL
        }
      }
    }
  }
`
