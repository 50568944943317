import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import { konnectSemiBold } from 'common-styles'
import { CenterColumn, sidePadding } from 'components/base/CenterColumn'
import { ExternalButtonLinkLarge } from 'components/ButtonLink'
import Layout from 'components/Layout'
import { Paragraph } from 'components/Paragraph'
import { Tag } from 'components/Tag'
import { media } from 'media'
import { Header, height as headerHeight } from 'components/AlternativeHeader'

const CONTENT_WIDTH_DESKTOP = 25
const CONTENT_MIN_WIDTH = '344px'
const CONTENT_MAX_WIDTH = '670px'
const CONTENT_Z_INDEX_DESKTOP = 10
const CUTOFF_WIDTH = '1435px'

const Container = styled(CenterColumn)`
  display: flex;
  background: ${({ theme }) => theme.colors.black};
  max-width: none;
  height: auto;
  flex-direction: column-reverse;
  width: 100%;
  padding: ${headerHeight}px 0 60px;
  z-index: 1;

  ${media.desktop`
    padding: 0;
    height: 100vh;
    width: calc(100% - ${sidePadding}px);
    margin-left: ${sidePadding}px;
    flex-direction: row;
  `}
`

const LeftGradient = styled.div`
  ${media.desktopOnly};
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  background: ${({ theme }) => `transparent linear-gradient(0deg, ${theme.colors.blackGradient} 0%, ${theme.colors.black} 100%) 0% 0% no-repeat padding-box`};
  height: ${headerHeight + 50}px;
`

const Title = styled.h1`
  font-size: 24px;
`

const Logo = styled.img`
  width: auto;
`

const Tags = styled.div`
  margin-top: 18px;

  ${media.desktop`
    margin-top: 15px;
  `}
`

const StyledTag = styled(Tag)`
  margin-top: 12px;

  ${media.desktop`
    margin-top: 15px;
  `}
`

const TEXT_MARGIN_DESKTOP = '50px'
const Text = styled.div`
  margin-top: 30px;

  ${media.desktop`
    margin-top: ${TEXT_MARGIN_DESKTOP};
    margin-right: ${sidePadding}px;
    position: relative;
    box-sizing: border-box;
  `}
`

const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.grey};
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-self: flex-end;
  margin-top: 20px;

  ${media.desktop`
    margin-top: 30px;
  `}
`

const LinksTitle = styled.div`
  font-family: ${konnectSemiBold};
  font-size: 14px;
  margin-bottom: 5px;

  ${media.desktop`
    font-size: 15px;
  `}
`

const Link = styled(ExternalButtonLinkLarge)`
  margin-top: 10px;
  width: fit-content;
  font-size: 14px;

  ${media.desktop`
    font-size: 15px;
  `}
`

const linkIconStyle = css`
  margin-right: 10px;
`
const LinkIcon = styled.img`
  ${linkIconStyle};

  ${media.mobile`
    height: 22px;
    width: auto;
  `}
`

const AppleLinkIcon = styled.img`
  ${linkIconStyle};

  ${media.mobile`
    height: 25px;
    width: auto;
  `}
`

const ImageSection = styled.section`
  z-index: 5;

  ${media.desktop`
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
  `}
`

const Image = styled(Img)`
  ${media.desktop`
    height: 100vh;
    width: ${({ aspectRatio }) => (aspectRatio ? aspectRatio : 1) * 100}vw;
  `}
`

const ContentHolder = styled.div`
  ${media.desktop`
    overflow-y: auto;
    overflow-x: visible;
    width: calc(100%);
    z-index: ${CONTENT_Z_INDEX_DESKTOP};
  `}
`

const ContentSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  z-index: 0;

  ${media.mobile`
    width: calc(100% - 40px);
    margin: 35px auto 0;
  `}

  ${media.desktop`
    padding: 150px 0 0 0;
    min-width: ${CONTENT_MIN_WIDTH};
    max-width: ${CONTENT_MAX_WIDTH};
    width: ${CONTENT_MIN_WIDTH};

    ::-webkit-scrollbar {
      display: none;
    }
  `}

  @media (min-width: ${CUTOFF_WIDTH}) {
    width: ${CONTENT_WIDTH_DESKTOP}%;
  }
`

const ImageHolder = styled.div`
  ${media.desktop`
    position: absolute;
    left: calc(${CONTENT_MIN_WIDTH} + ${TEXT_MARGIN_DESKTOP});
    width: calc(100% - ${CONTENT_MIN_WIDTH});
    z-index: ${CONTENT_Z_INDEX_DESKTOP - 1};
    height: 100vh;
  `}
  
  @media (min-width: ${CUTOFF_WIDTH}) {
    left: calc((100vw*${CONTENT_WIDTH_DESKTOP/100}) + 30px);
    width: calc(100% - (100vw*${CONTENT_WIDTH_DESKTOP/100}));
  }

  @media (min-width: 2280px) {
    left: calc(${CONTENT_MAX_WIDTH} + ${TEXT_MARGIN_DESKTOP});
    width: calc(100% - ${CONTENT_MAX_WIDTH});
  }
`

export const WorkPage = ({ data }) => (
  <Layout
    locale={data._locale}
    slug_prefix={data._slug_prefix}
    idPrefix={'work'}
    header={<Header locale={data._locale} slug_prefix={data._slug_prefix}/>}
    metadata={{
      ...data._meta,
      pageTitle: data.title,
      pageDescription: (data.text && data.text.length > 0) ? data.text.join(' ') : data.title,
    }}
    imageUrl={`https://9y.co${data.metaImage.childImageSharp.fixed.src}`}
    footer={null}
  >
    <Container>
      <ContentHolder>
        <ContentSection>
          <LeftGradient/>
          <Title>
            {data.logo
              ? <Logo src={data.logo.publicURL} alt={`${data.title} Logo`} title={data.title} />
              : data.title
            }
          </Title>
          {data.categories && data.categories.length > 0 && (
            <Tags>
              {data.categories.map((category, index) => (
                <StyledTag key={`${category}-${index}`} text={category}/>
              ))}
            </Tags>
          )}
          {data.text && data.text.length > 0 && (
            <Text>
              {data.text.map((t, index) => (
                <StyledParagraph key={`text-${index}`} dangerouslySetInnerHTML={{ __html: t }} />
              ))}
            </Text>
          )}
          {data.links && (data.links.web || data.links.google || data.links.apple) && (
            <Links>
              <LinksTitle>See it live</LinksTitle>
              {data.links.web && (
                <Link href={data.links.web.url} target="_blank" rel="noopener noreferrer">
                  <LinkIcon src="/images/icons/chrome.svg" alt="Chrome icon"/>
                  {data.links.web.text}
                </Link>
              )}
              {data.links.google && (
                <Link href={data.links.google.url} target="_blank" rel="noopener noreferrer">
                  <LinkIcon src="/images/icons/google.svg" alt="Google play icon"/>
                  {data.links.google.text}
                </Link>
              )}
              {data.links.apple && (
                <Link href={data.links.apple.url} target="_blank" rel="noopener noreferrer">
                  <AppleLinkIcon src="/images/icons/apple.svg" alt="Apple icon"/>
                  {data.links.apple.text}
                </Link>
              )}
            </Links>
          )}
        </ContentSection>
      </ContentHolder>
      <ImageHolder>
        <ImageSection>
          <Image 
            aspectRatio={data.image.childImageSharp.fluid.aspectRatio}
            fluid={data.image.childImageSharp.fluid}
            alt={data.alt_text}
            title={data.title + ' Image'}/>
        </ImageSection>
      </ImageHolder>
    </Container>
  </Layout>
)

export const query = graphql`
  fragment WorkPage on WorkJson {
    _name
    _locale
    _slug_prefix
    _meta {
      lang
      other_lang {
        code
        url
        _enabled
      }
    }
    title
    logo {
      publicURL
    }
    alt_text
    image {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    metaImage: image {
      childImageSharp {
        fixed(width: 1024, height: 512) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    categories
    text
    links {
      web {
        url
        text
      }
      google {
        url
        text
      }
      apple {
        url
        text
      }
    }
  }
`

WorkPage.propTypes = {
  data: PropTypes.shape().isRequired,
}
