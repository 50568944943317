import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { CenterColumn } from 'components/base/CenterColumn'
import { GradientBackground } from 'components/base/GradientBackground'
import Layout from 'components/Layout'
import { Paragraph } from 'components/Paragraph'
import { media } from 'media'

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 775px;
  margin: 0 auto;
  padding: 10px 0 60px;

  ${media.desktop`
    padding-bottom: 90px;
  `}
`

const Title = styled.h1`
  width: 100%;
  text-align: center;
  margin-bottom: 30px;

  ${media.desktop`
    margin-bottom: 50px;
  `}
`

const Subtitle = styled(Paragraph)`
  margin: 0;
`

const Section = styled.section`
  margin-top: 50px;

  ${media.desktop`
    margin-top: 60px;
  `}
`

const SectionTitle = styled.h3`
  margin-bottom: 15px;

  ${media.desktop`
    margin-bottom: 20px;
  `}
`

const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.grey};

  :last-child {
    margin-bottom: 0%;
  }

  a {
    color: ${({ theme }) => theme.colors.grey};
  }
`

export const PrivacyPolicyPage = ({ data }) => {
  return (
    <Layout
      locale={data._locale}
      slug_prefix={data._slug_prefix}
      idPrefix={'privacy'}
      metadata={data._meta}
    >
      <CenterColumn>
        <GradientBackground>
          <Container>
            <Title>{data.title}</Title>
            <Subtitle large>{data.subtitle}</Subtitle>
            {data.content.map((c, i) => (
              <Section key={`part-${i}`}>
                <SectionTitle>{c.title}</SectionTitle>
                {c.text.map((t, j) => (
                  <StyledParagraph
                    key={`part-${i}-paragraph-${j}`}
                    dangerouslySetInnerHTML={{ __html: t }}
                  />
                ))}
              </Section>
            ))}
          </Container>
        </GradientBackground>
      </CenterColumn>
    </Layout>
  )
}

PrivacyPolicyPage.propTypes = {
  data: PropTypes.shape().isRequired,
}

export const query = graphql`
  fragment PrivacyPage on PrivacyJson {
    _locale
    _name
    _slug_prefix
    _meta {
      lang
      pageTitle
      pageDescription
      other_lang {
        code
        url
        _enabled
      }
    }
    title
    subtitle
    content {
      title
      text
    }
  }
`
