import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { media } from 'media'
import Icon from './Icon'

const Svg = styled(Icon)`
  width: 15px;
  height: auto;

  ${media.desktop`
    width: 22.415px;
    height: 13.414px;
  `}
  color: ${({ theme }) => theme.colors.white};
`

export const Check = ({ className }) => (
  <Svg viewBox="0 0 22.415 15" fill="none" className={className}>
    <path fill="none" stroke="#fff" strokeWidth="2px" d="M-7639.553,11569.088l5.161,5.162,12.839-12.84" transform="translate(7640.26 -11560.703)"/>
  </Svg>
)

Check.propTypes = {
  className: PropTypes.string,
}

export default Check
