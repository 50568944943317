import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Location } from '@reach/router'
import React, { useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import _get from 'lodash.get'
import PropTypes from 'prop-types'

import { einaLight } from 'common-styles'
import { CenterColumn } from 'components/base/CenterColumn'
import { GradientBackground } from 'components/base/GradientBackground'
import { Form } from 'components/Form'
import { Calendar } from 'components/icons/Calendar'
import { Time } from 'components/icons/Time'
import Layout from 'components/Layout'
import { regularStyle, largeStyle } from 'components/Paragraph'
import { CONTACT_ID } from 'constant'
import { media, generateFontSizeCss } from 'media'
import { Header, height as mobileHeaderHeight } from 'components/AlternativeHeader'
import { appendBlueCharacter } from 'utils'

const CoverWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: ${mobileHeaderHeight}px;

  ${media.desktop`
    margin-top: ${mobileHeaderHeight + 30}px;
  `}
`

const CoverContainer = styled(CenterColumn)`
  position: relative;
  z-index: 1;
`

const Cover = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;

  ${media.desktop`
    width: calc(100% - 120px);
    padding-top: 134px;
    margin: 0 auto;
  `}
`

const CoverInfo = styled.div`
  display: flex;
  flex-direction: column;

  ${media.min1300`
    padding-left: 165px;
  `}
`

const Title = styled.h1`
  margin-bottom: 40px;
  line-height: 1.5;

  ${media.desktop`
    width: 725px;
    max-width: 70%;
    margin-bottom: 30px;
  `}
`

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 60px;

  ${media.desktop`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 70px;
  `}
`

const Info = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 20px;
  }

  ${media.desktop`
    :not(:last-child) {
      margin-right: 40px;
      margin-bottom: 0;
    }
  `}
`

const InfoText = styled.div`
  margin-left: 10px;
`

const InfoIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.graySecondary};
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
`

const StyledCalendar = styled(Calendar)`
  color: ${({ theme }) => theme.colors.white};
`

const StyledTime = styled(Time)`
  color: ${({ theme }) => theme.colors.white};
  width: 36px;
  height: 36px;
`

const InfoTextTitle = styled.h4`
  margin-bottom: 5px;
`

const InfoTextText = styled.div``

const CoverImage = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`

const [spacerIndent, spacerIndentMobile] = [300, 100]
const [spacerHeight, spacerHeightMobile] = [100, 60]
const WhiteSpace = styled.div`
  position: absolute;
  left: 0;
  bottom: -${spacerHeightMobile}px;
  width: 100%;
  height: ${spacerHeightMobile + spacerIndentMobile}px;
  background: ${({ theme }) => theme.colors.white};
  z-index: 0;

  ${media.desktop`
    bottom: -${spacerHeight}px;
    height: ${spacerHeight + spacerIndent}px;
  `}
`

const ContentContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  padding: ${spacerHeightMobile}px 0;

  ${media.desktop`
    padding: ${spacerHeight}px 0;
  `}
`

const elementStyle = css`
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 775px;
  text-align: left;
`

const addIntroductionStyling = (blogReference) => {
  const childrenElements = blogReference.current.children
  let i

  if(childrenElements == undefined){
    return
  }

  for (i = 0; childrenElements.length > i; i++) {
    if (childrenElements[i].tagName !== 'P') break
    childrenElements[i].className = 'introduction'
  }
 
  if(i != 0){
    childrenElements[i - 1].className = 'introduction last'
  }
}

const Content = styled(CenterColumn)`
  color: ${({ theme }) => theme.colors.black};
  max-width: 1042px;

  ${media.desktop`
    width: calc(100% - 220px);
    * { line-height: 34px; }
    a { text-decoration: underline 2px ${({ theme }) => theme.colors.blue}; }

    .introduction { line-height: 42px; }
    .introduction.last {
      margin-bottom: 100px;
    }
    
    
  `}

  ${media.mobile`
    * { line-height: 31px; }
    a { 
      color: ${({ theme }) => theme.colors.blue}; 
      line-height: 25px;
    }

    .introduction { line-height: 31px; }
    .introduction.last {
      margin-bottom: 80px;
    }
  `}

  .introduction {
    font-family: ${einaLight};
    ${generateFontSizeCss(18, 26)}
  }

  strong,
  b {
    font-weight: bold;
  }

  p, li, ul {
    font-family: ${einaLight};

    ${media.mobile`
      ${largeStyle};
      font-size: 16px;
      line-height: 25px;
    `}

    ${media.desktop`
      font-size: 20px;
      line-height: 34px;
    `}
  }
  
  ul {
    display: block;
    list-style-type: disc;
    padding-inline-start: 2rem;
    max-width: calc(100% - 2.5rem);
    margin-bottom: 2rem;

    ${media.desktop`
      padding-inline-start: 4rem;
      max-width: calc(100% - 4.5rem);
    `}
  }

  li {
    display: list-item;
    width: 100%;

    &::marker {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  h1 {
    ${elementStyle};
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 20px;

    ${media.mobile`
      font-size: 19px;
      line-height: 29px;
      margin-top: 40px;
    `}

    ${media.desktop`
      font-size: 26px;
      line-height: 34px;
      margin-top: 70px;
    `}
  }

  h3 {
    margin-bottom: 10px;
    
    ${media.mobile`
      line-height: 25px;
    `}
  }

  h4 {
    ${elementStyle};
    margin-bottom: 5px;
  }

  h5 {
    ${elementStyle};
    margin-bottom: 5px;
  }

  img, .gatsby-resp-image-wrapper {
    pointer-events: none;
  }

  em {
    font-style: italic;
    line-height: inherit;
  }
`

export const BlogPostPage = ({
  html,
  siteDescription,
  data,
}) => {
  let blogContent = useRef(null)
  let aboutTitle = _get(data, 'title', '')
  let coverImageAltText = _get(data, 'coverImageAltText', 'Blog Post Cover')
  if (aboutTitle.slice(-1) === '.') aboutTitle = aboutTitle.slice(0, aboutTitle.length - 1)

  useEffect(() => {
    if (blogContent) addIntroductionStyling(blogContent)
  })

  return (
    <Location>
      {({ location }) => {
        return (
          <Layout
            slug_prefix={data._slug_prefix}
            header={<Header locale={data._locale} slug_prefix={data._slug_prefix} filledCtaButton={false} stickyOnDesktop />}
            idPrefix={'blog-post'}
            imageUrl={`${location.origin}${data.metaImage.childImageSharp.fixed.src}`}
            metadata={{
              ...data._meta,
              siteDescription: siteDescription,
            }}
            contactPrefix={data._slug}
          >
            <CoverWrapper>
              <CoverContainer>
                <GradientBackground>
                  <Cover>
                    <CoverInfo>
                      <Title>{appendBlueCharacter(aboutTitle)}</Title>
                      <Infos>
                        <Info>
                          <InfoText style={{ 'marginLeft': '0' }}>
                            <InfoTextTitle>Author</InfoTextTitle>
                            <InfoTextText>{data.author}</InfoTextText>
                          </InfoText>
                        </Info>
                        <Info>
                          <InfoIcon>
                            <StyledCalendar />
                          </InfoIcon>
                          <InfoText>
                            <InfoTextTitle>Date</InfoTextTitle>
                            <InfoTextText>{data.publishedOn}</InfoTextText>
                          </InfoText>
                        </Info>
                        <Info>
                          <InfoIcon>
                            <StyledTime />
                          </InfoIcon>
                          <InfoText>
                            <InfoTextTitle>Time</InfoTextTitle>
                            <InfoTextText>{data.readTime} read</InfoTextText>
                          </InfoText>
                        </Info>
                      </Infos>
                    </CoverInfo>
                    <CoverImage>
                      <Img fluid={data.coverImage.childImageSharp.fluid} alt={coverImageAltText} />
                    </CoverImage>
                  </Cover>
                </GradientBackground>
              </CoverContainer>
              <WhiteSpace />
            </CoverWrapper>
            <ContentContainer>
              <Content ref={blogContent} dangerouslySetInnerHTML={{ __html: html }} />
            </ContentContainer>
            <div id={`${CONTACT_ID}`} />
            <Form
              for={'blog'}
              idPrefix={'blog-post'} />
          </Layout>
        )
      }}
    </Location>
  )
}

export const query = graphql`
  fragment BlogPost on MarkdownRemarkFrontmatter {
    title
    author
    _slug
    _locale
    _slug_prefix
    _name
    _meta {
      lang
      pageTitle
      pageDescription
      keywords
      other_lang {
        code
        url
        _enabled
      }
    }
    readTime
    publishedOn(formatString: "MMMM DD, YYYY")
    coverImage {
      childImageSharp {
        fluid(maxWidth: 2500, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    coverImageAltText
    metaImage: coverImage {
      childImageSharp {
        fixed(width: 1024, height: 512) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  fragment BlogPostPage on MarkdownRemark {
    html
    rawMarkdownBody
    data: frontmatter {
      ...BlogPost
    }
  }
`

BlogPostPage.propTypes = {
  data: PropTypes.shape().isRequired,
  html: PropTypes.string,
  siteDescription: PropTypes.string,
}
