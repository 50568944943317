import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { TeamPage } from 'content/team-page/TeamPage'

const Team = ({ data }) => {
  return <TeamPage data={data.teamJson}/>
}

Team.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default Team

export const query = graphql`  
  query($slug: String!) {
    teamJson(fields: { slug: { eq: $slug } }) {
      ...TeamPage
    }
  }
`
