import styled, { css } from 'styled-components'

import { einaRegular, einaLight } from 'common-styles'
import { generateFontSizeCss } from 'media'

const commonStyle = css`
  line-height: 1.5;
`
export const largeStyle = css`
  ${commonStyle};
  font-family: ${einaLight};
  ${generateFontSizeCss(18, 26)}
`

export const regularStyle = css`
  ${commonStyle};
  font-family: ${einaRegular};
  ${generateFontSizeCss(14, 16)}
`

export const Paragraph = styled.p`
  ${({ large }) => large ? largeStyle : regularStyle};
`
