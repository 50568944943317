import styled from 'styled-components'

import { MAX_PAGE_WIDTH, media } from 'media'

export const sidePadding = 50
export const sidePaddingMobile = 30

export const CenterColumn = styled.div`
  width: calc(100vw - ${2 * sidePaddingMobile}px);
  max-width: ${MAX_PAGE_WIDTH}px;
  margin: 0 auto;

  ${media.desktop`
    width: calc(100vw - ${2 * sidePadding}px);
  `}
`
