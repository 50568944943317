import styled from 'styled-components'

import { media, MAX_PAGE_WIDTH } from 'media'
import { sidePadding, sidePaddingMobile } from 'components/base/CenterColumn'

export const BOTTOM_OFFSET_LARGE = 170
export const BOTTOM_OFFSET_MOBILE = 150

export const WhiteBackground = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  z-index: 0;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden;
  ${media.desktop`
    padding-top: 100px;
    padding-bottom: 100px;
  `}
`

export const CommonContainer = styled.div`
  position: relative;
  width: calc(100% - ${2*sidePaddingMobile}px);
  left: ${sidePaddingMobile}px;
  ${media.desktop`
    width: calc(100% - ${2*sidePadding}px);
    max-width: calc(${MAX_PAGE_WIDTH}px - ${2*sidePadding}px);
    margin: 0 auto;
    left: 0;
  `}
`

export const GrayBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.grayTertiary };
  color: ${({ theme }) => theme.colors.black };
  padding-top: 60px;
  padding-bottom: 60px;
  ${media.desktop`
    padding-top: 90px;
    padding-bottom: 90px;
  `}
`