import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { CenterColumn } from 'components/base/CenterColumn'
import { media } from 'media'
import { WhiteBackground } from 'components/home/common'
import { JobInfo } from 'components/jobs/overview/JobInfo'

const Background = styled(WhiteBackground)`
  padding: 60px 0;

  ${media.desktop`
    padding: 90px 0;
  `}
`

const Container = styled(CenterColumn)`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black};
`

const ContentContainer = styled.div`
  width: 100%;

  p:last-child {
    margin: 0;
  }
`

export const JobsOverview = ({
  jobPosts,
}) => (
  <Background>
    <Container>
      <ContentContainer>

        {jobPosts.map((jobPost, index) =>
          <JobInfo 
            key={index} 
            data={jobPost.node}
            locale={jobPost.node._locale}
            lastJob={index === jobPosts.length - 1} 
          />
        )}

      </ContentContainer>
    </Container>
  </Background>
)

JobsOverview.propTypes = {
  data: PropTypes.shape().isRequired,
  jobPosts: PropTypes.array,
}
