import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {
  heightMobile,
  height,
} from 'components/Header'
import logoSrc from 'resources/images/9y-logo.png'
import invertedLogoSrc from 'resources/images/9y-logo-inverted.png'
import { CenterColumn as BaseCenterColumn } from 'components/base/CenterColumn'
import { BOTTOM_OFFSET_LARGE, BOTTOM_OFFSET_MOBILE } from 'components/home/common'
import { GeneralContext } from '../../context/general_context'

import { media } from 'media'
import { Link } from 'gatsby'
import Nav from 'components/Nav'
import { StickySlogan } from 'components/home/slogan'

// Subtract 1px from 'bottom' and 'top' to hide narrow gap visible in mobile navigation
const HeaderClip = styled.div.attrs(({ isHidden = false }) => ({
  'aria-hidden': isHidden ? 'true' : 'false',
}))`
  position: absolute;
  z-index: 5;
  top: ${({ position }) => position === 'bottom' ? '0' : `-${BOTTOM_OFFSET_MOBILE}px`};
  ${({ page }) => page != 'indexPage' ? 'top: -1px;' : ''}
  left: -1000px;
  right: -1000px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  bottom: ${({ position }) => position === 'middle' ? `-${BOTTOM_OFFSET_MOBILE}px` : `calc(${BOTTOM_OFFSET_MOBILE}px - 1px)`};
  ${({ page }) => page != 'indexPage' ? 'bottom: -1px !important;' : ''}
  pointer-events: none;

  ${media.desktop`
    top: 0;
    bottom: ${({ position }) => position === 'middle' ? `-${BOTTOM_OFFSET_LARGE}px` : `-${height}px`};
    ${({ page, position }) => page != 'indexPage' && position === 'bottom' ? `bottom: -${BOTTOM_OFFSET_LARGE}px !important` : ''}
  `}

  ${media.msEdge`
    clip-path: unset;
    ${({ position }) => position === 'top' ? '' : `
      display: none;
    `}
  `}
  
  ${media.firefox`
    clip-path: unset;
    ${({ position }) => position === 'top' ? '' : `
      display: none;
    `}
  `}
`

const StyledHeader = styled.div`
  height: ${heightMobile}px;
  background: ${({ isLightTheme, theme }) => isLightTheme ? theme.whiteGradientToBottom : theme.blackGradientToBottom};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  pointer-events: auto;
  ${media.desktop`
    height: ${height}px;
  `}
`

export const Container = styled.header`
  height: ${heightMobile}px;
  position: relative;
  ${media.desktop`
    height: ${height}px;
  `}
`

export const FixedContainer = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: ${heightMobile}px;
  ${media.desktop`
    height: ${height}px;
  `}
`

const CenterColumn = styled(BaseCenterColumn)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Logo = styled.img`
  height: 25px;
  width: auto;
  position: relative;
  z-index: 1;
  ${media.desktop`
    height: 30px;
  `}
`

export const IndexHeader = ({ 
  position, 
  isHidden = false, 
  isLightTheme = false, 
  idPrefix, 
  slug_prefix = '', 
  locale = 'en', 
  page = 'indexPage',
  contactPrefix = '',
}) => {
  const { mobileMenuOpen, setMobileMenuOpen } = React.useContext(GeneralContext)

  return (
    <HeaderClip page={page} position={position} isHidden={isHidden}>
      <StyledHeader isLightTheme={isLightTheme}>
        <Container>
          <FixedContainer>
            <CenterColumn>
              <Link to={`/${slug_prefix}`} onClick={() => setMobileMenuOpen(false)}>
                <Logo src={isLightTheme || mobileMenuOpen ? invertedLogoSrc : logoSrc} title={'9y'} alt={'9y Company Logo'} />
              </Link>
              <Nav locale={locale} slug_prefix={slug_prefix} idPrefix={idPrefix} isLightTheme={isLightTheme} contactPrefix={contactPrefix} />
            </CenterColumn>
          </FixedContainer>
        </Container>
        <StickySlogan isLightTheme={isLightTheme} />
      </StyledHeader>
    </HeaderClip>
  )
}

IndexHeader.propTypes = {
  isHidden: PropTypes.bool,
  isLightTheme: PropTypes.bool,
  position: PropTypes.string.isRequired,
  idPrefix: PropTypes.string,
  slug_prefix: PropTypes.string,
  locale: PropTypes.string,
  page: PropTypes.string,
  contactPrefix: PropTypes.string,
}

export default IndexHeader
