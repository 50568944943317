import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { media } from 'media'
import Icon from './Icon'

const Svg = styled(Icon)`
  width: 15px;
  height: auto;

  ${media.desktop`
    width: 22.415px;
    height: 13.414px;
  `}
  color: ${({ theme }) => theme.colors.white};
`

export const ArrowRight = ({ className }) => (
  <Svg viewBox="0 0 22.415 13.414" fill="none" className={className}>
    <path fill="currentColor" d="M613.708,1198.291l-1.414,1.414,4.295,4.295H598v2h18.585l-4.291,4.291,1.414,1.414,6.707-6.707Z" transform="translate(-598 -1198.291)"/>
  </Svg>
)

ArrowRight.propTypes = {
  className: PropTypes.string,
}

export default ArrowRight
