import styled from 'styled-components'

import { media, generateFontSizeCss } from 'media'

export const HeroTitle = styled.h1`
  text-align: left;
  ${generateFontSizeCss(40, 105)}
  line-height: 40px;
  margin-bottom: 30px;

  ${media.desktop`
    line-height: 128px;
    margin-left: 50px;
    line-height: min(122%, 128px);
    margin-bottom: 150px;
    margin-bottom: min(11.55vh, 150px);
    width: 50%;
  `}
`
