import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { einaRegular, einaLight } from 'common-styles'
import Icon from './Icon'

const Svg = styled(Icon)`
  width: 100%;
  height: auto;

  .a {
    fill: ${({ theme }) => theme.colors.blue};
  }
  
  .b {
    fill: ${({ theme }) => theme.colors.grayTertiary};
  }
  
  .c,
  .e {
    fill: ${({ theme }) => theme.colors.black};
  }
  
  .c,
  .f {
    font-size: 49px;
    font-family: ${einaLight};
    font-weight: 300;
  }
  
  .d,
  .e,
  .g {
    font-size: 22px;
    font-family: ${einaRegular};
  }
  
  .d {
    font-weight: 400;
  }
  
  .f,
  .g {
    fill: ${({ theme }) => theme.colors.white};
  }
`

export const PieChart = ({ className, isGerman = false }) => (
  <Svg className={className} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="640" height="640" viewBox="0 0 640 640">
    <g transform="translate(-847.999 -617)">
      <path className="a" d="M320,640a322.364,322.364,0,0,1-64.491-6.5A319.9,319.9,0,0,1,6.5,384.491a323.121,323.121,0,0,1,0-128.982A319.893,319.893,0,0,1,255.508,6.5,322.361,322.361,0,0,1,320,0V319.778L599.83,164.652A318.593,318.593,0,0,1,640,320a322.365,322.365,0,0,1-6.5,64.491A319.891,319.891,0,0,1,384.491,633.5,322.365,322.365,0,0,1,320,640Z" transform="translate(848 617)"/>
      <path className="b" d="M0,0C120.359,0,225.194,66.439,279.83,164.652L0,319.778Z" transform="translate(1167.999 617)"/>
      <g transform="translate(1213.188 700.92)">
        <text className="c" transform="translate(30.813 54.08)">
          <tspan x="0" y="0">17</tspan>
          <tspan className="d" y="0">%</tspan>
        </text>
        <text className="e" transform="translate(-0.188 97.08)">
          <tspan x="0" y="0">{isGerman ? 'Neugeschäft' : 'New Business'}</tspan>
        </text>
      </g>
      <g transform="translate(1085.925 1027.375)">
        <text className="f" transform="translate(49.075 53.625)">
          <tspan x="0" y="0">83</tspan>
          <tspan className="d" y="0">%</tspan>
        </text>
        <text className="g" transform="translate(0.075 96.625)">
          <tspan x="0" y="0">{isGerman ? 'Folgegeschäft' : 'Repeat Business'}</tspan>
        </text>
      </g>
    </g>
  </Svg>
)

PieChart.propTypes = {
  className: PropTypes.string,
  isGerman: PropTypes.bool.isRequired,
}

export default PieChart
