import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { media } from 'media'
import Icon from './Icon'

const Svg = styled(Icon)`
  width: 22px;
  height: auto;

  ${media.desktop`
    width: 22px;
    height: 21px;
  `}
  color: ${({ theme }) => theme.colors.white};
`

export const Time = ({ className }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" className={className}>
    <g transform="translate(-15991 14478)">
      <circle fill="#393939" cx="18" cy="18" r="18" transform="translate(15991 -14478)"/>
      <g transform="translate(15501 -14800)">
        <g fill="none" stroke="#fff" strokeWidth="2px" transform="translate(497 329)">
          <circle stroke="none" cx="11" cy="11" r="11"/>
          <circle fill="none" cx="11" cy="11" r="10"/>
        </g>
        <path fill="none" stroke="#fff" strokeWidth="2px" d="M8234-14491v6.605l3.111,3.111" transform="translate(-7726 14824.4)"/>
      </g>
    </g>
  </Svg>
)

Time.propTypes = {
  className: PropTypes.string,
}

export default Time
