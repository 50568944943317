import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media } from 'media'
import { afterDot } from 'custom-css'
import { einaRegular, konnectSemiBold } from 'common-styles'
import { WhiteBackground, CommonContainer } from 'components/home/common'

const Background = styled(WhiteBackground)`
  ${media.desktop`
    padding: 120px 0;
  `}
`

const Container = styled(CommonContainer)`
  display: flex;
  flex-direction: column;
  ${media.desktop`
    flex-direction: row;
    max-width: 1300px;
  `}
`

const TalkContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${media.desktop`
    width: 40%;
    padding-right: 60px;
  `}
`

const TalkOverviewText = styled.div`
  font-family: ${konnectSemiBold};
  text-align: left;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: ${({ titleColored }) => titleColored ? '0' : '20px'};
  color: ${({ theme }) => theme.colors.black};
  ${({ titleColored }) => titleColored ? '' : afterDot(6)}
  ${media.desktop`
    ${({ titleColored }) => titleColored ? '' : afterDot(8)}
    font-size: 32px;
    line-height: 46px;
  `}
`

const TalkOverviewTextColored = styled.div`
  font-family: ${konnectSemiBold};
  text-align: left;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.blue};
  ${media.desktop`
    font-size: 32px;
    line-height: 46px;
  `}
`

const TalkDescriptionContainer = styled.div`
  p:last-child {
    margin-bottom: 0;
  }

  ${media.desktop`
    width: 60%;
  `}
`

const TalkDescriptionText = styled.p`
  font-family: ${einaRegular};
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.graySecondary};
  ${media.desktop`
    font-size: 16px;
    line-height: 28px;
  `}
`

export const Talk = ({ title = '', titleColored = '', description = [], isFirst = false, className = '' }) => {
  if (title.endsWith('.')) {
    title = title.substring(0, title.length - 1)
  }

  return (
    <Background className={className}>
      <Container isFirst={isFirst}>
        <TalkContainer>
          <TalkOverviewText titleColored={titleColored}>{title}</TalkOverviewText>
          {titleColored && 
            <TalkOverviewTextColored>{titleColored}</TalkOverviewTextColored>
          }
        </TalkContainer>
        <TalkDescriptionContainer>
          {description.map((desc, i) => (
            <TalkDescriptionText key={`talk_desc_${i}`} dangerouslySetInnerHTML={{ __html: desc }} />
          ))}
        </TalkDescriptionContainer>
      </Container>
    </Background>
  )
}

Talk.propTypes = {
  isFirst: PropTypes.boolean,
  title: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  titleColored: PropTypes.string,
}
