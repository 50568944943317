import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import _get from 'lodash.get'
import { Link } from 'gatsby'

import { media } from 'media'
import { einaRegular } from 'common-styles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop`
    margin-top: 45px;
  `}
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 17px;
`

const ImageContainer = styled(Link)`
  display: block;
  position: relative;
`

const SubTextContainer = styled.div`
  margin-top: auto;
`

const SubText = styled.div`
  font-family: ${einaRegular};
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  ${media.desktop`
    font-size: 16px;
    line-height: 28px;
  `}
`

const OverlayHolder = styled(Link)`
  display: none;
  ${media.desktop`
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    :hover {
      cursor: pointer;
      >:nth-child(1) {
        opacity: 0.5;
      }
      >:nth-child(2) {
        opacity: 1;
      }
    }
  `}
`

const ImageContainerOverlay = styled.div`
  display: block;
  transition: .5s ease;
  opacity: 0;
  background: ${({ theme }) => theme.colors.black};
  text-align: center;
  width: 100%;
  height: 100%;
`

const PLUS_SIGN_CONTAINER_DIM = 50
const ImageContainerOverlayPlus = styled.div`
  opacity: 0;
  position: absolute;
  background: ${({ theme }) => theme.colors.blue};
  height: ${PLUS_SIGN_CONTAINER_DIM}px;
  width: ${PLUS_SIGN_CONTAINER_DIM}px;
  margin: 0 auto;
  top: calc(50% - ${PLUS_SIGN_CONTAINER_DIM/2}px);
  border-radius: ${PLUS_SIGN_CONTAINER_DIM/2}px;
  left: calc(50% - ${PLUS_SIGN_CONTAINER_DIM/2}px);
  :after, :before {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    background: ${({ theme }) => theme.colors.white};
  }
  :before {
    width: 2px;
    margin: 2px auto;
  }
  :after{
    margin: auto 2px;
    height: 2px;
  }
`

const CategoriesContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  overflow: hidden;
  >div {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  >:last-child {
    margin-bottom: auto;
  }

  ${media.desktop`
    >div {
      margin-bottom: 15px;
      margin-right: 30px;
    }

    >:last-child {
      margin-bottom: auto;
    }
  `}
`

const CATEGORY_HEIGHT = 26
const Category = styled.div`
  opacity: 1;
  font-size: 14px;
  line-height: ${CATEGORY_HEIGHT}px;
  color: ${({ theme, isLast }) => isLast ? theme.colors.black : theme.colors.white};
  padding: 0;
  white-space: nowrap;
  font-family: ${einaRegular};
  ${media.desktop`
    font-size: 16px;
  `}
`

export const WorkGalleryEntry = ({ index, entry, isLast }) => {
  const title = _get(entry, 'title', '')
  const categories = _get(entry, 'categories', [])
  const logo = _get(entry, 'logo.publicURL', null)
  const fluidImage = _get(entry, 'image.childImageSharp.fluid', null)
  const slug = _get(entry, 'slug', null)
  const subText = _get(entry, 'sub_text', null)
  const altText = _get(entry, 'alt_text', '')

  return (
    <Container>
      {logo &&
      <Title>
        <img alt={title + ' Icon'} title={title} src={logo}/>
        {subText &&
        <SubTextContainer>
          {subText.map((text, i) => {
            return <SubText key={`work_${index}_sub_text_${i}`}>{text}</SubText>
          })}
        </SubTextContainer>
        }
      </Title>
      }
      {fluidImage &&
      <ImageContainer to={slug}>
        <Img fluid={fluidImage} alt={altText} title={`Work Image ${index + 1}`} />
        {slug &&
          <OverlayHolder to={slug}>
            <ImageContainerOverlay />
            <ImageContainerOverlayPlus />
          </OverlayHolder>
        }
      </ImageContainer>
      }
      {categories &&
      <CategoriesContainer>
        {categories.map((category, i) => (
          <Category isLast={isLast} key={`work_entry_${index}_category_${i}`}>
            {category}
          </Category>
        ))}
      </CategoriesContainer>
      }
    </Container>

  )
}

WorkGalleryEntry.propTypes = {
  isLast: PropTypes.bool,
  index: PropTypes.number.isRequired,
  entry: PropTypes.shape({
    title: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}
