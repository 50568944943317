import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _get from 'lodash.get'
import { media } from 'media'
import { afterDot } from 'custom-css'
import { einaRegular, konnectSemiBold } from 'common-styles'

export const BASE_HEIGHT = 72
export const IMAGE_MARGIN = 20

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const Image = styled.img`
  margin-right: ${IMAGE_MARGIN}px;
  width: ${BASE_HEIGHT}px;
  height: ${BASE_HEIGHT}px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Name = styled.div`
  font-family: ${konnectSemiBold};
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 10px;
  ${afterDot(4)}

  ${media.desktop`
    ${afterDot(6)}
    font-size: 32px;
    line-height: 42px;
  `}
`

const Text = styled.div`
  font-family: ${einaRegular};
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.graySecondary};
  ${media.desktop`
    font-size: 16px;
    line-height: 27px;
  `}
`

export const Service = ({ entry }) => {
  const name = _get(entry, 'name', '')
  const text = _get(entry, 'text', '')
  const image = _get(entry, 'image.publicURL', '')

  return (
    <Container>
      {image && <Image src={image} alt={name + ' Icon'} title={name} />}
      <TextContainer>
        {name && <Name>{name}</Name>}
        {text && <Text>{text}</Text>}
      </TextContainer>
    </Container>
  )
}

Service.propTypes = {
  entry: PropTypes.shape({
    image: PropTypes.object,
    name: PropTypes.string,
    text: PropTypes.string,
  }),
}
