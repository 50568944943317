import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { einaRegular } from 'common-styles'

const Container = styled.div`
  font-family: ${einaRegular};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  background: rgba(255, 255, 255, 0.25);
  border-radius: 200px;
  padding: 10px 15px;
  width: fit-content;
`

export const Tag = ({ className, text }) => <Container className={className}>{text}</Container>

Tag.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
}
