import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media, generateFontSizeCss } from 'media'
import { ButtonLinkWithIcon } from 'components/ButtonLink'
import { ArrowRight } from 'components/icons/ArrowRight'
import hexToRgba from 'hex-to-rgba'

const Wrapper = styled.div`
  p {
    margin-bottom: 0;
  }

  ${({ theme, lastJob }) =>
    !lastJob && `border-bottom: 0.5px solid ${hexToRgba(theme.colors.grey, 0.3)};`
  }

  ${media.mobile`
    padding: 20px 15px;
  `}

  ${media.desktop`
    padding: 50px 15px;
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.grayTertiary};
    }

    &:hover {
      .text-info {
        transform: translateX(20px);
        transition: .15s cubic-bezier(.215, .61, .355, 1);
      }
    }

    .text-info {
      margin-right: 20px;
      transition: .15s cubic-bezier(.215, .61, .355, 1);
    }
  `}
`

const InnerWrapper = styled.div`
  max-width: 1310px;
  display: flex;
  margin: 0 auto;

  ${media.mobile`
    flex-direction: column;
    align-items: flex-start;
  `}

  ${media.desktop`
    flex-direction: row;
    align-items: center;
  `}
`

const Title = styled.h2`
  ${generateFontSizeCss(26, 46)}

  ${media.mobile`
    margin-bottom: 15px;
    line-height: 39px;
  `}

  ${media.desktop`
    margin-bottom: 20px;
    line-height: 58px;
  `}
`

const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.mobile`
    margin-bottom: 20px;
  `}
`

const InfoItem = styled.p`
  margin-right: 20px;

  ${media.mobile`
    margin-bottom: 10px !important;
  `}
`

const InnerContainer = styled.div``

export const JobInfo = ({
  data,
  lastJob,
  locale = 'en',
}) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    () => wrapperRef.current = null
  })

  if (!data) return null

  return (
    <Wrapper
      lastJob={lastJob}
      ref={wrapperRef}
    >
      <InnerWrapper>

        <InnerContainer
          className='text-info'
          style={{ flex: 1 }}
        >
          <Title>{data.title}</Title>

          <InfoContainer>
            <InfoItem>
              {data.timeIcon}
            </InfoItem>
            <InfoItem>
              {data.location}
            </InfoItem>
            <InfoItem>
              {data.team} Team
            </InfoItem>
          </InfoContainer>
        </InnerContainer>

        <InnerContainer>
          <ButtonLinkWithIcon
            isLightTheme
            to={data._slug}
            icon={<ArrowRight />}
            onClick={() => {
              wrapperRef.current.style.backgroundColor = '#f2f2f2'
            }}
          >
            {locale === 'en' ? 'Read more' : 'Mehr erfahren'}
          </ButtonLinkWithIcon>
        </InnerContainer>

      </InnerWrapper>
    </Wrapper>
  )
}

JobInfo.propTypes = {
  data: PropTypes.shape().isRequired,
  lastJob: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
}
