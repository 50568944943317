import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { WhyUsPage } from 'content/why-us-page/WhyUsPage'

const WhyUs = ({ data }) => {
  return <WhyUsPage data={data.whyUsJson}/>
}

WhyUs.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default WhyUs

export const query = graphql`  
  query($slug: String!) {
    whyUsJson(fields: { slug: { eq: $slug } }) {
      ...WhyUsPage
    }
  }
`
