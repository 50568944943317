import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import { Form } from 'components/Form'
import { HeroSection } from 'components/home/hero'
import { WorkGallery } from 'components/home/work'
import { Vision } from 'components/home/vision'
import { Services } from 'components/home/services'
import { Facts } from 'components/home/facts'
import { Quotes } from 'components/Quotes'
import { Customers } from 'components/home/customers'
import Layout from 'components/Layout'
import { CONTACT_ID } from 'constant'
import {
  CenterColumn,
  sidePaddingMobile,
  sidePadding,
} from 'components/base/CenterColumn'
import { media, MAX_PAGE_WIDTH } from 'media'
import { height as headerHeight, heightMobile as mobileHeaderHeight } from 'components/Header'
import { IndexHeader } from 'components/home/Header'
import { BOTTOM_OFFSET_LARGE, BOTTOM_OFFSET_MOBILE } from 'components/home/common'
import PropTypes from 'prop-types'
import { GeneralContext } from '../../context/general_context'

const LayoutHolder = styled.div`
  >:first-child {
    >:last-child {
      top: ${BOTTOM_OFFSET_MOBILE}px;
      ${media.desktop`
        top: 0px;
      `}
    }
  }
`

const FixedHolder = styled.div`
  position: fixed;
`

const FixedBackgroundImage = styled.div`
  background-repeat: no-repeat;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  width: calc(100vw - ${2*sidePaddingMobile}px);
  max-width: ${MAX_PAGE_WIDTH}px;
  z-index: -101;
  height: calc(100vh - ${4*sidePaddingMobile}px);
  position: relative;
  ${media.desktop`
    min-height: 700px;
    width: calc(100vw - ${2*sidePadding}px);
    height: calc(100vh - ${2*headerHeight}px);
  `}
`

const DarkSection = styled(CenterColumn)`
  position: relative;
  ${media.mobile`
    ${({ mobileMenuOpen }) => mobileMenuOpen ? `
      overflow-x: hidden;
      width: calc(100vw);
    ` : ''}
  `}
`

const BottomDarkSection = styled(DarkSection)`
  z-index: 1;
  top: ${BOTTOM_OFFSET_MOBILE}px;
  ${media.desktop`
    top: ${BOTTOM_OFFSET_LARGE + headerHeight}px;
    margin-bottom: ${headerHeight}px;
  `}
  
  ${media.mobile`
    ${({ mobileMenuOpen }) => mobileMenuOpen ? 'display: none;' : ''}
  `}
`

const LightSection = styled.div`
  position: relative;
  ${media.desktop`
    bottom: 0;
    top: ${headerHeight}px;
  `}
`

const SectionContentHolder = styled.div`
  >div {
    z-index: 1;
  }

  >:last-child {
    position: relative;
  }

  position: relative;
  top: ${mobileHeaderHeight}px;
`

const DarkSectionContentHolder = styled(SectionContentHolder)`
  ${media.desktop`
    top: ${headerHeight + BOTTOM_OFFSET_LARGE}px;
  `}
`

const LightSectionContentHolder = styled(SectionContentHolder)`
  >:first-child {
    z-index: 0;
  }

  top: ${BOTTOM_OFFSET_MOBILE}px;
  ${media.desktop`
    top: ${BOTTOM_OFFSET_LARGE}px;
  `}
`

const BottomHolder = styled.div`
  position: relative;
  top: -${2*BOTTOM_OFFSET_MOBILE}px;
  ${media.desktop`
    top: -${BOTTOM_OFFSET_LARGE}px;
  `}
`

const BottomContent = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  height: ${2*BOTTOM_OFFSET_MOBILE}px;
  width: 100vw;
  z-index: 1;
  ${media.mobile`
    top: 140px;
  `}
  ${media.desktop`
    height: ${BOTTOM_OFFSET_LARGE}px;
    left: 0px;
  `}
`
const GrayLine = styled.div`
  ${media.desktopOnly};
  position: absolute;
  border-top: 1px solid ${({ theme }) => theme.colors.graySecondary};
  top: 533px;
  bottom: 0;
  left: -10000px;
  right: -10000px;
  pointer-events: none;
`

const StyledQuotes = styled(Quotes)`
  padding: 10px 0 0 0;

  ${media.desktop`
    padding: 120px 0 0 0;
  `}
`

export const IndexPage = ({ data = {} }) => {
  const { mobileMenuOpen } = React.useContext(GeneralContext)
  const backImageData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "gradient_white.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <LayoutHolder>
      <Layout
        locale={data._locale}
        slug_prefix={data._slug_prefix}
        idPrefix={'home'}
        isHome
        disableHeader
        metadata={data._meta}
      >
        <DarkSection mobileMenuOpen={mobileMenuOpen}>
          <IndexHeader locale={data._locale} slug_prefix={data._slug_prefix} idPrefix={'home'} position={'top'} />
          <DarkSectionContentHolder>
            <FixedHolder>
              <FixedBackgroundImage imageUrl={backImageData.file.childImageSharp.fluid.src} />
            </FixedHolder>
            <HeroSection data={data} />
            <WorkGallery data={data} />
          </DarkSectionContentHolder>
        </DarkSection>
        <LightSection>
          <IndexHeader locale={data._locale} slug_prefix={data._slug_prefix} idPrefix={'home'} position={'middle'} isHidden={true} isLightTheme={true} />
          <LightSectionContentHolder>
            <BottomHolder>
              <BottomContent />
            </BottomHolder>
            <StyledQuotes locale={data._locale} _for={'index'} />
            <Services data={data} slug_prefix={data._slug_prefix} />
            <Facts data={data} />
            <Vision data={data} />
          </LightSectionContentHolder>
        </LightSection>
        <BottomDarkSection mobileMenuOpen={mobileMenuOpen}>
          <GrayLine />
          <IndexHeader locale={data._locale} slug_prefix={data._slug_prefix} idPrefix={'home'} position={'bottom'} isHidden={true} />
          <Customers data={data} />
          <div id={`${CONTACT_ID}`}/>
          <Form
            idPrefix={'home'}
            slug_prefix={data._slug_prefix}
            locale={data._locale}
            _for={'index'} />
        </BottomDarkSection>
      </Layout>
    </LayoutHolder>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape().isRequired,
}

export const query = graphql`
  fragment IndexPage on IndexJson {
    _locale
    _name
    _slug_prefix
    _meta {
      lang
      pageTitle
      siteTitle
      pageDescription
      other_lang {
        code
        url
        _enabled
      }
    }
    hero {
      title
    }
    services {
      title
      cta_button
      entries {
        id
        name
        text
        image {
          publicURL
        }
      }
    }
    work {
      slug
      categories
      title
      logo {
        publicURL
      }
      alt_text
      image {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    vision {
      description
      title
    }
    customers {
      title
      entries {
        alt_text
        link
        image {
          publicURL
        }
      }
    }
    facts {
      large_number
      small_number
      title
      description
      background_color
      font_color
      icon {
        publicURL
      }
      alt_text
    }
    customers {
      title
      entries {
        image {
          publicURL
        }
      }
    }
  }
`
