import React from 'react'
import styled from 'styled-components'
import _get from 'lodash.get'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

import { media } from 'media'
import { einaRegular, konnectSemiBold } from 'common-styles'
import { CommonContainer, WhiteBackground } from 'components/home/common'
import { sidePadding, sidePaddingMobile } from 'components/base/CenterColumn'
import { generateFontSizeCss } from 'media'
import { appendBlueCharacter } from 'utils'

const Background = styled(WhiteBackground)`
  z-index: 0;
  
  ${media.desktop`
    padding: 150px 0;
  `}
`

const INNER_DISTANCE = 30
const IMAGES_PER_ROW = 3
const FLEX_BASIS = `calc((100% / (${IMAGES_PER_ROW})) - ${INNER_DISTANCE}px)`
const EntriesContainer = styled(CommonContainer)`
  display: flex;
  flex-direction: column;
  ${media.desktop`
    flex-direction: unset;
    flex-wrap: wrap;
    justify-content: space-evenly;
    ${media.msEdge`
      ${media.desktop`
        width: calc(100% - ${2 * sidePadding - sidePaddingMobile}px);
        left: ${sidePaddingMobile / 2}px;
      `}
    `}
  `}
`

const Headline = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  margin: 0 auto;
  font-family: ${konnectSemiBold};
  text-align: center;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 20px;
  ${generateFontSizeCss(22, 32)}

  ${media.mobile`
    width: 75%;
  `}

  ${media.desktop`
    margin-bottom: 50px;
    line-height: 36px;
  `}
`

const EntryContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  ${media.desktop`
    flex: 1 ${FLEX_BASIS};
    max-width: ${FLEX_BASIS};
    ${({ index }) => index > 2 ? `
      margin-bottom: 0;
    ` : ''}
    ${media.msEdge`
      left: ${({ index }) => (index > 2 ? index - IMAGES_PER_ROW : index) * INNER_DISTANCE}px;
    `}
  `}

  ${media.mobile`
    ${({ index }) => index === 5 ? `
      margin-bottom: 0;
    ` : ''}
  `}

  .imageHover {
    display: none;
  }

  &:hover {
    cursor: pointer;
    
    .imageHover {
      display: inherit;
    }

    .imageNormal {
      display: none;
    }
  }
`

const PersonContainer = styled.div``
const ImageContainer = styled.a`
  display: block;
  display: relative;
  left: ${INNER_DISTANCE}px;
`

const OverlayHolder = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
`

const ImageContainerOverlay = styled.div`
  display: block;
  transition: .5s ease;
  opacity: 0.5;
  background: ${({ theme }) => theme.blackGradientToTop};
  text-align: center;
  width: 100%;
  height: 100%;
`

const PersonInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: -78px;
  left: 25px;
  max-width: calc(100% - 25px);
`

const PersonName = styled.div`
  text-align: left;
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
  font-family: ${konnectSemiBold};
  color: ${({ theme }) => theme.colors.white};
`

const PersonRole = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${einaRegular};
  ${media.desktop`
    font-size: 16px;
    line-height: 25px;
  `}
`

const MinorHeading = styled.h4`
  margin-top: 35px;
  font-size: 16px;

  color: ${({ theme }) => theme.colors.black};
  text-align: center;

  ${media.desktop`
  font-size: 20px;
  margin-top: 65px;
  `}
`

export const People = ({ data }) => {
  const title = _get(data, 'people.title', '')
  const punchLine = _get(data, 'people.punch_line', '')

  return (
    <Background>
      <Headline>{appendBlueCharacter(title)}</Headline>
      <EntriesContainer>
        {_get(data, 'people.entries', [])
          .map((entry, i) => {
            const fluidImage = _get(entry, 'image.childImageSharp.fluid', null)
            const fluidImageHover = _get(entry, 'image_hover.childImageSharp.fluid', null)
            const name = _get(entry, 'name', '')
            const role = _get(entry, 'role', '')

            return (
              <EntryContainer index={i} key={`people_gallery_entry_${i}`}>
                <PersonContainer>
                  <ImageContainer href={_get(entry, 'linkedin_link', '')} target={'_blank'}>
                    <Img
                      className={'imageNormal'}
                      fluid={fluidImage}
                      alt={name + ' Profile Image'}
                      title={name} />
                    <Img
                      className={'imageHover'}
                      fluid={fluidImageHover}
                      alt={name + ' Profile Image Hover'}
                      title={name}
                      loading={true} />
                    <OverlayHolder>
                      <ImageContainerOverlay />
                      <PersonInfoContainer>
                        <PersonName>{name}</PersonName>
                        <PersonRole>{role}</PersonRole>
                      </PersonInfoContainer>
                    </OverlayHolder>
                  </ImageContainer>
                </PersonContainer>
              </EntryContainer>
            )
          })}
      </EntriesContainer>
      <MinorHeading>
       {punchLine}
      </MinorHeading>
    </Background>
  )
}

People.propTypes = {
  data: PropTypes.shape().isRequired,
}


{/* <h4 style="
    margin-top: 65px;
    color: black;
    font-size: 20px;
">More To Come...
    </h4> */}