import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import _get from 'lodash.get'

const Highlighting = styled(Link)`
  color: ${({ theme, active }) => active ? theme.colors.blue : theme.colors.white};

  &:hover {
    color: ${({ theme, active }) => active ? theme.colors.blue : theme.colors.white} !important;
    text-decoration: none;
  }
`

const Slash = styled.span`
  color: ${({ theme }) => theme.colors.graySecondary};
`

const languageVersion = (languageTarget) => {
  let urlParam = '/'
  /* Avoid 'undefined window' during server-side compilation */
  if (typeof window !== 'undefined') urlParam = window.location.pathname
  /* Reset link params */
  urlParam = urlParam.replace('/de/', '/')

  let prefix = languageTarget === 'de' ? '/de' : ''
  return `${prefix}${urlParam}`
}

const isBlogPost = () => {
  let urlParam = '/'
  if (typeof window !== 'undefined') urlParam = window.location.pathname
  const regex = new RegExp(/blog[/]?[a-z0-9]+/, 'g')
  return regex.test(urlParam)
}

export const LanguageSwitch = ({ locale }) => {
  const isEnglish = locale === 'en'
  const isGerman = locale === 'de'

  return (
    <>
      {!isBlogPost() && (
        <>
          <Highlighting to={languageVersion('en')} active={isEnglish}>En </Highlighting>
          <Slash>/</Slash>
          <Highlighting to={languageVersion('de')} active={isGerman}> De</Highlighting>
        </>
      )}
    </>
  )
}
