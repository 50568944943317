import { createGlobalStyle } from 'styled-components'

import { einaRegular, einaLight, einaSemiBold, konnectRegular, konnectSemiBold } from 'common-styles'
import { generateFontSizeCss, media, MIN_WIDTH_BREAKPOINT } from 'media'
import { theme } from 'theme'

const fontSizeMapping = {
  h1: { min: 26, max: 46 },
  h2: { min: 22, max: 32 },
  h3: { min: 16, max: 18 },
  h4: { min: 14, max: 15 },
  p: { min: 14, max: 16 },
}

const generateFontSizeCssForMapping = mapping => generateFontSizeCss(mapping.min, mapping.max)

export default createGlobalStyle`
  @font-face {
    font-family: ${konnectRegular};
    src: url('/fonts/Konnect-Regular.otf');
    font-display: block;
  }

  @font-face {
    font-family: ${konnectSemiBold};
    src: url('/fonts/Konnect-SemiBold.otf');
    font-display: block;
  }

  @font-face {
    font-family: ${einaRegular};
    src: url('/fonts/Eina04-Regular.otf');
    font-display: block;
  }

  @font-face {
    font-family: ${einaLight};
    src: url('/fonts/Eina04-Light.otf');
    font-display: block;
  }

  @font-face {
    font-family: ${einaSemiBold};
    src: url('/fonts/Eina04-SemiBold.otf');
    font-display: block;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: ${einaRegular}, -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    line-height: 1;
    font-size: 1.6rem;
    color: #000;
    background-color: ${({ theme }) => theme.colors.black};
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.colors.blue};
    }
  }

  ${media.desktop`
    a.link { text-decoration: underline 2px ${({ theme }) => theme.colors.blue}; }
  `}

  ${media.mobile`
    a.link { color: ${({ theme }) => theme.colors.blue}; }
  `}

  @media only screen and (min-width: 440px) {
    .word-wrapping-mobile { display: none; }
  }

  @media only screen and (min-width: 360px) {
    .german-word-wrapping-mobile { display: none; }
  }

  .word-wrapping-tablet { display: none; }
  @media only screen and ${MIN_WIDTH_BREAKPOINT} and (max-width: 940px)  {
    .word-wrapping-tablet { display: inherit; !important }
  }

  pre {
    display: block;
    padding: 2rem;
    margin-top: 4rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 5px;
    color: ${theme.colors.black};
    border: 1px solid #ddd;
    font-family: ${einaRegular}, "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  }

  strong,
  b {
    font-weight: bold !important;
  }

  video {
    max-width: 100%;
  }

  p {
    margin-bottom: 2rem;
  }

  h1 {
    font-family: ${konnectSemiBold};
    ${generateFontSizeCssForMapping(fontSizeMapping.h1)};
  }

  h2 {
    font-family: ${konnectSemiBold};
    ${generateFontSizeCssForMapping(fontSizeMapping.h2)};
  }

  h3 {
    font-family: ${konnectSemiBold};
    ${generateFontSizeCssForMapping(fontSizeMapping.h3)};
  }

  h4 {
    font-family: ${konnectSemiBold};
    ${generateFontSizeCssForMapping(fontSizeMapping.h4)};
  }

  p {
    font-family: ${einaRegular};
    ${generateFontSizeCssForMapping(fontSizeMapping.p)};
  }
`
