import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { JobsOverviewPage } from 'content/jobs-overview-page/JobsOverviewPage'

const JobsOverview = ({ data, pageContext }) => {
  console.log("OVERVIEW DATA", data)
  return <JobsOverviewPage
    data={data.jobsOverviewJson}
    jobPosts={data.allMarkdownRemark.edges.map( e => ({node: e.node.frontmatter}))}
  />
}

JobsOverview.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape().isRequired,
}

export default JobsOverview

export const query = graphql`
  query($slug: String!) {
    jobsOverviewJson(fields: { slug: { eq: $slug } }) {
      ...JobsOverviewPage
    }
    allMarkdownRemark (filter: {frontmatter: {_enabled: {eq: true},  _type: {eq: "job"}}}) {
      edges {
        node {
          frontmatter{
          location
          team
          timeIcon
          title
          _locale
          _slug
        }
      }
      }
    }
  }
`
