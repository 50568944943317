import React from 'react'

import { CenterColumn } from 'components/base/CenterColumn'
import Layout from 'components/Layout'

const NotFound = () => (
  <Layout>
    <CenterColumn>
      <h1>Not found.</h1>
    </CenterColumn>
  </Layout>
)

export default NotFound
