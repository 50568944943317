import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { media } from 'media'
import Icon from './Icon'

const Svg = styled(Icon)`
  width: 22px;
  height: auto;

  ${media.desktop`
    width: 22px;
    height: 21px;
  `}
  color: ${({ theme }) => theme.colors.white};

  .a {
    fill: #393939;
  }
  .b, .c, .f {
    fill: none;
  }
  .c { 
    stroke: #fff;
    stroke-width: 2px;
  }
  .d, .e {
    stroke: none;
  }
  .e { 
    fill: #fff; 
  }
`

export const Location = ({ className }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" className={className}>
    <defs><style></style></defs>
    <g transform="translate(-497 -329)"><circle className="a" cx="20" cy="20" r="20" transform="translate(497 329)" />
      <g transform="translate(-1)">
        <g className="b" transform="translate(509.5 337)">
          <path className="d" d="M8.5,0A8.45,8.45,0,0,1,17,8.4C17,15.33,10.655,24,8.5,24S0,15.33,0,8.4A8.45,8.45,0,0,1,8.5,0Z" />
          <path className="e" d="M 8.5 1.999998092651367 C 4.915889739990234 1.999998092651367 2 4.871028900146484 2 8.399998664855957 C 2 10.89664936065674 3.02808952331543 14.1060094833374 4.820659637451172 17.20516967773438 C 5.568140029907227 18.49748992919922 6.427550315856934 19.72028923034668 7.240579605102539 20.64831924438477 C 7.802277565002441 21.28947448730469 8.23171329498291 21.66470336914062 8.5 21.86280822753906 C 8.76828670501709 21.66470336914062 9.197721481323242 21.28947448730469 9.759420394897461 20.64831924438477 C 10.57245063781738 19.72028923034668 11.43185997009277 18.49748992919922 12.17934036254883 17.20516967773438 C 13.97191047668457 14.1060094833374 15 10.89664936065674 15 8.399998664855957 C 15 4.871028900146484 12.08411026000977 1.999998092651367 8.5 1.999998092651367 M 8.5 -1.9073486328125e-06 C 13.19441986083984 -1.9073486328125e-06 17 3.760808944702148 17 8.399998664855957 C 17 15.33009910583496 10.65546035766602 24 8.5 24 C 6.344539642333984 24 0 15.33009910583496 0 8.399998664855957 C 0 3.760808944702148 3.805580139160156 -1.9073486328125e-06 8.5 -1.9073486328125e-06 Z" />
        </g>
        <g className="c" transform="translate(514.5 342)">
          <circle className="d" cx="3.5" cy="3.5" r="3.5" />
          <circle className="f" cx="3.5" cy="3.5" r="2.5" />
        </g>
      </g>
    </g>
  </Svg>
)

Location.propTypes = {
  className: PropTypes.string,
}

export default Location
