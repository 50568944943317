import React from 'react'
import styled, { css } from 'styled-components'
import _get from 'lodash.get'
import PropTypes from 'prop-types'

import { CenterColumn } from 'components/base/CenterColumn'
import { ExternalButtonLinkWithIcon } from 'components/ButtonLink'
import { ArrowRight } from 'components/icons/ArrowRight'
import Icon from 'components/icons/Icon'
import { media } from 'media'
import { graphql, useStaticQuery } from 'gatsby'
import Nav from 'components/Nav'
import { Link } from 'gatsby'
import invertedLogoSrc from 'resources/images/9y-logo-inverted.png'
import { GeneralContext } from '../context/general_context'

export const height = 90
const Wrapper = styled.header`
  position: fixed;
  height: ${height}px;
  z-index: 2;
  width: 100%;

  ${media.mobile`
    background: ${({ theme }) => theme.blackGradientToBottom};
  `}

  ${media.desktop`
    ${({ stickyOnDesktop }) =>
      stickyOnDesktop
      ?
      css`
      height: 134px;
      background: ${({ theme }) => theme.blackGradientToBottom};`
      :
      'position: absolute;'
    }}
  `}
`

const Container = styled(CenterColumn)`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  max-width: none;

  ${media.desktop`
    ${({ stickyOnDesktop }) =>
      stickyOnDesktop
      ?
      'align-items: center;'
      :
      'align-items: flex-end;'
    }}
  `}
`

const BackButton = styled(ExternalButtonLinkWithIcon)`
  border: none;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  pointer-events: auto;

  ${({ mobileMenuOpen }) => mobileMenuOpen && css`
    display: none;
  `}

  ${Icon} {
    width: 16px;
    height: auto;
  }

  &:hover {
    background: transparent;
    ${Icon} {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  ${media.desktop`
    font-size: 15px;
  `}
`

const ArrowLeft = styled(ArrowRight)`
  transform: scaleX(-1);
`

export const Logo = styled.img`
  height: 25px;
  width: auto;
  position: relative;
  z-index: 1;
  ${media.desktop`
    height: 30px;
  `}
`

const back = event => {
  event.preventDefault()
  window.history.back()
}

export const Header = ({
  slug_prefix,
  locale = 'en',
  isLightTheme = false,
  idPrefix,
  filledCtaButton,
  stickyOnDesktop = false,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allHeaderJson(filter: {_for: {eq: "index"}}) {
        edges {
          node {
            entries {
              back
              lets_talk
              locale
            }
          }
        }
      }
    }
  `)

  const { mobileMenuOpen, setMobileMenuOpen } = React.useContext(GeneralContext)
  const headerEntries = _get(data, 'allHeaderJson.edges.0.node.entries', [])
  const headerData = headerEntries.find(h => h.locale === locale)
  const isFilled = (typeof filledCtaButton !== 'undefined') ? filledCtaButton : !mobileMenuOpen

  return (
    <Wrapper stickyOnDesktop={stickyOnDesktop}>
      <Container stickyOnDesktop={stickyOnDesktop}>
        {mobileMenuOpen &&
          <Link to={`/${slug_prefix}`} onClick={() => setMobileMenuOpen(false)}>
            <Logo src={invertedLogoSrc} alt="9y" />
          </Link>}
        <BackButton 
          mobileMenuOpen={mobileMenuOpen} 
          onClick={back} 
          href="#" 
          isLeftIcon 
          icon={<ArrowLeft />}
        >
          {_get(headerData, 'back', '')}
        </BackButton>
        <Nav 
          locale={locale} 
          slug_prefix={slug_prefix} 
          idPrefix={idPrefix} 
          isLightTheme={isLightTheme} 
          filledColorButton={isFilled} 
        />
      </Container>
    </Wrapper>
  )
}

Header.propTypes = {
  slug_prefix: PropTypes.string,
  locale: PropTypes.string,
  isLightTheme: PropTypes.bool,
  idPrefix: PropTypes.string,
  filledCtaButton: PropTypes.bool,
  stickyOnDesktop: PropTypes.bool,
}
