import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _get from 'lodash.get'

import { graphql, useStaticQuery } from 'gatsby'

const Container = styled.div`
  display: none;

  @media only screen and (min-width: 1800px) {
    display: inline;
    width: auto;
    color: ${({ isLightTheme, theme }) => isLightTheme ? theme.colors.black : theme.colors.white };
    position: fixed;
    left: -150px;
    bottom: 235px;
    transform: rotate(270deg);
  }
`

export const StickySlogan = ({ isLightTheme = false }) => {
  const data = useStaticQuery(graphql`
    query {
      allHeaderJson(filter: {_for: {eq: "index"}}) {
        edges {
          node {
            slogan
          }
        }
      }
    }
  `)

  const slogan = _get(data, 'allHeaderJson.edges.0.node.slogan', '')

  return (
    <Container isLightTheme={isLightTheme}>
      {slogan}
    </Container>
  )
}

StickySlogan.propTypes = {
  isLightTheme: PropTypes.bool,
}