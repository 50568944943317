import React from 'react'
import _get from 'lodash.get'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'

import { CenterColumn } from 'components/base/CenterColumn'
import { media, generateFontSizeCss } from 'media'
import { afterDot } from 'custom-css'
import { ExternalButtonLinkLarge } from 'components/ButtonLink'
import { ArrowRight } from 'components/icons/ArrowRight'
import { appendBlueCharacter } from 'utils'

const Container = styled(CenterColumn)`
  display: flex;
  justify-content: center;
`

const ContentContainer = styled.div`
  max-width: 775px;
  width: 100%;
  padding: 60px 0;

  ${media.desktop`
    padding: 90px 0;
  `}
`

const ServiceOffer = styled.article`
  margin-bottom: 50px;

  ${media.desktop`
    margin-bottom: 75px;
  `}
`

const Heading = styled.h2`
  ${generateFontSizeCss(22, 32)}
  margin-bottom: 10px;

  ${media.desktop`
    margin-bottom: 15px;
  `}
`

const Description = styled.p`
  color: ${({ theme }) => theme.colors.grey};
  line-height: 22px;
  margin-bottom: 30px;

  ${media.desktop`
    margin-bottom: 35px;
    line-height: 28px;
  `}
`

const GreyLine = styled.hr`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.graySecondary};
  margin: 0;
  width: 75px;
  margin-bottom: 15px;

  ${media.desktop`
    margin-bottom: 20px;
  `}
`

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 650px;
`

const Tag = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  ${({ lastTag }) => !lastTag ? afterDot(5) : ''};
  margin: 0 10px 10px 0;
  ${generateFontSizeCss(14, 16)}

  &::after {
    margin-left: 10px;
    position: relative;
    top: -3px;
  }

  ${media.desktop`
    margin: 0 15px 15px 0;

    &::after {
      margin-left: 15px;
    }
  `}
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const ButtonLink = styled(ExternalButtonLinkLarge)`
  display: inline-block;
`

export const Offers = ({ data }) => {
  const serviceOffers = _get(data, 'service_offers.entries', [])
  const buttonText = _get(data, 'service_offers.cta_button', '')
  const pdfFiles = useStaticQuery(graphql`
    {
      allFile(filter: {extension: {eq: "pdf"}}) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  const allPdfs = pdfFiles.allFile.edges
  const capabilitiesPdf = allPdfs.filter(pdf => 
    pdf.node.name === '9Y-Technical-Capabilities-Template'
  )

  return (
    <Container>
      <ContentContainer>
        {serviceOffers.map((entry, i) => {
          const tags = entry.tags

          return (
            <ServiceOffer key={`service_offer_${i}`}>
              <Heading>
                {appendBlueCharacter(entry.title)}
              </Heading>
              <Description>{entry.text}</Description>
              <GreyLine />
              <TagsContainer>
                {tags.map((tag, i) => {
                  const lastTag = (i === tags.length - 1)

                  return (
                    <Tag lastTag={lastTag} key={`tag_${i}`}>#{tag}</Tag>
                  )
                })}
              </TagsContainer>
            </ServiceOffer>
          )
        })}
        <ButtonContainer>
          <ButtonLink href={capabilitiesPdf[0].node.publicURL} target="_blank">
            {buttonText}
            <ArrowRight />
          </ButtonLink>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )
}

Offers.propTypes = {
  data: PropTypes.shape().isRequired,
}
