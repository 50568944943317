import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { media } from 'media'
import Icon from './Icon'

const Svg = styled(Icon)`
  width: 15px;
  height: auto;

  ${media.desktop`
    width: 22.415px;
    height: 13.414px;
  `}
  color: ${({ theme }) => theme.colors.white};
`

export const Cross = ({ className }) => (
  <Svg viewBox="0 0 22.88 22.88" xmlSpace="preserve" className={className}>
    <path fill="#FFFFFF" d="M0.324,1.909c-0.429-0.429-0.429-1.143,0-1.587c0.444-0.429,1.143-0.429,1.587,0l9.523,9.539
	l9.539-9.539c0.429-0.429,1.143-0.429,1.571,0c0.444,0.444,0.444,1.159,0,1.587l-9.523,9.524l9.523,9.539
	c0.444,0.429,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-9.539-9.539l-9.523,9.539c-0.444,0.429-1.143,0.429-1.587,0
	c-0.429-0.444-0.429-1.159,0-1.587l9.523-9.539L0.324,1.909z"/>
  </Svg>
)

Cross.propTypes = {
  className: PropTypes.string,
}

export default Cross
