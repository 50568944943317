import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { ImprintPage } from 'content/imprint-page/ImprintPage'

const Imprint = ({ data }) => {
  return <ImprintPage data={data.imprintJson} />
}

Imprint.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default Imprint

export const query = graphql`
  query($slug: String!) {
    imprintJson(fields: { slug: { eq: $slug } }) {
      ...ImprintPage
    }
  }
`
