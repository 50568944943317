import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { CenterColumn } from 'components/base/CenterColumn'
import { GradientBackground } from 'components/base/GradientBackground'
import Layout from 'components/Layout'
import { media } from 'media'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 1050px;
  margin: 0 auto;
  padding: 10px 0 60px;

  ${media.desktop`
    padding-bottom: 90px;
  `}
`

const Title = styled.h1`
  width: 100%;
  text-align: center;
  margin-bottom: 30px;

  ${media.desktop`
    margin-bottom: 50px;
  `}
`

const LocationContainer = styled.section`
  width: 100%;
`

const Locations = styled.section`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${media.desktop`
    justify-content: space-between;
    flex-direction: row;
  `}
`

const Location = styled.article`
  background: ${({ inverted, theme }) => inverted ? 'transparent' : theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ inverted, theme }) => inverted ? theme.colors.white : theme.colors.black};
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  max-width: 260px;
  min-height: 135px;

  ${media.mobile`
    :not(:last-child) {
      margin-bottom: 15px;
    }
  `}

  ${media.desktop`
    max-width: none;
    margin-bottom: 0;
    min-height: 170px;
    padding: 30px;
    width: calc(25% - 30px);
  `}
`

const LocationTitle = styled.h3`
  margin-bottom: 20px;
`

const LocationRow = styled.div`
  :not(:last-child) {
    margin-bottom: 5px;
  }

  word-wrap: break-word;
  a {
    color: ${({ inverted, theme }) => inverted ? theme.colors.white : theme.colors.black};
  }
`

const LegalContainer = styled.section`
  margin-top: 80px;
  width: 100%;
`

const Legals = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
  `}
`

const Legal = styled.article`
  width: 100%;
  max-width: 450px;

  :not(:last-child) {
    margin-bottom: 30px;
  }

  ${media.desktop`
    max-width: none;
    margin-bottom: 0;
    width: calc(50% - 30px);
  `}
`

const LegalTitle = styled.h3`
  margin-bottom: 20px;
`

const LegalRow = styled.div`
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.grey};
`

export const ImprintPage = ({ data }) => (
  <Layout
    locale={data._locale}
    slug_prefix={data._slug_prefix}
    idPrefix={'imprint'}
    metadata={data._meta}
  >
    <CenterColumn>
      <GradientBackground>
        <Container>
          <LocationContainer>
            <Title>{data.locationTitle}</Title>
            <Locations>
              {data.locations.map((l, i) => (
                <Location key={`location-${i}`} inverted={l.inverted}>
                  <LocationTitle>{l.title}</LocationTitle>
                  {l.info.map((t, j) => (
                    <LocationRow
                      key={`location-${i}-row-${j}`}
                      dangerouslySetInnerHTML={{ __html: t }}
                      inverted={l.inverted}
                    />
                  ))}
                </Location>
              ))}
            </Locations>
          </LocationContainer>
          <LegalContainer>
            <Title>{data.legalTitle}</Title>
            <Legals>
              {data.legals.map((l, i) => (
                <Legal key={`legal-${i}`}>
                  <LegalTitle>{l.title}</LegalTitle>
                  {l.text.map((t, j) => (
                    <LegalRow key={`legal-${i}-row-${j}`}>{t}</LegalRow>
                  ))}
                </Legal>
              ))}
            </Legals>
          </LegalContainer>
        </Container>
      </GradientBackground>
    </CenterColumn>
  </Layout>
)

ImprintPage.propTypes = {
  data: PropTypes.shape().isRequired,
}

export const query = graphql`
  fragment ImprintPage on ImprintJson {
    _name
    _locale
    _slug_prefix
    _meta {
      lang
      pageTitle
      pageDescription
      other_lang {
        code
        url
        _enabled
      }
    }
    locationTitle
    locations {
      title
      info
      inverted
    }
    legalTitle
    legals {
      title
      text
    }
  }
`
