import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { CenterColumn } from 'components/base/CenterColumn'
import { einaLight } from 'common-styles'
import { media } from 'media'
import { WhiteBackground } from 'components/home/common'
import { Paragraph } from 'components/Paragraph'
import { regularStyle, largeStyle } from 'components/Paragraph'
import { generateFontSizeCss } from 'media'


const elementStyle = css`
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 775px;
  text-align: left;
`

const Content = styled(CenterColumn)`
  color: ${({ theme }) => theme.colors.black};
  max-width: 1042px;

  ${media.desktop`
    width: calc(100%);
    * { line-height: 34px; }
    a { text-decoration: underline 2px ${({ theme }) => theme.colors.blue}; }

    .introduction { line-height: 42px; }
    .introduction.last {
      margin-bottom: 100px;
    }
    
    
  `}

  ${media.mobile`
    * { line-height: 31px; }
    a { 
      color: ${({ theme }) => theme.colors.blue}; 
      line-height: 25px;
    }

    .introduction { line-height: 31px; }
    .introduction.last {
      margin-bottom: 80px;
    }
  `}

  .introduction {
    font-family: ${einaLight};
    ${generateFontSizeCss(18, 26)}
  }

  strong,
  b {
    font-weight: bold;
  }

  p, li, ul {
    font-family: ${einaLight};

    ${media.mobile`
      ${largeStyle};
      font-size: 16px;
      line-height: 25px;
    `}

    ${media.desktop`
      font-size: 20px;
      line-height: 34px;
    `}
  }
  
  ul {
    display: block;
    list-style-type: disc;
    padding-inline-start: 2rem;
    max-width: calc(100% - 2.5rem);
    margin-bottom: 2rem;

    ${media.desktop`
      padding-inline-start: 4rem;
      max-width: calc(100% - 4.5rem);
    `}
  }

  li {
    display: list-item;
    width: 100%;

    &::marker {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  h1 {
    ${elementStyle};
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 20px;

    ${media.mobile`
      font-size: 19px;
      line-height: 29px;
      margin-top: 40px;
    `}

    ${media.desktop`
      font-size: 26px;
      line-height: 34px;
      margin-top: 70px;
    `}
  }

  h3 {
    margin-bottom: 10px;
    
    ${media.mobile`
      line-height: 25px;
    `}
  }

  h4 {
    ${elementStyle};
    margin-bottom: 5px;
  }

  h5 {
    ${elementStyle};
    margin-bottom: 5px;
  }

  img, .gatsby-resp-image-wrapper {
    pointer-events: none;
  }

  em {
    font-style: italic;
    line-height: inherit;
  }
`

// const Background = styled(WhiteBackground)`
//   padding: 60px 0;

//   ${media.desktop`
//     padding: 90px 0;
//   `}
// `


const Background = styled(WhiteBackground)`
  padding: 60px 0;

  ${media.desktop`
    padding: 90px 0;
  `}
`

const StyledParagraph = styled(Paragraph)`
  font-family: ${einaLight};

  ${media.mobile`
    font-size: 16px;
    line-height: 25px;
  `}

  ${media.desktop`
    font-size: 20px;
    line-height: 34px;
  `}
`

const Container = styled(CenterColumn)`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black};
  max-width: 775px;
`

const ContentContainer = styled.div`
  max-width: 1300px;
  width: 100%;

  a {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.blue};
    text-decoration-thickness: 2px;
  }

  p:last-child {
    margin: 0;
  }
`

const IntroductionText = styled(Paragraph)`
  margin-bottom: 3rem;
  line-height: 28px;
  font-family: ${einaLight};

  ${media.mobile`
    font-size: 18px;
    line-height: 31px;
  `}

  ${media.desktop`
    font-size: 26px;
    line-height: 42px;
  `}
`

const List = styled.ul`
  font-family: ${einaLight};

  li {
    margin-left: 2rem;

    ${media.mobile`
      font-size: 16px;
      line-height: 25px;
    `}

    ${media.desktop`
      font-size: 20px;
      line-height: 34px;
    `}
  }

  li::before {
    color: ${({ theme }) => theme.colors.blue};
    content: '\\2022';
    font-weight: bold;
    display: inline-block;
    width: 20px;
    margin-left: -2rem;
  }
`

const SubHeading = styled.h2`
  margin-bottom: 20px;

  ${media.mobile`
    margin-top: 60px;
    font-size: 19px;
    line-height: 29px;
  `}

  ${media.desktop`
    margin-top: 100px;
    font-size: 26px;
    line-height: 34px;
  `}
`

/**
 * Add span tags to fix production build bug 
 * https://github.com/gatsbyjs/gatsby/issues/11108
 */
const returnList = (content) => (
  <List>
    {content.map((sentence, i) => (
      <li key={i} dangerouslySetInnerHTML={{ __html: `<span>${sentence}</span>` }}></li>
    ))}
  </List>
)

const returnParagraphs = (content) => (
  <>
    {content.map((text, i) => (
      <StyledParagraph key={i} dangerouslySetInnerHTML={{ __html: `<span>${text}</span>` }} />
    ))}
  </>
)


const addIntroductionStyling = (blogReference) => {
  const childrenElements = blogReference.current.children
  let i

  if(childrenElements == undefined){
    return
  }

  for (i = 0; childrenElements.length > i; i++) {
    if (childrenElements[i].tagName !== 'P') break
    childrenElements[i].className = 'introduction'
  }
 
  if(i != 0){
    childrenElements[i - 1].className = 'introduction last'
  }
}

export const JobDescription = ({
  data: {
    introduction,
    content,
  }, html,
}) => {
  let mdContent = useRef(null)

  useEffect(() => {
    if (mdContent && mdContent.current){
      addIntroductionStyling(mdContent)
    } 
  },[mdContent])

  if ((!introduction || !content) && !html) return null



  return (
    <Background>
      <Container>
        <ContentContainer>

          {introduction?.map(((text, index) => (
            <IntroductionText key={index}>{text}</IntroductionText>
          )))}

          {content?.map((({ type, title, body }, index) => (
            <span key={index}>
              <SubHeading >{title}</SubHeading>

              {type === 'list' && returnList(body)}
              {type === 'paragraph' && returnParagraphs(body)}
            </span>
          )))}
        
          {html && 
            <Content ref={mdContent} dangerouslySetInnerHTML={{ __html: html }} />
          }
        </ContentContainer>
    
      </Container>
 
    </Background>
  )
}

JobDescription.propTypes = {
  data: PropTypes.shape().isRequired,
}
