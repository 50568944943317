import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Icon from './Icon'

const Svg = styled(Icon)`
  width: 24px;
  stroke: ${({ isLightTheme, theme }) => isLightTheme ? theme.colors.black : theme.colors.white};
  transition: 0.2s;
    
  g:first-child {
    opacity: 1;
    transition: opacity 0s 0.2s;
  }

  g:first-child line {
    transition: transform 0.2s 0.2s;
    transform: translateY(0px);
  }

  g:last-child {
    opacity: 0;
    transition: opacity 0s 0.2s;
  }

  g:last-child line {
    transition: transform 0.2s;
    transform: rotate(0deg);
    transform-origin: center;
  }

  &.-menu-open {
    stroke: #000;
  }

  &.-menu-open g:first-child {
    opacity: 0;
  }

  &.-menu-open g:first-child line {
    transition: transform 0.2s;
  }

  &.-menu-open g:first-child line:first-child {
    transform: translateY(7px);
  }

  &.-menu-open g:first-child line:last-child {
    transform: translateY(-7px);
  }

  &.-menu-open g:last-child {
    opacity: 1;
  }

  &.-menu-open g:last-child line {
    transition: transform 0.2s 0.2s;
  }
  
  &.-menu-open g:last-child line:first-child {
    transform: rotate(45deg);
  }

  &.-menu-open g:last-child line:last-child {
    transform: rotate(-45deg);
  }
`

export const HamburgerIcon = ({ cssClasses, isLightTheme }) => (
  <Svg className={`${cssClasses}`} isLightTheme={isLightTheme} 
    width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g>
      <line x1="0" y1="17" x2="48" y2="17" strokeWidth="3" />
      <line x1="0" y1="31" x2="48" y2="31" strokeWidth="3" />
    </g>

    <g>
      <line x1="0" y1="24" x2="48" y2="24" strokeWidth="3" />
      <line x1="0" y1="24" x2="48" y2="24" strokeWidth="3" />
    </g>
  </Svg>
)

HamburgerIcon.propTypes = {
  cssClasses: PropTypes.string,
  isLightTheme: PropTypes.bool,
}

export default HamburgerIcon
