import React from 'react'
import { Talk } from 'components/home/talking'
import _get from 'lodash.get'
import PropTypes from 'prop-types'

export const Vision = ({ data }) => {
  return (
    <Talk
      title={_get(data, 'vision.title', '')}
      description={_get(data, 'vision.description', [])} />
  )
}

Vision.propTypes = {
  data: PropTypes.shape().isRequired,
}
