import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { CenterColumn } from 'components/base/CenterColumn'
import { media, generateFontSizeCss } from 'media'
import { WhiteBackground } from 'components/home/common'
import PieChart from 'components/icons/PieChart'
import { konnectSemiBold } from 'common-styles'

const Background = styled(WhiteBackground)`
  padding: 0 0 60px 0;

  ${media.desktop`
    padding: 0 0 120px 0;
  `}
`

const Container = styled(CenterColumn)`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black};
`

const ContentContainer = styled.div`
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.desktop`
    flex-direction: row;
    justify-content: space-between;
  `}
`

const StyledPieChart = styled(PieChart)`
  max-width: 640px;

  ${media.mobile`
    margin-bottom: 50px;
  `}
`

const TextContainer = styled.div`
  max-width: 500px;

  ${media.desktop`
    margin-left: 50px;
  `}
`


const NormalText = styled.p`
  ${generateFontSizeCss(22, 32)}
  font-family: ${konnectSemiBold};
  line-height: 32px;

  ${media.mobile`
    ${({ lastText }) => !lastText ? 
      'margin-bottom: 45px;' : 
      'margin-bottom: 0;'
    }
  `}

  ${media.desktop`
    line-height: 46px;
    ${({ lastText }) => !lastText ? 
      'margin-bottom: 100px;' : 
      'margin-bottom: 0;'
    }
  `}
`

const ColoredText = styled.p`
  color: ${({ theme }) => theme.colors.blue};
  ${generateFontSizeCss(22, 32)}
  font-family: ${konnectSemiBold};
  line-height: 32px;
  margin-bottom: 0;

  ${media.desktop`
    line-height: 46px;
  `}
`

export const Customers = ({ data, className, locale }) => {
  const customers = data.customers
  const isGerman = (locale === 'de' ? true : false)
  
  return (
    <Background>
      <Container>
        <ContentContainer>
          <StyledPieChart isGerman={isGerman}/>
          <TextContainer>
            {customers.texts.map((text, i) => {
              const lastText = (i === customers.texts.length - 1)

              return (
                <NormalText lastText={lastText} key={`text_${i}`}>{text}</NormalText>
              )
            })}
            <ColoredText>{customers.colored_text}</ColoredText>
          </TextContainer>
        </ContentContainer>
      </Container>
    </Background>
  )
}

Customers.propTypes = {
  data: PropTypes.shape().isRequired,
  className: PropTypes.string,
}
