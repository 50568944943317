import React from 'react'
import { Talk } from 'components/home/talking'
import _get from 'lodash.get'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledTalk = styled(Talk)`
  padding: 0;
`

export const Vision = ({ data }) => {
  return (
    <StyledTalk
      title={_get(data, 'vision.title', '')}
      titleColored={_get(data, 'vision.title_colored', '')}
      description={_get(data, 'vision.description', [])} />
  )
}

Vision.propTypes = {
  data: PropTypes.shape().isRequired,
}