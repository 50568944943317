import React from 'react'
import _get from 'lodash.get'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { CenterColumn } from 'components/base/CenterColumn'
import { media, generateFontSizeCss } from 'media'
import { WhiteBackground } from 'components/home/common'
import { konnectSemiBold } from 'common-styles'

const Background = styled(WhiteBackground)`
  ${media.desktop`
    padding: 120px 0 150px 0;
  `}
`

const Container = styled(CenterColumn)`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black};
`

const ContentContainer = styled.div`
  max-width: 775px;
  width: 100%;
`

const SectionHeading = styled.h2`
  ${generateFontSizeCss(22, 32)}
  text-align: center;
  margin-bottom: 70px;

  ${media.desktop`
    margin-bottom: 50px;
  `}
`

const Blue = styled.span`
  color: ${({ theme }) => theme.colors.blue};
`

const Value = styled.article`
  position: relative;
  z-index: 1;
  ${({ lastValue }) => !lastValue ? `
    margin-bottom: 70px;
  ` : ''}

  ${media.desktop`
    ${({ lastValue }) => !lastValue ? `
      margin-bottom: 130px;
  ` : ''}
  `}
`

const ValueHeading = styled.h3`
  ${generateFontSizeCss(22, 32)}
  margin-bottom: 10px;

  ${media.desktop`
    margin-bottom: 15px;
  `}
`

const Description = styled.p`
  line-height: 22px;

  ${media.desktop`
    line-height: 28px;
    margin-bottom: 28px;
  `}
`

const BackgroundNumber = styled.div`
  font-family: ${konnectSemiBold};
  ${generateFontSizeCss(80, 137)}
  color: ${({ theme }) => theme.colors.grayTertiary};
  position: absolute;
  z-index: -1;
  top: -40px;

  @media only screen and (min-width: 1000px) {
    top: -50px;
    left: -40px;
  }

  ${media.min1300`
    top: -70px;
    left: -70px;
  `}
`

export const Values = ({ data }) => {
  const values = _get(data, 'values.entries', [])
  const title = _get(data, 'values.title', [])
  const titleText = title.substring(0, title.length - 1)
  const titleLastChar = title.substring(title.length - 1, title.length)

  return (
    <Background>
      <Container>
        <ContentContainer>
          <SectionHeading>{titleText}<Blue>{titleLastChar}</Blue></SectionHeading>
          {values.map((entry, index) => {
            const lastValue = (index === values.length - 1)

            return (
              <Value lastValue={lastValue} key={`value_${index}`}>
                <BackgroundNumber>0{index + 1}</BackgroundNumber>
                <ValueHeading>{entry.title}</ValueHeading>
                <Description>{entry.text}</Description>
              </Value>
            )
          })}
        </ContentContainer>
      </Container>
    </Background>
  )
}

Values.propTypes = {
  data: PropTypes.shape().isRequired,
}