import React from 'react'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { einaLight, konnectSemiBold } from 'common-styles'
import PropTypes from 'prop-types'

import { media, generateFontSizeCss } from 'media'
import { WhiteBackground, CommonContainer } from 'components/home/common'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { ArrowRight } from 'components/icons/ArrowRight'
import { appendBlueCharacter } from 'utils'

const Background = styled(WhiteBackground)`
  color: ${({ theme }) => theme.colors.black};
`

const Container = styled(CommonContainer)`
  display: flex;
  flex-direction: column;
  max-width: 1000px !important;
}
`
const SectionHeading = styled.h2`
  text-align: center;
  margin-bottom: 50px;
`

const StyledSlide = styled(Slide)`
  pointer-events: none;

  &[aria-selected='false'] > div {
    opacity: 0;
  }

  &[aria-selected='true'] > div {
    opacity: 1;
    transition: opacity 2s ease-in-out;
  }
`

const QuoteText = styled.h3`
  line-height: 39px;
  font-family: ${einaLight};
  text-align: center;
  ${generateFontSizeCss(26, 46)}

  ${media.desktop`
    line-height: 74px;
  `}
`

const QuoteIcon = styled.img`
  width: 125px;
  height: auto;
  position: absolute;
  top: 58px;

  ${media.desktop`
    width: 295px;
    top: 10px;
    left: -30px;
  `}
`

const StyledCarouselProvider = styled(CarouselProvider)`
  > div:first-child,
  > div:nth-child(2) {
    user-select: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

  > div:nth-child(2) {
    height: 45px;
    margin-bottom: 30px;
    overflow: visible;
    position: relative;
    top: -80px;

    @media only screen and (max-width: 380px) {
      height: 60px !important;
    }

    ${media.desktop`
      margin-bottom: 50px;
    `}
  }

  > div:nth-child(2) > div {
    padding-top: 80px;
  }

  > div:first-child {
    ${media.desktop`
      height: 200px;
    `}

    @media only screen and (min-width: 430px) and (max-width: 768px) {
      height: 160px !important;
    }

    @media only screen and (max-width: 430px) {
      height: 220px !important;
    }

    @media only screen and (max-width: 350px) {
      height: 2 !important;
    }
    
    ${({ locale }) => locale === 'de' && css`
      @media only screen and (min-width: 1430px) {
        height: 275px;
      }

      @media only screen and (max-width: 350px) {
        height: 275px !important;
      }

      @media only screen and (max-width: 430px) {
        height: 250px !important;
      }
    `}
  }

  > div:first-child > div {
    height: 100% !important;
  }

  .customTransition {
    transition-property: transform, -webkit-transform;
    transition-duration: 2s, 1s;
    transition-timing-function: cubic-bezier(0, 0.96, 0.01, 0.97);
    transition-delay: 0s, 0s;
  }
`

const ButtonGroup = styled.div`
  ${media.desktop`
    position: absolute;
    top: -95px;
    right: 0;
    margin-left: 25px; 

    svg {
      position: relative;
      top: 2px;
    }
  `}
`

const StyledButtonBack = styled(ButtonBack)`
  height: ;
  width: ;
  background-color: ${({ theme }) => theme.colors.blue};
  border: none;
  border-radius: 100%;
  position: relative;
`

const StyledButtonNext = styled(ButtonNext)`
  height: ;
  width: ;
  background-color: ${({ theme }) => theme.colors.blue};
  border: none;
  border-radius: 100%;
  margin-left: 15px;
  position: relative;
`

const ProgressBar = styled.div`
  height: 3px;
  background-color: ${({ theme }) => theme.colors.grey};
  flex: 1;
  max-width: 6;

  ${media.mobile`
    margin-right: 25px;
  `}
`

const QuoteFooter = styled.div`
  ${media.mobile`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
  `}

  ${media.desktop`
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 6;
    margin: 0 auto;
  `}
`

const ArrowContainer = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const LeftArrow = styled(ArrowRight)`
  transform: rotate(180deg);
`

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 6;
  margin: 0 auto;
`

const ProfileTextWrapper = styled.div`
  p {
    margin: 0;
    line-height: 19px;
  }

  .profileTitle {
    font-family: ${konnectSemiBold};
    font-size: 16px;
  }
`

export const Quotes = ({
  _for = 'index',
  locale = 'en',
  className,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allQuotesJson {
        edges {
          node {
            _for
            background_icon { 
              publicURL
            }
            entries {
              locale
              title
              entries {
                quote
                name
                job_position
                id
                profile_image {
                  childImageSharp {
                    resize(height: 45, width: 45, cropFocus: CENTER) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const dataNodes = data.allQuotesJson.edges.find(e => e['node']['_for'] === _for)
  const languageNode = dataNodes['node']['entries'].find(e => e.locale === locale)
  const contentData = languageNode['entries']
  const title = languageNode['title']
  const backgroundIcon = dataNodes['node']['background_icon'].publicURL

  return (
    <Background className={className}>
      <Container>
        <SectionHeading>{appendBlueCharacter(title)}</SectionHeading>
        <QuoteIcon src={backgroundIcon} alt={'Quotes Icon'} title={'Quotes Icon'} />
        <StyledCarouselProvider
          dragEnabled={false}
          touchEnabled={false}
          orientation={'vertical'}
          naturalSlideWidth={100}
          naturalSlideHeight={52}
          totalSlides={contentData.length}
          infinite={true}
          isPlaying={true}
          interval={8000}
          locale={locale}
        >
          <Slider classNameAnimation={'customTransition'}> 
            {contentData.map((entry, i) => {
              return (
                <>
                  <StyledSlide index={i} key={`quote_${i}`}>
                    <QuoteText>{entry.quote}</QuoteText>
                  </StyledSlide>
                </>
              )
            })}
          </Slider>
          <Slider classNameAnimation={'customTransition'}> 
            {contentData.map((entry, i) => {
              const src = contentData[i].profile_image.childImageSharp.resize.src

              return (
                <>
                  <StyledSlide index={i} key={`profile_${i}`}>
                    <ProfileWrapper>
                      {/* <ProfileImage src={src} alt={`profile_image_${i}`}/> */}
                      <ProfileTextWrapper>
                        <p>
                          <span className="profileTitle">{entry.name}</span><br />
                          <span>{entry.job_position}</span>
                        </p>
                      </ProfileTextWrapper>
                    </ProfileWrapper>
                  </StyledSlide>
                </>
              )
            })}
          </Slider>
          <QuoteFooter>
            <ProgressBar />
            <ButtonGroup>
              <StyledButtonBack>
                <ArrowContainer>
                  <LeftArrow />
                </ArrowContainer>
              </StyledButtonBack>
              <StyledButtonNext>
                <ArrowContainer>
                  <ArrowRight />
                </ArrowContainer>
              </StyledButtonNext>
            </ButtonGroup>
          </QuoteFooter>
        </StyledCarouselProvider>
      </Container>
    </Background>
  )
}

Quotes.propTypes = {
  _for: PropTypes.string,
  locale: PropTypes.string,
  className: PropTypes.string,
}