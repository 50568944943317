import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import { CenterColumn as BaseCenterColumn } from 'components/base/CenterColumn'
import logoSrc from 'resources/images/9y-logo.png'
import invertedLogoSrc from 'resources/images/9y-logo-inverted.png'
import { media } from 'media'
import { GeneralContext } from '../context/general_context'

export const height = 134
export const heightMobile = 90

export const viewportHeightCss = css`
  height: calc(100vh - ${heightMobile}px);
  min-height: 1000px;

  ${media.desktop`
    height: calc(100vh - ${height}px);
    min-height: 600px;
  `}
`

export const Container = styled.header`
  height: ${heightMobile}px;
  position: relative;
  z-index: 2;

  ${media.desktop`
    height: ${height}px;
  `}
`

export const FixedContainer = styled.div`
  background: ${({ theme }) => theme.blackGradientToBottom};
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: ${heightMobile}px;

  ${media.desktop`
    height: ${height}px;
  `}
`

export const CenterColumn = styled(BaseCenterColumn)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Logo = styled.img`
  height: 25px;
  width: auto;
  position: relative;
  z-index: 1;
  ${media.desktop`
    height: 30px;
  `}
`

export const Header = ({ children, isLightTheme, slug_prefix = '' }) => {
  const { mobileMenuOpen, setMobileMenuOpen } = React.useContext(GeneralContext)

  return (
    <Container>
      <FixedContainer>
        <CenterColumn>
          <Link to={`/${slug_prefix}`} onClick={() => setMobileMenuOpen(false)}>
            <Logo src={isLightTheme || mobileMenuOpen ? invertedLogoSrc : logoSrc} alt="9y" />
          </Link>
          {children}
        </CenterColumn>
      </FixedContainer>
    </Container>
  )
}

Header.propTypes = {
  children: PropTypes.any,
  isLightTheme: PropTypes.bool,
  slug_prefix: PropTypes.string,
}

export default Header
