import hexToRgba from 'hex-to-rgba'

export const theme = {
  colors: {
    black: '#0B0C0D',
    // This is not potentially supported on some MS browsers - use rgba instead
    blackGradient: '#0B0C0D00',
    blue: '#2667FF',
    grey: '#BEBEBE',
    whiteGradient: '#FFFFFF00',
    white: '#FFFFFF',
    graySecondary: '#393939',
    grayTertiary: '#F5F5F5',
    grayLight: '#777777',
    errorBackground: '#2F1112',
    errorForeground: '#781D1D',
  },
  blackGradientToBottom: `linear-gradient(to bottom, ${hexToRgba('#0B0C0D', 1.0)}, ${hexToRgba('#0B0C0D', 0)}) 0% 0% no-repeat padding-box`,
  whiteGradientToBottom: `linear-gradient(to bottom, ${hexToRgba('#FFFFFF', 1.0)}, ${hexToRgba('#FFFFFF', 0)}) 0% 0% no-repeat padding-box`,
  blackGradientToTop: `linear-gradient(180deg, ${hexToRgba('#0B0C0D', 0)} 0%, ${hexToRgba('#0B0C0D', 0.2)} 75%, ${hexToRgba('#0B0C0D', 0.4)} 100%); 0% 0% no-repeat padding-box`,
}
