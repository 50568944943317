import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import _get from 'lodash.get'

import { theme } from 'theme'
import { sidePadding } from 'components/base/CenterColumn'
import { media } from 'media'
import { afterDot } from 'custom-css'
import { Service, BASE_HEIGHT, IMAGE_MARGIN } from './Service'
import { konnectSemiBold } from 'common-styles'
import { CommonContainer, WhiteBackground } from 'components/home/common'
import { ButtonLinkWithIcon } from 'components/ButtonLink'
import Icon from 'components/icons/Icon'
import { ArrowRight } from 'components/icons/ArrowRight'
import { Waypoint } from 'react-waypoint'

const CENTER_CONTAINER_DIM = '50vw'
const Container = styled(CommonContainer)``

const Background = styled(WhiteBackground)`
  ${media.desktop`
    padding: 150px 0;
  `}
`

const NotMobileHolder = styled.div`
  display: none;
  ${media.min1300`
    display: unset;
  `}
`

const MobileHolder =styled.div`
  margin-bottom: 35px;

  ${media.min1300`
    display: none;
  `}
`

const opacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const MAX_CIRCLE_DIM_PAGE = 700
const MAX_CIRCLE_DIM_DESKTOP = 500
const CenterContainer = styled.div`
  position: relative;
  width: ${CENTER_CONTAINER_DIM};
  height: ${CENTER_CONTAINER_DIM};
  background: ${theme.colors.grayTertiary};
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.min1300`
    opacity: 0;
    margin-left: 0;
    animation: 1s linear 1s normal forwards 1 ${opacity};
    max-width: ${MAX_CIRCLE_DIM_DESKTOP}px;
    max-height: ${MAX_CIRCLE_DIM_DESKTOP}px;
    margin-bottom: 145px;
  `}
  ${media.page`
    max-width: ${MAX_CIRCLE_DIM_PAGE}px;
    max-height: ${MAX_CIRCLE_DIM_PAGE}px;
  `}
`

const CenterContainerContent = styled.div`
  max-width: 200px;
  max-height: 100px;
`

const CenterText = styled.div`
  font-family: ${konnectSemiBold};
  color: ${theme.colors.black};
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  ${afterDot(4)}

  ${media.desktop`
    ${afterDot(6)}
    font-size: 32px;
    line-height: 40px;
  `}
`

const calcTransform = index => (285 + (index * 45))

const INDEX_COEFFICIENT_MAP = {
  0: [ 2 ],
  1: [ 2 ],
  2: [ 2 ],
}

const INDEX_LEFT_MAP_M1300 = [
  BASE_HEIGHT,
  BASE_HEIGHT,
  -103,
]

const INDEX_TOP_MAP_M1300 = [
  -40,
  50,
  140,
]

const INDEX_LEFT_MAP_PAGE = [
  BASE_HEIGHT,
  90,
  -173,
]

const INDEX_TOP_MAP_PAGE = [
  20,
  88,
  160,
]

const NonMobileEntryWrapper = styled.div`
  ${media.min1300`
    width: ${({ index }) => `calc(100vw - ${6*sidePadding}px - (((${MAX_CIRCLE_DIM_DESKTOP}px/${INDEX_COEFFICIENT_MAP[index][0]}))))`};
    opacity: 0;
    animation: 0.5s cubic-bezier(0.8, 0, 0, 0.8) ${({ index }) => index * 0.3}s normal forwards 1 ${opacity};
    position: absolute;
    top: ${({ index }) => `calc((${MAX_CIRCLE_DIM_DESKTOP}px/2) + ${INDEX_TOP_MAP_M1300[index]}px)`};
    left: ${({ index }) => `calc((${MAX_CIRCLE_DIM_DESKTOP}px/2) - ${2 * sidePadding}px + ${INDEX_LEFT_MAP_M1300[index]}px)`};
    transform: ${({ index }) => `rotate(${calcTransform(index)}deg) translate(calc(${MAX_CIRCLE_DIM_DESKTOP}px/2 - ${index % 2 === 0 ? '0' : '75'}px)) rotate(-${calcTransform(index)}deg)`};
  `}
  ${media.page`
    width: calc(510px + ${BASE_HEIGHT}px + ${IMAGE_MARGIN}px);
    position: absolute;
    top: ${({ index }) => `calc((${MAX_CIRCLE_DIM_PAGE}px/2) + ${INDEX_TOP_MAP_PAGE[index]}px)`};
    left: ${({ index }) => `calc((${MAX_CIRCLE_DIM_PAGE}px/2) - ${2 * sidePadding}px + ${INDEX_LEFT_MAP_PAGE[index]}px)`};
    transform: ${({ index }) => `rotate(${calcTransform(index)}deg) translate(calc((${MAX_CIRCLE_DIM_PAGE}px/2) - ${index % 2 === 0 ? '0' : '100'}px)) rotate(-${calcTransform(index)}deg)`};
  `}
`

const MobileEntriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 30px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const ServicesButton = styled(ButtonLinkWithIcon)`
  display: inline-block;
  font-size: 14px;
  pointer-events: auto;
  padding: 12px 15px;
  color: ${({ theme }) => theme.colors.black};

  ${Icon} {
    width: 16px;
    height: auto;
  }

  ${media.desktop`
    padding: 15px 20px;
    font-size: 15px;
    :hover {
      border-color: ${({ theme }) => theme.colors.blue};
    }
  `}
`

const DesktopWaypoint = styled(Waypoint)`
  ${media.desktopOnly};
`

export const Services = ({ data, slug_prefix }) => {
  const entries = _get(data, 'services.entries', [])
  const buttonText = _get(data, 'services.cta_button', '')
  let [renderServices, setRenderServices] = useState(false)

  const renderEntriesForNotMobile = () => {
    return (
      <>
        {entries.map((entry, i) => {
          return (
            <NonMobileEntryWrapper index={i} key={`service_${i}`}>
              <Service entry={entry} />
            </NonMobileEntryWrapper>
          )
        })}
      </>
    )
  }

  const renderEntriesForMobile = () => {
    return (
      <MobileEntriesWrapper>
        {entries.map((entry, i) => {
          return <Service key={`service_${i}`} entry={entry} />
        })}
      </MobileEntriesWrapper>
    )
  }

  return (
    <Background>
      <Container>
          <CenterContainer>
            <CenterContainerContent>
              <DesktopWaypoint bottomOffset={'120px'} onEnter={() => setRenderServices(true)} />
              <CenterText dangerouslySetInnerHTML={{ __html: _get(data, 'services.title', '') }} />
            </CenterContainerContent>
            {renderServices && <>
            <NotMobileHolder>{renderEntriesForNotMobile()}</NotMobileHolder>
            </>}
          </CenterContainer>
          <MobileHolder>{renderEntriesForMobile()}</MobileHolder>
          {process.env.GATSBY_ENV !== 'production' &&
          <ButtonContainer>
            <ServicesButton icon={<ArrowRight />} to={`${slug_prefix ? `/${slug_prefix}` : ''}/services`}>{buttonText}</ServicesButton>
          </ButtonContainer>}
      </Container>
    </Background>
  )
}
