import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { PrivacyPolicyPage } from 'content/privacy-policy-page/PrivacyPolicyPage'

const PrivacyPolicy = ({ data }) => {
  return <PrivacyPolicyPage data={data.privacyJson}/>
}

PrivacyPolicy.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default PrivacyPolicy

export const query = graphql`  
  query($slug: String!) {
    privacyJson(fields: { slug: { eq: $slug } }) {
      ...PrivacyPage
    }
  }
`
