import { css } from 'styled-components'
import { theme } from 'theme'

export const afterDot = (dotSize = 6) => css`
  :after {
    content: '';
    display: inline-block;
    width: ${dotSize}px;
    height: ${dotSize}px;
    border-radius: ${dotSize/2}px;
    background-color: ${({ theme }) => theme.colors.blue};
    margin-left: ${dotSize/2}px;
  }
`

export const HOVER_LINE_HEIGHT = 24

export const hoverUnderline = ({
  color = theme.colors.blue,
  height = '2px',
  width = '100%',
  lineHeight = `${HOVER_LINE_HEIGHT}px`,
} = {}) => css`
  :hover {
    text-decoration: none !important;
    background: linear-gradient(to right, ${color}, ${color}) no-repeat left bottom;
    background-size: ${width} ${height};
    line-height: ${lineHeight};
  }
`
