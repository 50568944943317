import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Icon from './Icon'

const Svg = styled(Icon)`
  width: 18px;
  height: 17.212px;
  color: ${({ theme }) => theme.colors.white};
`

export const LinkedIn = ({ className }) => (
  <Svg viewBox="0 0 18 17.212" fill="none" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M557.9,1226.87v-11.609h-3.859v11.609Zm-1.929-13.194a2.013,2.013,0,0,0,2.176-1.836c.005-.056.007-.113.007-.169a2,2,0,0,0-2-2.012c-.053,0-.107,0-.16.006a2.013,2.013,0,0,0-2.176,1.836c0,.056-.007.113-.007.17a2,2,0,0,0,1.991,2.009c.047,0,.094,0,.141,0h.026Zm4.063,13.193H563.9v-6.483a2.641,2.641,0,0,1,.127-.942,2.112,2.112,0,0,1,1.98-1.411c1.4,0,1.955,1.065,1.955,2.625v6.211h3.858v-6.656c0-3.566-1.9-5.225-4.442-5.225a3.842,3.842,0,0,0-3.5,1.956h.026v-1.684h-3.859c.051,1.089,0,11.609,0,11.609Z"
      transform="translate(-553.816 -1209.658)"
    />
  </Svg>
)

LinkedIn.propTypes = {
  className: PropTypes.string,
}

export default LinkedIn
