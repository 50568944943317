import PropTypes from 'prop-types'
import React from 'react'
import { ThemeProvider } from 'styled-components'

import { theme } from 'theme'
import { GeneralContextProvider } from './context/general_context'

export const AppProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GeneralContextProvider>
      {children}
    </GeneralContextProvider>
  </ThemeProvider>
)

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
