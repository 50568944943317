import React from 'react'
import styled from 'styled-components'

/**
 * Takes a string and adds a blue dot or question mark at the end
 * Checks for existing dots or questions marks to avoid duplication
 *
 * @component
 * @param {string} text - Regular text
 */
export const appendBlueCharacter = (text) => {
  let characterToAppend = '.'
  let lastCharacter = text.slice(-1)

  if (lastCharacter === '.') {
    text = text.slice(0, text.length - 1)
  } else if (lastCharacter === '?') {
    text = text.slice(0, text.length - 1)
    characterToAppend = '?'
  }

  return (
    <>{text}<Blue>{characterToAppend}</Blue></>
  )
}

const Blue = styled.span`
  color: ${({ theme }) => theme.colors.blue};
`