import React from 'react'
import { Location } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import _get from 'lodash.get'
import PropTypes from 'prop-types'

import { imageWidth, imageHeight } from 'constant'
import schemaGenerator from 'schemaGenerator'
import logoSrc from 'resources/images/9y-logo-black-blue.png'
import ogImage from 'resources/images/9y-og-image.png'

export const Head = ({
  imageUrl,
  metadata = {},
}) => {
  const siteMetadataQuery = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          siteDescription
          keywords
          siteUrl
          siteVersion
          themeColor
          social {
            twitter
            fbAppId
          }
        }
      }
    }
  `)

  return (
    <Location>
      {({ location }) => {
        const thisMetadata = _get(siteMetadataQuery, 'site.siteMetadata', {})
        const siteUrl = thisMetadata.siteUrl
        const canonical = `${siteUrl}${(location.pathname || '')}`
        const siteTitle = _get(metadata, 'siteTitle', '') || thisMetadata.siteTitle
        const pageTitle = _get(metadata, 'pageTitle', '') || thisMetadata.pageTitle
        const pageDescription = _get(metadata, 'pageDescription', '') || thisMetadata.pageDescription
        const pageTitleFull = pageTitle ? `${pageTitle} - ${siteTitle}` : siteTitle
        const facebook = _get(thisMetadata, 'social.fbAppId', '')
        const twitter = _get(thisMetadata, 'social.twitter', '')
        const otherLang = _get(metadata, 'other_lang', [])
        const keywords = _get(metadata, 'keywords', '')

        return (
          <Helmet>
            <html lang={_get(metadata, 'lang', 'en')} />

            <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
            <meta
              content="width=device-width,initial-scale=1.0,user-scalable=yes"
              name="viewport"
            />

            {otherLang?.map((l, i) => {
              if (l._enabled) {
                return <link key={i} rel={'alternate'} hrefLang={l.code} href={`${siteUrl}${l.url.startsWith('/') ? '' : '/'}${l.url}`} />
              }

              return null
            })}

            <meta content={siteTitle} name="apple-mobile-web-app-title" />
            <meta content={pageTitleFull} property="og:title" />
            <meta content={pageTitleFull} name="twitter:title" />
            <title>{pageTitleFull}</title>

            <meta content={pageDescription} name="description" />
            <meta content={pageDescription} property="og:description" />
            <meta content={pageDescription} name="twitter:description" />

            {keywords && <meta content={keywords} name="keywords" />}

            <meta content="yes" name="apple-mobile-web-app-capable" />
            <meta
              content="black-translucent"
              name="apple-mobile-web-app-status-bar-style"
            />
            <meta content={thisMetadata.themeColor} name="theme-color" />
            <meta content={siteTitle} name="application-name" />

            <meta content="website" property="og:type" />
            <meta content={siteTitle} property="og:site_name" />
            {facebook && <meta content={facebook} property="fb:app_id" />}
            <meta content="summary_large_image" name="twitter:card" />
            {twitter && (
              <>
                <meta content={`@${twitter}`} name="twitter:site" />
                <meta content={`@${twitter}`} name="twitter:creator" />
              </>
            )}
            <meta content={pageTitleFull} name="twitter:text:title" />
            <meta content={canonical} property="og:url" />
            <meta content={canonical} name="twitter:url" />
            <link rel="canonical" href={canonical} />

            <meta content={imageUrl || `${siteUrl}${ogImage}`} property="og:image" />
            <meta content={imageUrl || `${siteUrl}${logoSrc}`} name="twitter:image" />
            <meta content={`${imageWidth}`} name="twitter:image:width" />
            <meta content={`${imageHeight}`} name="twitter:image:height" />

            <meta content={thisMetadata.themeColor} name="msapplication-TileColor" />
            <meta content="/icons/mstile-70x70.png" name="msapplication-square70x70" />
            <meta
              content="/icons/mstile-144x144.png"
              name="msapplication-square144x144"
            />
            <meta
              content="/icons/mstile-150x150.png"
              name="msapplication-square150x150"
            />
            <meta
              content="/icons/mstile-310x150.png"
              name="msapplication-wide310x150"
            />
            <meta
              content="/icons/mstile-310x310.png"
              name="msapplication-square310x310"
            />

            <link href="/manifest.json" rel="manifest" />

            <link
              href="/icons/apple-touch-icon-57x57.png"
              rel="apple-touch-icon"
              sizes="57x57"
            />
            <link
              href="/icons/apple-touch-icon-60x60.png"
              rel="apple-touch-icon"
              sizes="60x60"
            />
            <link
              href="/icons/apple-touch-icon-72x72.png"
              rel="apple-touch-icon"
              sizes="72x72"
            />
            <link
              href="/icons/apple-touch-icon-76x76.png"
              rel="apple-touch-icon"
              sizes="76x76"
            />
            <link
              href="/icons/apple-touch-icon-114x114.png"
              rel="apple-touch-icon"
              sizes="114x114"
            />
            <link
              href="/icons/apple-touch-icon-120x120.png"
              rel="apple-touch-icon"
              sizes="120x120"
            />
            <link
              href="/icons/apple-touch-icon-144x144.png"
              rel="apple-touch-icon"
              sizes="144x144"
            />
            <link
              href="/icons/apple-touch-icon-152x152.png"
              rel="apple-touch-icon"
              sizes="152x152"
            />
            <link
              href="/icons/apple-touch-icon-167x167.png"
              rel="apple-touch-icon"
              sizes="167x167"
            />
            <link
              href="/icons/apple-touch-icon-180x180.png"
              rel="icon"
              sizes="180x180"
              type="image/png"
            />

            <link
              href="/icons/favicon-32x32.png"
              rel="icon"
              sizes="32x32"
              type="image/png"
            />
            <link
              href="/icons/favicon-16x16.png"
              rel="icon"
              sizes="16x16"
              type="image/png"
            />

            <script type="application/ld+json">
              {JSON.stringify(
                schemaGenerator({
                  location,
                  canonical,
                  siteUrl,
                  pageTitle,
                  siteTitle,
                  pageTitleFull,
                })
              )}
            </script>
            {process.env.GATSBY_ENV === 'production' && (
              <script>{`
               window.dataLayer = window.dataLayer || []
               window.dataLayer.push({
                'siteVersion': '${thisMetadata.siteVersion}'
               })`}
              </script>
            )}
            {process.env.GATSBY_ENV === 'production' && (
              <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/2245966.js"></script>
            )}
            {(process.env.GATSBY_ENV === 'staging' || process.env.GATSBY_ENV === 'production') && (
              <script src="https://www.googleoptimize.com/optimize.js?id=OPT-MX6VH6R"></script>
            )}
            {process.env.GATSBY_ENV === 'production' && (
              <script>{`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-W4MCRPS');`}
              </script>
            )}
            {process.env.GATSBY_ENV === 'staging' && (
              <script>{`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-W3WC5W7');`}
              </script>
            )}
            {process.env.GATSBY_ENV === 'production' && (
              <script>{`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '298899958140146');
                fbq('track', 'PageView');`}
              </script>
            )}
            {process.env.GATSBY_ENV === 'production' && (
              <script>
                {`
                  (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:1691984,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}
              </script>
            )}
          </Helmet>
        )
      }}
    </Location>
  )
}

Head.propTypes = {
  imageUrl: PropTypes.string,
  metadata: PropTypes.func,
}
