import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Icon from './Icon'

const Svg = styled(Icon)`
  width: 18px;
  height: 17.212px;
  color: ${({ theme }) => theme.colors.white};
  transform: scale(1.59);
`

export const Facebook = ({ className }) => (
  <Svg viewBox="0 0 30 30" fill="none" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.556 11.721V9.755c0-.852.2-1.311 1.574-1.311h1.7V5.165h-2.618c-3.278 0-4.59 2.164-4.59 4.59v1.967H10V15h2.623v9.835h3.934V15h2.885l.393-3.278z"
    />
  </Svg>
)

Facebook.propTypes = {
  className: PropTypes.string,
}

export default Facebook
