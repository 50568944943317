import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { WorkPage } from 'content/work-page/WorkPage'

const Work = ({ data }) => {
  return <WorkPage data={data.workJson}/>
}

Work.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default Work

export const query = graphql`
  query($slug: String!) {
    workJson(fields: { slug: { eq: $slug } }) {
      ...WorkPage
    }
  }
`
