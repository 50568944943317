import React from 'react'
import { Talk } from 'components/home/talking'
import _get from 'lodash.get'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { media } from 'media'

const StyledTalk = styled(Talk)`
  p {
    line-height: 22px;

    ${media.desktop`
      margin-bottom: 28px;
      line-height: 28px;
    `}
  }
`

export const Project = ({ data, className }) => {
  return (
    <StyledTalk
      className={className}
      title={_get(data, 'project.title', '')}
      description={_get(data, 'project.description', [])} />
  )
}

Project.propTypes = {
  data: PropTypes.shape().isRequired,
  className: PropTypes.string,
}
