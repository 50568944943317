import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { media } from 'media'
import Icon from './Icon'

const Svg = styled(Icon)`
  width: 22px;
  height: auto;

  ${media.desktop`
    width: 22px;
    height: 21px;
  `}
  color: ${({ theme }) => theme.colors.white};
  .b {
    fill: none;
    stroke: #fff;
    stroke-width: 2px;
  }
`

export const Person = ({ className }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" className={className}>
    <g transform="translate(-755 -329)">
      <circle cx="20" cy="20" r="20" transform="translate(755 329)" fill="#393939" />
      <path className="b" d="M766 360c0-5.523 4.029-10 9-10s9 4.477 9 10" />
      <g className="b" transform="translate(768.5 338)">
        <circle cx="6.5" cy="6.5" r="6.5" />
        <circle cx="6.5" cy="6.5" r="5.5" fill="none" />
      </g>
    </g>
  </Svg>
)

Person.propTypes = {
  className: PropTypes.string,
}

export default Person
